// Copyright 2021
// ThatWorks.xyz Limited

export const ONBOARD_EXAMPLE_POST = {
    type: 'doc',
    content: [
        {
            type: 'heading',
            attrs: { dir: null, ignoreBidiAutoUpdate: null, level: 2 },
            content: [{ type: 'text', text: 'Project X Overview' }],
        },
        {
            type: 'metric',
            attrs: {
                uuid: 'da235ca6-a5ad-4d4a-b2d7-dbddc0695648',
                data: '',
                metrics: [
                    {
                        parents: [
                            {
                                name: 'Project X Financials',
                                connectorObjectType: 'Sheet',
                                url: 'https://docs.google.com/spreadsheets',
                            },
                        ],
                        title: 'Social Ad Spend',
                        value: 124333,
                        valueFormatted: '$12,433',
                        connector: 'linked#google#drive',
                        itemUuid: '44eac100-23b1-4d11-b132-c1bc0629087a',
                        deltas: [],
                        xAxisValue: null,
                    },
                    {
                        parents: [
                            {
                                name: 'Consolidated Metrics',
                                connectorObjectType: 'Sheet',
                                url: 'https://docs.google.com/spreadsheets',
                            },
                        ],
                        title: 'NPS',
                        value: 38,
                        valueFormatted: '38',
                        connector: 'linked#google#drive',
                        itemUuid: '44eac100-23b1-4d11-b132-c1bc0629087a',
                        deltas: [],
                        xAxisValue: null,
                    },
                ],
            },
        },
        {
            type: 'heading',
            attrs: { dir: null, ignoreBidiAutoUpdate: null, level: 2 },
            content: [{ type: 'text', text: 'Engineering: Completed' }],
        },
        {
            type: 'table',
            attrs: { dir: null, ignoreBidiAutoUpdate: null },
            content: [
                {
                    type: 'tableRow',
                    content: [
                        {
                            type: 'tableCell',
                            attrs: { colspan: 1, rowspan: 1, colwidth: [200], background: null },
                            content: [
                                {
                                    type: 'paragraph',
                                    attrs: { dir: null, ignoreBidiAutoUpdate: null },
                                    content: [
                                        {
                                            type: 'inline-insight-pill',
                                            attrs: {
                                                uuid: '3e0595c8-8e04-43ec-9703-2dde4beb98d0',
                                                data: {
                                                    value: 'Bugs: 6 Fixed',
                                                    iconUrl:
                                                        'https://api.atlassian.com/ex/jira/50a0ba45-fdcb-42b0-8cc3-c0de7825c269/rest/api/2/universal_avatar/view/type/issuetype/avatar/10303?size=medium',
                                                    connector: 'linked#atlassian#jira',
                                                    items: [
                                                        {
                                                            title: 'NUX images do not show in prod and staging',
                                                            iconUrl:
                                                                'https://api.atlassian.com/ex/jira/50a0ba45-fdcb-42b0-8cc3-c0de7825c269/rest/api/2/universal_avatar/view/type/issuetype/avatar/10303?size=medium',
                                                            connector: 'linked#atlassian#jira',
                                                            id: '335af270-589a-4e52-bdd7-3c74ec48551b',
                                                            properties: [
                                                                {
                                                                    name: 'Status',
                                                                    value: 'Staging',
                                                                    color: '#36B37E',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'STATUS',
                                                                },
                                                                {
                                                                    name: 'Assignee',
                                                                    value: 'Varun Nair',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'ASSIGNEE',
                                                                },
                                                                {
                                                                    name: 'Priority',
                                                                    value: 'Medium',
                                                                    color: '#f79232',
                                                                    iconUrl:
                                                                        'https://thatworks.atlassian.net/images/icons/priorities/medium.svg',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'PRIORITY',
                                                                },
                                                            ],
                                                            parents: [
                                                                {
                                                                    connectorObjectType: 'sprint',
                                                                    name: 'Sprint 43',
                                                                    url: 'https://thatworks.atlassian.net/issues/?jql=Sprint%20%3D%20%22Sprint%2043%22',
                                                                },
                                                                {
                                                                    connectorObjectType: 'project',
                                                                    name: 'Project X Dev',
                                                                    url: 'https://thatworks.atlassian.net/browse/TWD',
                                                                },
                                                            ],
                                                            url: 'https://thatworks.atlassian.net/browse/TWD-690',
                                                        },
                                                        {
                                                            title: 'Stripe connection timeouts and errors',
                                                            iconUrl:
                                                                'https://api.atlassian.com/ex/jira/50a0ba45-fdcb-42b0-8cc3-c0de7825c269/rest/api/2/universal_avatar/view/type/issuetype/avatar/10303?size=medium',
                                                            connector: 'linked#atlassian#jira',
                                                            id: 'b449d506-4efd-4da5-bade-1aecaed46e9a',
                                                            properties: [
                                                                {
                                                                    name: 'Status',
                                                                    value: 'Staging',
                                                                    color: '#36B37E',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'STATUS',
                                                                },
                                                                {
                                                                    name: 'Assignee',
                                                                    value: 'Abesh Thakur',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'ASSIGNEE',
                                                                },
                                                                {
                                                                    name: 'Priority',
                                                                    value: 'Medium',
                                                                    color: '#f79232',
                                                                    iconUrl:
                                                                        'https://thatworks.atlassian.net/images/icons/priorities/medium.svg',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'PRIORITY',
                                                                },
                                                            ],
                                                            parents: [
                                                                {
                                                                    connectorObjectType: 'Epic',
                                                                    name: 'v1 Slice',
                                                                    url: 'https://thatworks.atlassian.net/browse/TWD-627',
                                                                },
                                                                {
                                                                    connectorObjectType: 'sprint',
                                                                    name: 'Sprint 43',
                                                                    url: 'https://thatworks.atlassian.net/issues/?jql=Sprint%20%3D%20%22Sprint%2043%22',
                                                                },
                                                                {
                                                                    connectorObjectType: 'project',
                                                                    name: 'Project X Dev',
                                                                    url: 'https://thatworks.atlassian.net/browse/TWD',
                                                                },
                                                            ],
                                                            url: 'https://thatworks.atlassian.net/browse/TWD-692',
                                                        },
                                                        {
                                                            title: 'Stripe connection on prod is stuck on processing step',
                                                            iconUrl:
                                                                'https://api.atlassian.com/ex/jira/50a0ba45-fdcb-42b0-8cc3-c0de7825c269/rest/api/2/universal_avatar/view/type/issuetype/avatar/10303?size=medium',
                                                            connector: 'linked#atlassian#jira',
                                                            id: '18b13fae-51cb-4e8b-a087-2eaa8aea9011',
                                                            properties: [
                                                                {
                                                                    name: 'Status',
                                                                    value: 'Staging',
                                                                    color: '#36B37E',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'STATUS',
                                                                },
                                                                {
                                                                    name: 'Assignee',
                                                                    value: 'Abesh Thakur',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'ASSIGNEE',
                                                                },
                                                                {
                                                                    name: 'Priority',
                                                                    value: 'Medium',
                                                                    color: '#f79232',
                                                                    iconUrl:
                                                                        'https://thatworks.atlassian.net/images/icons/priorities/medium.svg',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'PRIORITY',
                                                                },
                                                            ],
                                                            parents: [
                                                                {
                                                                    connectorObjectType: 'Epic',
                                                                    name: 'v1 Slice',
                                                                    url: 'https://thatworks.atlassian.net/browse/TWD-627',
                                                                },
                                                                {
                                                                    connectorObjectType: 'sprint',
                                                                    name: 'Sprint 43',
                                                                    url: 'https://thatworks.atlassian.net/issues/?jql=Sprint%20%3D%20%22Sprint%2043%22',
                                                                },
                                                                {
                                                                    connectorObjectType: 'project',
                                                                    name: 'Project X Dev',
                                                                    url: 'https://thatworks.atlassian.net/browse/TWD',
                                                                },
                                                            ],
                                                            url: 'https://thatworks.atlassian.net/browse/TWD-693',
                                                        },
                                                        {
                                                            title: 'Insight failure in customer and support dashboards',
                                                            iconUrl:
                                                                'https://api.atlassian.com/ex/jira/50a0ba45-fdcb-42b0-8cc3-c0de7825c269/rest/api/2/universal_avatar/view/type/issuetype/avatar/10303?size=medium',
                                                            connector: 'linked#atlassian#jira',
                                                            id: '12e8534a-d104-4e2a-b31e-51ed5fdd0339',
                                                            properties: [
                                                                {
                                                                    name: 'Status',
                                                                    value: 'Staging',
                                                                    color: '#36B37E',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'STATUS',
                                                                },
                                                                {
                                                                    name: 'Assignee',
                                                                    value: 'Varun Nair',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'ASSIGNEE',
                                                                },
                                                                {
                                                                    name: 'Priority',
                                                                    value: 'Medium',
                                                                    color: '#f79232',
                                                                    iconUrl:
                                                                        'https://thatworks.atlassian.net/images/icons/priorities/medium.svg',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'PRIORITY',
                                                                },
                                                            ],
                                                            parents: [
                                                                {
                                                                    connectorObjectType: 'sprint',
                                                                    name: 'Sprint 43',
                                                                    url: 'https://thatworks.atlassian.net/issues/?jql=Sprint%20%3D%20%22Sprint%2043%22',
                                                                },
                                                                {
                                                                    connectorObjectType: 'project',
                                                                    name: 'Project X Dev',
                                                                    url: 'https://thatworks.atlassian.net/browse/TWD',
                                                                },
                                                            ],
                                                            url: 'https://thatworks.atlassian.net/browse/TWD-695',
                                                        },
                                                        {
                                                            title: '50x errors in customer dashboard',
                                                            iconUrl:
                                                                'https://api.atlassian.com/ex/jira/50a0ba45-fdcb-42b0-8cc3-c0de7825c269/rest/api/2/universal_avatar/view/type/issuetype/avatar/10303?size=medium',
                                                            connector: 'linked#atlassian#jira',
                                                            id: '582ef298-6f6d-437b-b518-ffbfb6c9890f',
                                                            properties: [
                                                                {
                                                                    name: 'Status',
                                                                    value: 'Staging',
                                                                    color: '#36B37E',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'STATUS',
                                                                },
                                                                {
                                                                    name: 'Assignee',
                                                                    value: 'Varun Nair',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'ASSIGNEE',
                                                                },
                                                                {
                                                                    name: 'Priority',
                                                                    value: 'Medium',
                                                                    color: '#f79232',
                                                                    iconUrl:
                                                                        'https://thatworks.atlassian.net/images/icons/priorities/medium.svg',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'PRIORITY',
                                                                },
                                                            ],
                                                            parents: [
                                                                {
                                                                    connectorObjectType: 'sprint',
                                                                    name: 'Sprint 43',
                                                                    url: 'https://thatworks.atlassian.net/issues/?jql=Sprint%20%3D%20%22Sprint%2043%22',
                                                                },
                                                                {
                                                                    connectorObjectType: 'project',
                                                                    name: 'Project X Dev',
                                                                    url: 'https://thatworks.atlassian.net/browse/TWD',
                                                                },
                                                            ],
                                                            url: 'https://thatworks.atlassian.net/browse/TWD-694',
                                                        },
                                                        {
                                                            title: 'Reconnect banner has incorrect layout when viewing a topic',
                                                            iconUrl:
                                                                'https://api.atlassian.com/ex/jira/50a0ba45-fdcb-42b0-8cc3-c0de7825c269/rest/api/2/universal_avatar/view/type/issuetype/avatar/10303?size=medium',
                                                            connector: 'linked#atlassian#jira',
                                                            id: 'db467063-1da3-489d-9235-b49018d73648',
                                                            properties: [
                                                                {
                                                                    name: 'Status',
                                                                    value: 'Staging',
                                                                    color: '#36B37E',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'STATUS',
                                                                },
                                                                {
                                                                    name: 'Assignee',
                                                                    value: 'Varun Nair',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'ASSIGNEE',
                                                                },
                                                                {
                                                                    name: 'Priority',
                                                                    value: 'Medium',
                                                                    color: '#f79232',
                                                                    iconUrl:
                                                                        'https://thatworks.atlassian.net/images/icons/priorities/medium.svg',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'PRIORITY',
                                                                },
                                                            ],
                                                            parents: [
                                                                {
                                                                    connectorObjectType: 'sprint',
                                                                    name: 'Sprint 43',
                                                                    url: 'https://thatworks.atlassian.net/issues/?jql=Sprint%20%3D%20%22Sprint%2043%22',
                                                                },
                                                                {
                                                                    connectorObjectType: 'project',
                                                                    name: 'Project X Dev',
                                                                    url: 'https://thatworks.atlassian.net/browse/TWD',
                                                                },
                                                            ],
                                                            url: 'https://thatworks.atlassian.net/browse/TWD-691',
                                                        },
                                                        {
                                                            title: 'Poll is silently failing',
                                                            iconUrl:
                                                                'https://api.atlassian.com/ex/jira/50a0ba45-fdcb-42b0-8cc3-c0de7825c269/rest/api/2/universal_avatar/view/type/issuetype/avatar/10303?size=medium',
                                                            connector: 'linked#atlassian#jira',
                                                            id: '8e6352f1-8373-4ead-9301-8e81695526d2',
                                                            properties: [
                                                                {
                                                                    name: 'Status',
                                                                    value: 'Staging',
                                                                    color: '#36B37E',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'STATUS',
                                                                },
                                                                {
                                                                    name: 'Assignee',
                                                                    value: 'Varun Nair',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'ASSIGNEE',
                                                                },
                                                                {
                                                                    name: 'Priority',
                                                                    value: 'Medium',
                                                                    color: '#f79232',
                                                                    iconUrl:
                                                                        'https://thatworks.atlassian.net/images/icons/priorities/medium.svg',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'PRIORITY',
                                                                },
                                                            ],
                                                            parents: [
                                                                {
                                                                    connectorObjectType: 'sprint',
                                                                    name: 'Sprint 43',
                                                                    url: 'https://thatworks.atlassian.net/issues/?jql=Sprint%20%3D%20%22Sprint%2043%22',
                                                                },
                                                                {
                                                                    connectorObjectType: 'project',
                                                                    name: 'Project X Dev',
                                                                    url: 'https://thatworks.atlassian.net/browse/TWD',
                                                                },
                                                            ],
                                                            url: 'https://thatworks.atlassian.net/browse/TWD-696',
                                                        },
                                                        {
                                                            title: 'Links on home page footer are broken',
                                                            iconUrl:
                                                                'https://api.atlassian.com/ex/jira/50a0ba45-fdcb-42b0-8cc3-c0de7825c269/rest/api/2/universal_avatar/view/type/issuetype/avatar/10303?size=medium',
                                                            connector: 'linked#atlassian#jira',
                                                            id: '8a63a04a-fd97-4e63-8129-2b354bdd18a6',
                                                            properties: [
                                                                {
                                                                    name: 'Status',
                                                                    value: 'Staging',
                                                                    color: '#36B37E',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'STATUS',
                                                                },
                                                                {
                                                                    name: 'Assignee',
                                                                    value: 'Varun Nair',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'ASSIGNEE',
                                                                },
                                                                {
                                                                    name: 'Priority',
                                                                    value: 'Medium',
                                                                    color: '#f79232',
                                                                    iconUrl:
                                                                        'https://thatworks.atlassian.net/images/icons/priorities/medium.svg',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'PRIORITY',
                                                                },
                                                            ],
                                                            parents: [
                                                                {
                                                                    connectorObjectType: 'Epic',
                                                                    name: 'v1 Slice',
                                                                    url: 'https://thatworks.atlassian.net/browse/TWD-627',
                                                                },
                                                                {
                                                                    connectorObjectType: 'sprint',
                                                                    name: 'Sprint 43',
                                                                    url: 'https://thatworks.atlassian.net/issues/?jql=Sprint%20%3D%20%22Sprint%2043%22',
                                                                },
                                                                {
                                                                    connectorObjectType: 'project',
                                                                    name: 'Project X Dev',
                                                                    url: 'https://thatworks.atlassian.net/browse/TWD',
                                                                },
                                                            ],
                                                            url: 'https://thatworks.atlassian.net/browse/TWD-682',
                                                        },
                                                    ],
                                                },
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            type: 'tableCell',
                            attrs: { colspan: 1, rowspan: 1, colwidth: null, background: null },
                            content: [
                                {
                                    type: 'paragraph',
                                    attrs: { dir: null, ignoreBidiAutoUpdate: null },
                                    content: [
                                        { type: 'text', marks: [{ type: 'bold' }], text: '✨ Highlights: ' },
                                        {
                                            type: 'text',
                                            text: 'Highlights include NUX images not showing in prod/staging, Stripe connection issues during payment and processing steps, and issues with insights in customer and support dashboards.',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            type: 'table',
            attrs: { dir: null, ignoreBidiAutoUpdate: null },
            content: [
                {
                    type: 'tableRow',
                    content: [
                        {
                            type: 'tableCell',
                            attrs: { colspan: 1, rowspan: 1, colwidth: [200], background: null },
                            content: [
                                {
                                    type: 'paragraph',
                                    attrs: { dir: null, ignoreBidiAutoUpdate: null },
                                    content: [
                                        {
                                            type: 'inline-insight-pill',
                                            attrs: {
                                                uuid: '3e0595c8-8e04-43ec-9703-2dde4beb98d0',
                                                data: {
                                                    value: 'Tasks: 8 Done',
                                                    iconUrl:
                                                        'https://api.atlassian.com/ex/jira/50a0ba45-fdcb-42b0-8cc3-c0de7825c269/rest/api/2/universal_avatar/view/type/issuetype/avatar/10318?size=medium',
                                                    connector: 'linked#atlassian#jira',
                                                    items: [
                                                        {
                                                            title: 'Implement dark mode for all pages',
                                                            iconUrl:
                                                                'https://api.atlassian.com/ex/jira/50a0ba45-fdcb-42b0-8cc3-c0de7825c269/rest/api/2/universal_avatar/view/type/issuetype/avatar/10318?size=medium',
                                                            connector: 'linked#atlassian#jira',
                                                            id: 'b966b8c8-c898-4528-9300-77fcd31851b3',
                                                            properties: [
                                                                {
                                                                    name: 'Status',
                                                                    value: 'Done',
                                                                    color: '#36B37E',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'STATUS',
                                                                },
                                                                {
                                                                    name: 'Assignee',
                                                                    value: 'Varun Nair',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'ASSIGNEE',
                                                                },
                                                                {
                                                                    name: 'Priority',
                                                                    value: 'High',
                                                                    color: '#f15C75',
                                                                    iconUrl:
                                                                        'https://thatworks.atlassian.net/images/icons/priorities/high.svg',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'PRIORITY',
                                                                },
                                                            ],
                                                            parents: [
                                                                {
                                                                    connectorObjectType: 'Epic',
                                                                    name: 'v1 Slice',
                                                                    url: 'https://thatworks.atlassian.net/browse/TWD-627',
                                                                },
                                                                {
                                                                    connectorObjectType: 'sprint',
                                                                    name: 'Sprint 42',
                                                                    url: 'https://thatworks.atlassian.net/issues/?jql=Sprint%20%3D%20%22Sprint%2042%22',
                                                                },
                                                                {
                                                                    connectorObjectType: 'project',
                                                                    name: 'Project X Dev',
                                                                    url: 'https://thatworks.atlassian.net/browse/TWD',
                                                                },
                                                            ],
                                                            url: 'https://thatworks.atlassian.net/browse/TWD-657',
                                                        },
                                                        {
                                                            title: 'Optimize database query for large datasets',
                                                            iconUrl:
                                                                'https://api.atlassian.com/ex/jira/50a0ba45-fdcb-42b0-8cc3-c0de7825c269/rest/api/2/universal_avatar/view/type/issuetype/avatar/10318?size=medium',
                                                            connector: 'linked#atlassian#jira',
                                                            id: '7ad0aac8-506e-4f7f-8fb9-e02df320f33b',
                                                            properties: [
                                                                {
                                                                    name: 'Status',
                                                                    value: 'Done',
                                                                    color: '#36B37E',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'STATUS',
                                                                },
                                                                {
                                                                    name: 'Assignee',
                                                                    value: 'Varun Nair',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'ASSIGNEE',
                                                                },
                                                                {
                                                                    name: 'Priority',
                                                                    value: 'Medium',
                                                                    color: '#f79232',
                                                                    iconUrl:
                                                                        'https://thatworks.atlassian.net/images/icons/priorities/medium.svg',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'PRIORITY',
                                                                },
                                                            ],
                                                            parents: [
                                                                {
                                                                    connectorObjectType: 'Epic',
                                                                    name: 'v1 Slice',
                                                                    url: 'https://thatworks.atlassian.net/browse/TWD-627',
                                                                },
                                                                {
                                                                    connectorObjectType: 'sprint',
                                                                    name: 'Sprint 43',
                                                                    url: 'https://thatworks.atlassian.net/issues/?jql=Sprint%20%3D%20%22Sprint%2043%22',
                                                                },
                                                                {
                                                                    connectorObjectType: 'project',
                                                                    name: 'Project X Dev',
                                                                    url: 'https://thatworks.atlassian.net/browse/TWD',
                                                                },
                                                            ],
                                                            url: 'https://thatworks.atlassian.net/browse/TWD-686',
                                                        },
                                                        {
                                                            title: 'Ability to upload images to the database',
                                                            iconUrl:
                                                                'https://api.atlassian.com/ex/jira/50a0ba45-fdcb-42b0-8cc3-c0de7825c269/rest/api/2/universal_avatar/view/type/issuetype/avatar/10318?size=medium',
                                                            connector: 'linked#atlassian#jira',
                                                            id: '997dac04-a387-4c25-8ab8-a05a48db4a84',
                                                            properties: [
                                                                {
                                                                    name: 'Status',
                                                                    value: 'Done',
                                                                    color: '#36B37E',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'STATUS',
                                                                },
                                                                {
                                                                    name: 'Assignee',
                                                                    value: 'Varun Nair',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'ASSIGNEE',
                                                                },
                                                                {
                                                                    name: 'Priority',
                                                                    value: 'Medium',
                                                                    color: '#f79232',
                                                                    iconUrl:
                                                                        'https://thatworks.atlassian.net/images/icons/priorities/medium.svg',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'PRIORITY',
                                                                },
                                                            ],
                                                            parents: [
                                                                {
                                                                    connectorObjectType: 'Epic',
                                                                    name: 'v1 Slice',
                                                                    url: 'https://thatworks.atlassian.net/browse/TWD-627',
                                                                },
                                                                {
                                                                    connectorObjectType: 'sprint',
                                                                    name: 'Sprint 43',
                                                                    url: 'https://thatworks.atlassian.net/issues/?jql=Sprint%20%3D%20%22Sprint%2043%22',
                                                                },
                                                                {
                                                                    connectorObjectType: 'project',
                                                                    name: 'Project X Dev',
                                                                    url: 'https://thatworks.atlassian.net/browse/TWD',
                                                                },
                                                            ],
                                                            url: 'https://thatworks.atlassian.net/browse/TWD-684',
                                                        },
                                                        {
                                                            title: 'Conduct security audit',
                                                            iconUrl:
                                                                'https://api.atlassian.com/ex/jira/50a0ba45-fdcb-42b0-8cc3-c0de7825c269/rest/api/2/universal_avatar/view/type/issuetype/avatar/10318?size=medium',
                                                            connector: 'linked#atlassian#jira',
                                                            id: 'e7397752-a5a8-4e2f-811d-2b97a05ceffd',
                                                            properties: [
                                                                {
                                                                    name: 'Status',
                                                                    value: 'Done',
                                                                    color: '#36B37E',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'STATUS',
                                                                },
                                                                {
                                                                    name: 'Assignee',
                                                                    value: 'Varun Nair',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'ASSIGNEE',
                                                                },
                                                                {
                                                                    name: 'Priority',
                                                                    value: 'Medium',
                                                                    color: '#f79232',
                                                                    iconUrl:
                                                                        'https://thatworks.atlassian.net/images/icons/priorities/medium.svg',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'PRIORITY',
                                                                },
                                                            ],
                                                            parents: [
                                                                {
                                                                    connectorObjectType: 'Epic',
                                                                    name: 'v1 Slice',
                                                                    url: 'https://thatworks.atlassian.net/browse/TWD-627',
                                                                },
                                                                {
                                                                    connectorObjectType: 'sprint',
                                                                    name: 'Sprint 42',
                                                                    url: 'https://thatworks.atlassian.net/issues/?jql=Sprint%20%3D%20%22Sprint%2042%22',
                                                                },
                                                                {
                                                                    connectorObjectType: 'project',
                                                                    name: 'Project X Dev',
                                                                    url: 'https://thatworks.atlassian.net/browse/TWD',
                                                                },
                                                            ],
                                                            url: 'https://thatworks.atlassian.net/browse/TWD-667',
                                                        },
                                                        {
                                                            title: 'Integrate Analytics Dashboard',
                                                            iconUrl:
                                                                'https://api.atlassian.com/ex/jira/50a0ba45-fdcb-42b0-8cc3-c0de7825c269/rest/api/2/universal_avatar/view/type/issuetype/avatar/10318?size=medium',
                                                            connector: 'linked#atlassian#jira',
                                                            id: '29a53b63-17a1-43b9-ac61-e2e8f82b1965',
                                                            properties: [
                                                                {
                                                                    name: 'Status',
                                                                    value: 'Done',
                                                                    color: '#36B37E',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'STATUS',
                                                                },
                                                                {
                                                                    name: 'Assignee',
                                                                    value: 'Varun Nair',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'ASSIGNEE',
                                                                },
                                                                {
                                                                    name: 'Priority',
                                                                    value: 'Medium',
                                                                    color: '#f79232',
                                                                    iconUrl:
                                                                        'https://thatworks.atlassian.net/images/icons/priorities/medium.svg',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'PRIORITY',
                                                                },
                                                            ],
                                                            parents: [
                                                                {
                                                                    connectorObjectType: 'Epic',
                                                                    name: 'v1 Slice',
                                                                    url: 'https://thatworks.atlassian.net/browse/TWD-627',
                                                                },
                                                                {
                                                                    connectorObjectType: 'sprint',
                                                                    name: 'Sprint 42',
                                                                    url: 'https://thatworks.atlassian.net/issues/?jql=Sprint%20%3D%20%22Sprint%2042%22',
                                                                },
                                                                {
                                                                    connectorObjectType: 'project',
                                                                    name: 'Project X Dev',
                                                                    url: 'https://thatworks.atlassian.net/browse/TWD',
                                                                },
                                                            ],
                                                            url: 'https://thatworks.atlassian.net/browse/TWD-659',
                                                        },
                                                        {
                                                            title: 'Show item names in filter/search',
                                                            iconUrl:
                                                                'https://api.atlassian.com/ex/jira/50a0ba45-fdcb-42b0-8cc3-c0de7825c269/rest/api/2/universal_avatar/view/type/issuetype/avatar/10318?size=medium',
                                                            connector: 'linked#atlassian#jira',
                                                            id: '8f0a6766-d30d-4c58-895f-123a250e24dd',
                                                            properties: [
                                                                {
                                                                    name: 'Status',
                                                                    value: 'Done',
                                                                    color: '#36B37E',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'STATUS',
                                                                },
                                                                {
                                                                    name: 'Assignee',
                                                                    value: 'Varun Nair',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'ASSIGNEE',
                                                                },
                                                                {
                                                                    name: 'Priority',
                                                                    value: 'Medium',
                                                                    color: '#f79232',
                                                                    iconUrl:
                                                                        'https://thatworks.atlassian.net/images/icons/priorities/medium.svg',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'PRIORITY',
                                                                },
                                                            ],
                                                            parents: [
                                                                {
                                                                    connectorObjectType: 'Epic',
                                                                    name: 'v1 Slice',
                                                                    url: 'https://thatworks.atlassian.net/browse/TWD-627',
                                                                },
                                                                {
                                                                    connectorObjectType: 'sprint',
                                                                    name: 'Sprint 42',
                                                                    url: 'https://thatworks.atlassian.net/issues/?jql=Sprint%20%3D%20%22Sprint%2042%22',
                                                                },
                                                                {
                                                                    connectorObjectType: 'project',
                                                                    name: 'Project X Dev',
                                                                    url: 'https://thatworks.atlassian.net/browse/TWD',
                                                                },
                                                            ],
                                                            url: 'https://thatworks.atlassian.net/browse/TWD-641',
                                                        },
                                                        {
                                                            title: 'Progress indicators should show breakdown',
                                                            iconUrl:
                                                                'https://api.atlassian.com/ex/jira/50a0ba45-fdcb-42b0-8cc3-c0de7825c269/rest/api/2/universal_avatar/view/type/issuetype/avatar/10318?size=medium',
                                                            connector: 'linked#atlassian#jira',
                                                            id: '68bbb60c-bcff-4708-b1f8-a925f78ca403',
                                                            properties: [
                                                                {
                                                                    name: 'Status',
                                                                    value: 'Done',
                                                                    color: '#36B37E',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'STATUS',
                                                                },
                                                                {
                                                                    name: 'Assignee',
                                                                    value: 'Varun Nair',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'ASSIGNEE',
                                                                },
                                                                {
                                                                    name: 'Priority',
                                                                    value: 'Low',
                                                                    color: '#707070',
                                                                    iconUrl:
                                                                        'https://thatworks.atlassian.net/images/icons/priorities/low.svg',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'PRIORITY',
                                                                },
                                                            ],
                                                            parents: [
                                                                {
                                                                    connectorObjectType: 'Epic',
                                                                    name: 'v1 Slice',
                                                                    url: 'https://thatworks.atlassian.net/browse/TWD-627',
                                                                },
                                                                {
                                                                    connectorObjectType: 'sprint',
                                                                    name: 'Sprint 42',
                                                                    url: 'https://thatworks.atlassian.net/issues/?jql=Sprint%20%3D%20%22Sprint%2042%22',
                                                                },
                                                                {
                                                                    connectorObjectType: 'project',
                                                                    name: 'Project X Dev',
                                                                    url: 'https://thatworks.atlassian.net/browse/TWD',
                                                                },
                                                            ],
                                                            url: 'https://thatworks.atlassian.net/browse/TWD-661',
                                                        },
                                                        {
                                                            title: 'Render text in-line with images',
                                                            iconUrl:
                                                                'https://api.atlassian.com/ex/jira/50a0ba45-fdcb-42b0-8cc3-c0de7825c269/rest/api/2/universal_avatar/view/type/issuetype/avatar/10318?size=medium',
                                                            connector: 'linked#atlassian#jira',
                                                            id: 'a1381bd7-d844-41a7-81b5-7ae10a76a248',
                                                            properties: [
                                                                {
                                                                    name: 'Status',
                                                                    value: 'Done',
                                                                    color: '#36B37E',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'STATUS',
                                                                },
                                                                {
                                                                    name: 'Assignee',
                                                                    value: 'Varun Nair',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'ASSIGNEE',
                                                                },
                                                                {
                                                                    name: 'Priority',
                                                                    value: 'Lowest',
                                                                    color: '#999999',
                                                                    iconUrl:
                                                                        'https://thatworks.atlassian.net/images/icons/priorities/lowest.svg',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'PRIORITY',
                                                                },
                                                            ],
                                                            parents: [
                                                                {
                                                                    connectorObjectType: 'Epic',
                                                                    name: 'v1 Slice',
                                                                    url: 'https://thatworks.atlassian.net/browse/TWD-627',
                                                                },
                                                                {
                                                                    connectorObjectType: 'sprint',
                                                                    name: 'Sprint 42',
                                                                    url: 'https://thatworks.atlassian.net/issues/?jql=Sprint%20%3D%20%22Sprint%2042%22',
                                                                },
                                                                {
                                                                    connectorObjectType: 'project',
                                                                    name: 'Project X Dev',
                                                                    url: 'https://thatworks.atlassian.net/browse/TWD',
                                                                },
                                                            ],
                                                            url: 'https://thatworks.atlassian.net/browse/TWD-660',
                                                        },
                                                    ],
                                                },
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            type: 'tableCell',
                            attrs: { colspan: 1, rowspan: 1, colwidth: null, background: null },
                            content: [
                                {
                                    type: 'paragraph',
                                    attrs: { dir: null, ignoreBidiAutoUpdate: null },
                                    content: [
                                        { type: 'text', marks: [{ type: 'bold' }], text: '✨ Highlights: ' },
                                        {
                                            type: 'text',
                                            text: 'Tasks include support for dark mode, optimization of database queries, adding the ability to upload images, and conducting a security audit.',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    type: 'tableRow',
                    content: [
                        {
                            type: 'tableCell',
                            attrs: { colspan: 1, rowspan: 1, colwidth: [200], background: null },
                            content: [
                                {
                                    type: 'paragraph',
                                    attrs: { dir: null, ignoreBidiAutoUpdate: null },
                                    content: [
                                        {
                                            type: 'inline-insight-pill',
                                            attrs: {
                                                uuid: '3e0595c8-8e04-43ec-9703-2dde4beb98d0',
                                                data: {
                                                    value: 'PRs: 4 Merged',
                                                    connector: 'github',
                                                    items: [
                                                        {
                                                            title: 'HW-696',
                                                            connector: 'github',
                                                            id: 'e60c49cb-bade-4703-ba5f-6ed9ded8162c',
                                                            properties: [
                                                                {
                                                                    name: 'Status',
                                                                    value: 'closed',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'STATUS',
                                                                },
                                                            ],
                                                            parents: [
                                                                {
                                                                    connectorObjectType: 'branch',
                                                                    name: 'staging',
                                                                    url: 'https://github.com/',
                                                                },
                                                            ],
                                                            url: 'https://github.com/',
                                                        },
                                                        {
                                                            title: 'HW-692, HW-693, HW-691, HW-694, HW-682, HW-695',
                                                            connector: 'github',
                                                            id: 'cfbb4c13-0b29-4e6c-8138-7f9532fa4451',
                                                            properties: [
                                                                {
                                                                    name: 'Status',
                                                                    value: 'closed',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'STATUS',
                                                                },
                                                            ],
                                                            parents: [
                                                                {
                                                                    connectorObjectType: 'branch',
                                                                    name: 'staging',
                                                                    url: 'https://github.com',
                                                                },
                                                            ],
                                                            url: 'https://github.com',
                                                        },
                                                        {
                                                            title: 'HW-688, HW-687',
                                                            connector: 'github',
                                                            id: 'cc40883d-f27d-448a-8149-8065b195d67d',
                                                            properties: [
                                                                {
                                                                    name: 'Status',
                                                                    value: 'closed',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'STATUS',
                                                                },
                                                            ],
                                                            parents: [
                                                                {
                                                                    connectorObjectType: 'branch',
                                                                    name: 'staging',
                                                                    url: 'https://github.com/',
                                                                },
                                                            ],
                                                            url: 'https://github.com/',
                                                        },
                                                        {
                                                            title: 'HW-684, HW-686',
                                                            connector: 'github',
                                                            id: 'f53810a2-ae50-4a0e-a911-9e35a873af2b',
                                                            properties: [
                                                                {
                                                                    name: 'Status',
                                                                    value: 'closed',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'STATUS',
                                                                },
                                                            ],
                                                            parents: [
                                                                {
                                                                    connectorObjectType: 'branch',
                                                                    name: 'staging',
                                                                    url: 'https://github.com/',
                                                                },
                                                            ],
                                                            url: 'https://github.com/',
                                                        },
                                                    ],
                                                },
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            type: 'tableCell',
                            attrs: { colspan: 1, rowspan: 1, colwidth: null, background: null },
                            content: [
                                {
                                    type: 'paragraph',
                                    attrs: { dir: null, ignoreBidiAutoUpdate: null },
                                    content: [
                                        { type: 'text', marks: [{ type: 'bold' }], text: '✨ Highlights: ' },
                                        {
                                            type: 'text',
                                            text: 'Pull requests for HW-696 and HW-695 have been merged addressing modifying the debug helper function, fixing a Stripe connection issue, resolving layout and ranking problems, and updating database queries.',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            type: 'heading',
            attrs: {
                dir: null,
                ignoreBidiAutoUpdate: null,
                level: 2,
            },
            content: [
                {
                    type: 'text',
                    text: 'Design: Overdue And Due Soon',
                },
            ],
        },
        {
            type: 'insight-pill',
            attrs: {
                uuid: '0d911952-a856-4349-8c38-d11bdf221a28',
                data: [
                    {
                        connector: 'clickup',
                        title: [
                            {
                                __typename: 'InsightPillValue',
                                value: 'V1 Design:',
                                type: 'STRING',
                            },
                            {
                                __typename: 'InsightPillValue',
                                value: ' 0% complete',
                                type: 'STRING',
                            },
                        ],
                        iconUrl: null,
                        data: [
                            {
                                __typename: 'InsightPillData',
                                value: '2 In Progress',
                                color: '#ee5e99',
                                iconUrl: null,
                                itemUuids: [],
                            },
                            {
                                __typename: 'InsightPillData',
                                value: '1 To Do',
                                color: '#5f55ee',
                                iconUrl: null,
                                itemUuids: [],
                            },
                        ],
                        items: [],
                    },
                ],
            },
        },
        {
            type: 'table',
            attrs: { dir: null, ignoreBidiAutoUpdate: null },
            content: [
                {
                    type: 'tableRow',
                    content: [
                        {
                            type: 'tableCell',
                            attrs: { colspan: 1, rowspan: 1, colwidth: [200], background: null },
                            content: [
                                {
                                    type: 'paragraph',
                                    attrs: { dir: null, ignoreBidiAutoUpdate: null },
                                    content: [
                                        {
                                            type: 'inline-insight-pill',
                                            attrs: {
                                                uuid: '3e0595c8-8e04-43ec-9703-2dde4beb98d0',
                                                data: {
                                                    value: 'Tasks: 2 scheduled',
                                                    connector: 'clickup',
                                                    items: [
                                                        {
                                                            title: 'Update the logo to match new guidelines',
                                                            connector: 'clickup',
                                                            id: 'daadbf0c-9ddf-468e-8058-fb037a3904c7',
                                                            properties: [
                                                                {
                                                                    name: 'Priority',
                                                                    value: 'No priority',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'PRIORITY',
                                                                },
                                                                {
                                                                    name: 'Status',
                                                                    value: 'In Progress',
                                                                    color: '#ee5e99',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'STATUS',
                                                                },
                                                                {
                                                                    name: 'Assignee',
                                                                    value: 'Eric Soderberg',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'ASSIGNEE',
                                                                },
                                                                {
                                                                    name: 'Due Date',
                                                                    value: '2024-04-10T03:00:00.000Z',
                                                                    valueType: 'DATE_ISO',
                                                                    propertyType: 'END_DATE',
                                                                },
                                                            ],
                                                            parents: [
                                                                {
                                                                    connectorObjectType: 'List',
                                                                    name: 'v1 Design',
                                                                    url: 'https://app.clickup.com/',
                                                                },
                                                            ],
                                                            url: 'https://app.clickup.com/',
                                                        },
                                                        {
                                                            title: 'Landing page revamp',
                                                            connector: 'clickup',
                                                            id: 'a1526adf-3966-4551-a265-049da734a05e',
                                                            properties: [
                                                                {
                                                                    name: 'Priority',
                                                                    value: 'No priority',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'PRIORITY',
                                                                },
                                                                {
                                                                    name: 'Status',
                                                                    value: 'scheduled',
                                                                    color: '#ee5e99',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'STATUS',
                                                                },
                                                                {
                                                                    name: 'Assignee',
                                                                    value: 'John Yoo',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'ASSIGNEE',
                                                                },
                                                                {
                                                                    name: 'Due Date',
                                                                    value: '2024-04-15T03:00:00.000Z',
                                                                    valueType: 'DATE_ISO',
                                                                    propertyType: 'END_DATE',
                                                                },
                                                            ],
                                                            parents: [
                                                                {
                                                                    connectorObjectType: 'List',
                                                                    name: 'v1 Design',
                                                                    url: 'https://app.clickup.com/',
                                                                },
                                                            ],
                                                            url: 'https://app.clickup.com/',
                                                        },
                                                    ],
                                                },
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            type: 'tableCell',
                            attrs: { colspan: 1, rowspan: 1, colwidth: null, background: null },
                            content: [
                                {
                                    type: 'paragraph',
                                    attrs: { dir: null, ignoreBidiAutoUpdate: null },
                                    content: [
                                        { type: 'text', marks: [{ type: 'bold' }], text: '✨ Highlights: ' },
                                        {
                                            type: 'text',
                                            text: 'Two tasks about updating the logo icon and designing the landing page are in progress but overdue.',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    type: 'tableRow',
                    content: [
                        {
                            type: 'tableCell',
                            attrs: { colspan: 1, rowspan: 1, colwidth: [200], background: null },
                            content: [
                                {
                                    type: 'paragraph',
                                    attrs: { dir: null, ignoreBidiAutoUpdate: null },
                                    content: [
                                        {
                                            type: 'inline-insight-pill',
                                            attrs: {
                                                uuid: '3e0595c8-8e04-43ec-9703-2dde4beb98d0',
                                                data: {
                                                    value: 'Task: 1 reached out',
                                                    connector: 'clickup',
                                                    items: [
                                                        {
                                                            title: 'v1 onboarding prototype',
                                                            connector: 'clickup',
                                                            id: '40344c4f-fbc0-4b8d-b177-c80b524911fa',
                                                            properties: [
                                                                {
                                                                    name: 'Priority',
                                                                    value: 'No priority',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'PRIORITY',
                                                                },
                                                                {
                                                                    name: 'Status',
                                                                    value: 'reached out',
                                                                    color: '#5f55ee',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'STATUS',
                                                                },
                                                                {
                                                                    name: 'Assignee',
                                                                    value: 'John Yoo',
                                                                    valueType: 'STRING',
                                                                    propertyType: 'ASSIGNEE',
                                                                },
                                                                {
                                                                    name: 'Due Date',
                                                                    value: '2024-04-08T03:00:00.000Z',
                                                                    valueType: 'DATE_ISO',
                                                                    propertyType: 'END_DATE',
                                                                },
                                                            ],
                                                            parents: [
                                                                {
                                                                    connectorObjectType: 'List',
                                                                    name: 'v1 Design',
                                                                    url: 'https://app.clickup.com/',
                                                                },
                                                            ],
                                                            url: 'https://app.clickup.com/t/86946bpnj',
                                                        },
                                                    ],
                                                },
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            type: 'tableCell',
                            attrs: { colspan: 1, rowspan: 1, colwidth: null, background: null },
                            content: [
                                {
                                    type: 'paragraph',
                                    attrs: { dir: null, ignoreBidiAutoUpdate: null },
                                    content: [
                                        { type: 'text', marks: [{ type: 'bold' }], text: '✨ Highlights: ' },
                                        {
                                            type: 'text',
                                            text: 'The design for v1 onboarding prototype has not started and is due in 2 days.',
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
};
