// Copyright 2021
// ThatWorks.xyz Limited

import { useQuery } from '@apollo/client';
import { Colors } from '@thatworks/colors';
import { ConnectorName } from '@thatworks/connector-api';
import { joinPagesPaths, Pages } from '@thatworks/shared-frontend/pages';
import { Anchor, Box, Spinner, Text } from 'grommet';
import { FormLock } from 'grommet-icons';
import { useEffect, useState } from 'react';
import { gql } from '../../../../../__generated__';
import { ConnectorConnectedState, SlackChannel, SlackTeamChannels } from '../../../../../__generated__/graphql';
import { DropdownMenuComponent } from '../../../../../components/DropdownMenu';
import { useTelemetryContext } from '../../../../../components/TelemetryContext';
import { useUserStateContext } from '../../../../../components/UserContext';
import { useNavNoRerender } from '../../../../../shared/UseNavNoRerender';

const GET_SLACK_CHANNELS = gql(/* GraphQL */ `
    query GetSlackChannels($connectorUserId: String!) {
        slackChannels(connectorUserId: $connectorUserId) {
            teamId
            teamName
            channels {
                id
                name
                private
            }
        }
    }
`);

function SlackChannelList(props: {
    connectorUserId: string;
    onSelected: (channels: SlackChannel[]) => void;
    selectedIds: string[];
}): JSX.Element | null {
    const { postErrorMessage } = useUserStateContext();
    const { logger } = useTelemetryContext();
    const [selected, setSelected] = useState<
        {
            label: string;
            id: string;
        }[]
    >([]);

    const { data, loading } = useQuery(GET_SLACK_CHANNELS, {
        variables: {
            connectorUserId: props.connectorUserId,
        },
        onError: (error) => {
            postErrorMessage({ title: 'Error', shortDesc: 'Failed to get Slack channels' });
            logger.error(error.message);
        },
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (!data.slackChannels) {
                return;
            }

            const alreadySelected: {
                label: string;
                id: string;
            }[] = [];
            data.slackChannels.channels.forEach((ch) => {
                if (props.selectedIds.includes(ch.id)) {
                    alreadySelected.push({ label: ch.name, id: ch.id });
                }
            });
            setSelected(alreadySelected);
        },
    });

    if (loading || !data) {
        return <Spinner />;
    }

    if (!data.slackChannels) {
        return null;
    }

    return (
        <Box>
            <DropdownMenuComponent
                label={
                    selected.length === 0
                        ? `Team: ${data.slackChannels.teamName}`
                        : selected.length === 1
                        ? selected[0].label
                        : `${selected.length} channels selected`
                }
                options={data.slackChannels.channels.map((ch) => ({
                    id: ch.id,
                    label: ch.name,
                    icon: ch.private ? (
                        <FormLock size="18px" />
                    ) : (
                        <Text size="18px" style={{ marginRight: '2px' }}>
                            #
                        </Text>
                    ),
                }))}
                selected={selected}
                onSelectionChange={(sel) => {
                    setSelected(sel);
                    if (data.slackChannels) {
                        const channelIds = sel.map((s) => s.id);
                        props.onSelected(
                            data.slackChannels.channels.filter((channel) => channelIds.includes(channel.id)),
                        );
                    }
                }}
                getTextProps={() => ({ color: Colors.dark_3, size: '16px', style: { fontWeight: 'bold' } })}
                boxProps={{
                    pad: '5px 16px',
                    border: { color: Colors.accent_3 },
                    justify: 'between',
                    round: '8px',
                    margin: '0',
                }}
            />
        </Box>
    );
}

export function SlackChannels(props: {
    onSelectionChange: (slackNotificationState: SlackTeamChannels[]) => void;
    existingNotifications: SlackTeamChannels[];
}): JSX.Element | null {
    const { userState } = useUserStateContext();
    const [slackConnectors, setSlackConnectors] = useState<ConnectorConnectedState[]>([]);
    const navigate = useNavNoRerender();
    const [teamChannels, setTeamChannels] = useState<Map<string, SlackTeamChannels>>(
        new Map(
            props.existingNotifications.map((n) => [
                n.teamId,
                { teamId: n.teamId, teamName: n.teamName, channels: n.channels },
            ]),
        ),
    );

    useEffect(() => {
        if (!userState) {
            setSlackConnectors([]);
            return;
        }

        setSlackConnectors(userState.connectedConnectors.filter((c) => c.connector === ConnectorName.SLACK));
    }, [userState]);

    if (slackConnectors.length === 0) {
        return (
            <Text>
                <Anchor onClick={() => navigate(joinPagesPaths([Pages.app.root, Pages.app.subs.connect.root]))}>
                    Connect
                </Anchor>{' '}
                Slack to receive notifications.
            </Text>
        );
    }

    return (
        <Box direction="row" gap="xsmall" align="center">
            <Text>Share to Slack</Text>
            {slackConnectors.map((s) => (
                <SlackChannelList
                    connectorUserId={s.connectorUserId}
                    key={s.connectorUserId}
                    onSelected={(channels) => {
                        const newMap = new Map(teamChannels);
                        newMap.set(s.connectorUserId, {
                            teamId: s.connectorUserId,
                            teamName: s.accountDisplayName,
                            channels: channels,
                        });
                        setTeamChannels(newMap);
                        props.onSelectionChange(Array.from(newMap.values()));
                    }}
                    selectedIds={
                        props.existingNotifications
                            .find((n) => n.teamId === s.connectorUserId)
                            ?.channels.map((c) => c.id) || []
                    }
                />
            ))}
        </Box>
    );
}
