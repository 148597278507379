// Copyright 2021
// ThatWorks.xyz Limited

import { DateTime } from 'luxon';

export function getReadableDateString(d: Date, now = new Date(), futurePrefix?: string) {
    const from = DateTime.fromJSDate(d);
    const to = DateTime.fromJSDate(now);
    const diff = to.diff(from);
    const days = diff.as('days');

    function getPrefix(): string {
        if (!futurePrefix) {
            return '';
        }
        if (days < 0) {
            return `${futurePrefix} `;
        }
        return '';
    }

    const absDays = Math.abs(days);
    if (absDays > 14) {
        return `${getPrefix()}${d.toLocaleDateString()}`;
    }

    if (absDays < 7) {
        const s = to.minus(diff).toRelativeCalendar({ unit: 'days', base: DateTime.fromJSDate(now) });
        if (s) {
            return s;
        }
    }

    if (absDays <= 14) {
        const s = to.minus(diff).toRelativeCalendar({ unit: 'weeks', base: DateTime.fromJSDate(now) });
        if (s) {
            return s;
        }
    }

    const str = to.minus(diff).toRelativeCalendar({ base: DateTime.fromJSDate(now) });
    if (str) {
        return str;
    }
    return `${getPrefix()}${d.toLocaleDateString()}`;
}

export function getReadableDateTimeString(d: Date) {
    return `${d.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })} ${getReadableDateString(d)}`;
}

export function getReadableDateTimeStringLuxon(d: DateTime, now?: DateTime) {
    return `${d.toLocaleString(DateTime.TIME_SIMPLE)} ${getReadableDateString(
        d.toJSDate(),
        now?.toJSDate() || undefined,
    )}`;
}

export function getReadableTimeString(d: Date) {
    return `${d.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })}`;
}

export function getDateMinusDays(numDays: number) {
    const d = new Date();
    d.setDate(d.getDate() - numDays);
    return d;
}

export function getHoursMinutesFromString(
    hourStr: string,
    minStr: string,
    amPm: string,
): { hour: number; minute: number } {
    let hour = Number(hourStr);
    const minute = Number(minStr);

    if (hour < 0 || hour > 12 || hour === undefined) {
        throw new Error(`Hour value is outside the accepted range of 1 to 12: ${hourStr}`);
    }
    if (minute < 0 || minute > 59 || hour === undefined) {
        throw new Error(`Minute value is outside the accepted range of 0 to 59: ${minStr}`);
    }

    const ampmLower = amPm.toLowerCase();
    if (ampmLower !== 'am' && ampmLower !== 'pm') {
        throw new Error(`am/pm string is invalid: ${amPm}`);
    }

    if (amPm.toLowerCase() === 'am' && hour === 12) {
        hour = 0;
    } else if (amPm.toLowerCase() === 'pm') {
        hour += 12;
    }
    return {
        hour,
        minute,
    };
}

export function getStringsFromHoursMinutes(
    hour: number,
    minute: number,
): { hour: string; minute: string; amPm: 'am' | 'pm' } {
    if (hour < 0 || hour > 23 || hour === undefined) {
        throw new Error(`Hour value is outside the accepted range of 1 to 12: ${hour}`);
    }
    if (minute < 0 || minute > 59 || hour === undefined) {
        throw new Error(`Minute value is outside the accepted range of 0 to 59: ${minute}`);
    }

    let amPm: 'am' | 'pm' = 'am';

    if (hour === 0) {
        hour = 12;
    } else if (hour > 12) {
        hour -= 12;
        amPm = 'pm';
    }

    return {
        hour: String(hour).padStart(2),
        minute: String(minute).padStart(2),
        amPm,
    };
}

export function getAmPmStringFromHoursMinutes(hour: number, minute: number): string {
    if (hour < 0 || hour > 23) {
        throw new Error(`Hour value is outside the accepted range of 0 to 23: ${hour}`);
    }
    if (minute < 0 || minute > 59) {
        throw new Error(`Hour value is outside the accepted range of 0 to 59: ${minute}`);
    }

    const minuteString = String(minute).padStart(2, '0');
    if (hour === 0) {
        return `${12}:${minuteString}am`;
    }
    if (hour > 12) {
        return `${hour - 12}:${minuteString}pm`;
    }
    return `${hour}:${minuteString}am`;
}
