// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box, Drop, Text, TextInput } from 'grommet';
import { useMemo, useRef, useState } from 'react';
import { SimpleBorderTextButton } from '../../../../../../components/FilterDropdown';
import { ToolbarButton } from '../../../../../../components/prosemirror/ToolbarButton';
import { DatePreset } from '../timeline-date-selection';
import { PropertyFilterSelection } from './helpers';

const TIME_PERIOD_OPTIONS = [DatePreset.Today, DatePreset.StartOfWeek, DatePreset.EndOfWeek];

export function isTimePeriodOption(value: string): boolean {
    return (TIME_PERIOD_OPTIONS as string[]).includes(value);
}

export function DateInput(props: {
    selection: PropertyFilterSelection;
    onChange: (dateValue: string) => void;
}): JSX.Element {
    const [buttonActive, setButtonActive] = useState(false);
    const [valueOption, setValueOption] = useState(
        isTimePeriodOption(props.selection.value) ? props.selection.value : '',
    );
    const [valueDays, setValueDays] = useState(!isTimePeriodOption(props.selection.value) ? props.selection.value : '');
    const ref = useRef<HTMLDivElement>(null);

    const label = useMemo(() => {
        return valueOption ? valueOption : `${valueDays} days`;
    }, [valueOption, valueDays]);

    const updateValueDays = (inputValue: string) => {
        // Check if we have an input value (other than only "-")
        if (inputValue && inputValue !== '-') {
            // Cast the value to number
            const num = Number(inputValue);

            // Check if the num is valid
            if (isNaN(num) || !isFinite(num) || num < -9999 || num > 9999) {
                // Since the num is not valid, return
                return;
            }
        }

        // Here the input value is already validated so we just need to set the new value
        setValueDays(inputValue);
        setValueOption('');
    };

    const removePadding = () => {
        let cleanedValue: string;

        // Check if the value starts with '-'
        if (valueDays.startsWith('-')) {
            // Remove the '-' and then remove the padding
            cleanedValue = valueDays.slice(1).replace(/^0+(?=[0-9]+)/, '');

            // Add the '-' to the beginning of the value
            cleanedValue = `-${cleanedValue}`;
        } else {
            // Remove the padding
            cleanedValue = valueDays.replace(/^0+(?=[0-9]+)/, '');
        }

        // Check if the value is different from the cleaned value. It means that we have removed the padding.
        if (cleanedValue && valueDays !== cleanedValue) {
            // Set the value with the cleaned value (value without padding)
            setValueDays(cleanedValue);
        }
    };

    const onClickOutside = () => {
        // Remove the padding
        removePadding();

        // Call the parent's onChange with the value
        props.onChange(valueOption ? valueOption : valueDays);

        // Set the button as not active
        setButtonActive(false);
    };

    // Return
    return (
        <Box ref={ref} width={{ width: '100%', min: '100%' }} pad={{ horizontal: '10px' }}>
            <ToolbarButton
                active={buttonActive}
                label={label}
                onClick={async () => setButtonActive(!buttonActive)}
                textProps={{ weight: 'bold' }}
            />
            {buttonActive && (
                <Drop
                    plain
                    target={ref.current || undefined}
                    align={{ top: 'bottom', left: 'left' }}
                    pad={'xxsmall'}
                    onClickOutside={onClickOutside}
                >
                    <Box
                        background={{ color: Colors.light_2 }}
                        border={{ color: Colors.neutral_1, size: '1px' }}
                        pad={{ vertical: 'xsmall' }}
                        round={'10px'}
                        elevation="xsmall"
                        direction="column"
                        align="center"
                        justify="center"
                    >
                        {/* Time period enum options */}
                        <Box
                            direction="column"
                            align="center"
                            justify="center"
                            gap="xsmall"
                            pad={{ horizontal: 'xsmall' }}
                        >
                            <Text size="16px" weight="bold">
                                Choose a time period
                            </Text>
                            <Box align="center" direction="row" gap="xsmall" justify="center">
                                {TIME_PERIOD_OPTIONS.map((p, pi) => (
                                    <SimpleBorderTextButton
                                        key={`date-preset-${pi}`}
                                        onClick={() => {
                                            setValueOption(p);
                                            setValueDays('');
                                        }}
                                        render={(hover) => (
                                            <Text
                                                size="14px"
                                                color={hover || valueOption === p ? Colors.brand : Colors.dark_4}
                                            >
                                                {p}
                                            </Text>
                                        )}
                                        border={{
                                            color: valueOption === p ? Colors.brand : Colors.neutral_1,
                                            size: '1px',
                                        }}
                                    />
                                ))}
                            </Box>
                        </Box>
                        {/* Line separator */}
                        <Box
                            fill="horizontal"
                            border={{
                                color: Colors.light_6,
                                side: 'bottom',
                                size: '1px',
                            }}
                            margin={{ vertical: 'xsmall' }}
                        />
                        {/* Custom period value */}
                        <Box
                            direction="column"
                            align="center"
                            justify="center"
                            gap="xsmall"
                            pad={{ horizontal: 'xsmall' }}
                        >
                            <Text size="16px" weight="bold">
                                Or define a custom value
                            </Text>
                            <Box direction="row" align="center" justify="center" gap="xxsmall">
                                <Box
                                    border={{ color: Colors.neutral_1, size: '1px' }}
                                    round="8px"
                                    height="30px"
                                    direction="row"
                                    align="center"
                                    justify="center"
                                    width="48px"
                                >
                                    <TextInput
                                        plain
                                        type="numeric"
                                        pattern="[1-9]+"
                                        value={valueDays}
                                        onChange={(e) => updateValueDays(e.target.value)}
                                    />
                                </Box>
                                <Text size="16px">days relative to today</Text>
                            </Box>
                        </Box>
                    </Box>
                </Drop>
            )}
        </Box>
    );
}
