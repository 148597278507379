// Copyright 2021
// ThatWorks.xyz Limited

import { Blank, IconProps } from 'grommet-icons';

export const Hashtag = (props: IconProps & JSX.IntrinsicElements['svg']) => (
    <Blank {...props}>
        <svg
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="miter"
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <line x1="6" y1="22" x2="8" y2="2"></line>
                <line x1="16" y1="22" x2="18" y2="2"></line>
                <line x1="3" y1="7" x2="22" y2="7"></line>
                <line x1="2" y1="17" x2="21" y2="17"></line>
            </g>
        </svg>
    </Blank>
);
