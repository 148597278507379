// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box, TextInput } from 'grommet';
import { Copy } from 'grommet-icons';
import { IconButtonV2 } from './IconButton';
import { useUserStateContext } from './UserContext';

export function TextFieldCopyToClipboard(props: { text: string }): JSX.Element {
    const { postErrorMessage } = useUserStateContext();
    return (
        <Box
            direction="row"
            align="center"
            gap="xxsmall"
            pad="xxsmall"
            round="10px"
            background={{ color: Colors.light_2 }}
        >
            <IconButtonV2
                icon={(hover) => <Copy size="16px" color={hover ? Colors.accent_3 : undefined} />}
                onClickWithSpinner={async () => {
                    await window.navigator.clipboard.writeText(props.text);
                    postErrorMessage({ title: 'Copied', shortDesc: 'Link copied to clipboard' }, 'info');
                }}
            />
            <TextInput value={props.text} style={{ borderWidth: 0, padding: 0, borderRadius: '5px' }} />
        </Box>
    );
}
