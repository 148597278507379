// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box, TextInput } from 'grommet';
import debounce from 'lodash.debounce';
import { useMemo, useState } from 'react';
import { isCustomProperty } from '../activity-property-helpers';
import { getPropertyFromLabel, getPropertyLabel, Property, PropertyFilterSelection } from './helpers';

function calculateSuggestedInput(
    propertiesOptions: { label: string; value: Property }[],
    userInput: string,
): { label: string; value: Property }[] {
    // Return all the suggestions if user input is not valid
    if (userInput == null || userInput === '') {
        return propertiesOptions;
    }

    // Normalise the user input
    const userInputNormalised = userInput.toLowerCase();

    // Initialize suggested input
    let suggestedInputs: { label: string; value: Property }[] = [];

    // Iterate over the suggestions to get all the suggestions that match the user input
    propertiesOptions.forEach((p) => {
        const valueNormalised = p.label.toLowerCase();
        const valueDescontructed = valueNormalised.split(/[ ]|[-]/);
        for (const v of valueDescontructed) {
            if (v.startsWith(userInputNormalised) && valueNormalised !== userInputNormalised) {
                suggestedInputs.push(p);
                break;
            }
        }
    });

    // Return suggested inputs
    return suggestedInputs;
}

export function PropertySelect(props: {
    onChange: (filter: PropertyFilterSelection) => void;
    selection: PropertyFilterSelection;
    propertiesOptions: { label: string; value: Property }[];
}): JSX.Element {
    const onChange = props.onChange;
    const [value, setValue] = useState<{ value: string | undefined; label: string }>(() => {
        let label = '';
        if (props.selection.property) {
            // if the property is a custom property, show what the user has entered
            // otherwise show the label of the property
            label = isCustomProperty(props.selection.property)
                ? props.selection.property
                : getPropertyLabel(props.selection.property as Property);
        }

        return {
            value: props.selection.property,
            label: label,
        };
    });
    const debounceValue = useMemo(() => {
        return debounce((v: { value: string | undefined; label: string }) => {
            const newSelection = { ...props.selection };
            // if the user enters a property name that matches a pre-existing property
            // then set the property to that pre-existing property enum.
            // this ensures that the correct ops/values are populated.
            newSelection.property = getPropertyFromLabel(v.value) || v.value;
            newSelection.value = '';
            onChange(newSelection);
        }, 200);
    }, [onChange, props.selection]);

    const suggestedInputs = useMemo(() => {
        // Calculate the suggested input
        const suggestedInput = calculateSuggestedInput(props.propertiesOptions, value.label);
        return suggestedInput;
    }, [props.propertiesOptions, value.label]);

    return (
        <Box
            border={{ color: Colors.accent_3, size: '1px' }}
            round="8px"
            height="32px"
            direction="row"
            align="center"
            justify="center"
            width="210px"
        >
            <TextInput
                plain
                placeholder="Property: choose or type"
                value={value.label}
                onChange={(e) => {
                    debounceValue({ value: e.target.value, label: e.target.value });
                    setValue({ value: e.target.value, label: e.target.value });
                }}
                suggestions={suggestedInputs}
                onSuggestionSelect={({ suggestion }) => {
                    debounceValue(suggestion);
                    setValue(suggestion);
                }}
            />
        </Box>
    );
}
