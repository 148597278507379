// Copyright 2021
// ThatWorks.xyz Limited

import { ConnectorName } from '@thatworks/connector-api';
import { FilterHierarchyType } from '@thatworks/shared-frontend/collections';
import { PickerResult } from './picker-result';

export enum GdriveMime {
    DOCS = 'application/vnd.google-apps.document',
    SHEET = 'application/vnd.google-apps.spreadsheet',
    SLIDES = 'application/vnd.google-apps.presentation',
    FOLDER = 'application/vnd.google-apps.folder',
}

let gapiLoaded = false;

interface gapiType {
    load: (t: string, onLoad: () => void) => void;
    client: { load: (t: string) => Promise<void> };
}

function map(
    connectorUserId: string,
    res: {
        id: string;
        mimeType: string;
        name: string;
        type: string;
        lastEditedUtc: number;
        iconUrl: string;
        url: string;
        embedUrl: string;
        parentId: string;
    },
): PickerResult {
    return {
        connector: ConnectorName.GOOGLE_DRIVE,
        iconUrl: res.iconUrl,
        id: {
            connectorObjectType: res.mimeType,
            connectorUserId,
            idFromConnector: res.id,
        },
        name: res.name,
        url: res.url,
        hierarchyType:
            res.mimeType === GdriveMime.FOLDER
                ? FilterHierarchyType.GoogleDriveFolder
                : FilterHierarchyType.SpecificItem,
    };
}

export interface GoogleFilePickerOptions {
    mimeTypes: GdriveMime[];
    multiSelect: boolean;
    showFolders: boolean;
}

function picker(
    connectorUserId: string,
    accessToken: string,
    options: GoogleFilePickerOptions,
): Promise<PickerResult[]> {
    const g = window as any;

    return new Promise((resolve, reject) => {
        try {
            const pickerBuilder = new g.google.picker.PickerBuilder()
                .enableFeature(g.google.picker.Feature.SUPPORT_DRIVES)
                .setDeveloperKey(process.env.REACT_APP_GOOGLE_API_KEY)
                .setAppId(process.env.REACT_APP_GOOGLE_CLIENT_ID)
                .setOAuthToken(accessToken)
                .setCallback((response: any) => {
                    if (response.action === g.google.picker.Action.PICKED) {
                        if (Array.isArray(response.docs)) {
                            resolve(response.docs.map((d: any) => map(connectorUserId, d)));
                        }
                    } else if (response.action === g.google.picker.Action.CANCEL) {
                        resolve([]);
                    }
                });

            pickerBuilder.addView(
                new g.google.picker.DocsView()
                    .setMimeTypes(options.mimeTypes.join(','))
                    .setMode(g.google.picker.DocsViewMode.LIST),
            );
            if (options.showFolders) {
                pickerBuilder.addView(
                    new g.google.picker.DocsView(g.google.picker.ViewId.FOLDERS)
                        .setMimeTypes(GdriveMime.FOLDER)
                        .setIncludeFolders(true)
                        .setSelectFolderEnabled(true),
                );
            }
            pickerBuilder.addView(
                new g.google.picker.DocsView()
                    .setMimeTypes(options.mimeTypes.join(','))
                    .setMode(g.google.picker.DocsViewMode.LIST)
                    .setIncludeFolders(true)
                    .setSelectFolderEnabled(options.showFolders)
                    .setEnableDrives(true),
            );

            if (options.multiSelect) {
                pickerBuilder.enableFeature(g.google.picker.Feature.MULTISELECT_ENABLED);
            }
            pickerBuilder.build().setVisible(true);
        } catch (error) {
            reject(error);
        }
    });
}

async function maybeLoadScript(url: string, elementId: string): Promise<HTMLScriptElement> {
    const sc = document.getElementById(elementId);
    if (!sc) {
        const script = document.createElement('script');
        script.src = url;
        script.id = elementId;
        document.body.appendChild(script);

        return new Promise((resolve) => {
            script.onload = () => {
                resolve(script);
            };
        });
    }
    return sc as HTMLScriptElement;
}

async function loadGapi(): Promise<void> {
    return new Promise((resolve) => {
        const gapiObj = (window as any).gapi as gapiType;
        gapiObj.load('client:picker', async () => {
            await gapiObj.client.load('https://www.googleapis.com/discovery/v1/apis/drive/v3/rest');
            resolve();
        });
    });
}

export async function gdrivePicker(
    connectorUserId: string,
    accessToken: string,
    options: GoogleFilePickerOptions,
): Promise<PickerResult[]> {
    await maybeLoadScript('https://apis.google.com/js/api.js', 'google_driver_picker');
    if (!gapiLoaded) {
        await loadGapi();
        gapiLoaded = true;
    }
    return picker(connectorUserId, accessToken, options);
}
