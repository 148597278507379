// Copyright 2021
// ThatWorks.xyz Limited

import { Box, DropType, Text } from 'grommet';

export function tooltipProps(
    text: string | React.ReactNode,
    capitalize?: boolean,
    width?: string,
    tooltipDropProps?: DropType,
) {
    return {
        plain: true,
        content: (
            <Box
                border={{ color: 'light-4' }}
                background="background-front"
                round="4px"
                width={width || 'max-content'}
                pad="xxsmall"
            >
                <Text
                    size="12px"
                    color="dark-4"
                    textAlign="center"
                    style={capitalize ? { textTransform: 'capitalize' } : undefined}
                >
                    {text}
                </Text>
            </Box>
        ),
        dropProps: tooltipDropProps || {
            margin: 'xsmall',
        },
    };
}
