// Copyright 2021
// ThatWorks.xyz Limited

import { Box } from 'grommet';
import { BasicPage3, BasicPage3NavFunction, DefaultPagePaddingPixels } from '../../../../components/BasicPage3';
import { getLabel, SidebarNav } from '../../../../components/SidebarNav';
import { TwHeading } from '../../../../components/TwHeading';
import { withAuth } from '../../../../components/withAuth';
import InternalSettingsBody from './InternalSettingsBody';

function InternalSettingsV2(props: { onNav: BasicPage3NavFunction }): JSX.Element {
    return (
        <BasicPage3
            activeNavButton={SidebarNav.InternalSettings}
            browserPageTitle={getLabel(SidebarNav.InternalSettings)}
            onNav={props.onNav}
        >
            <Box overflow={{ vertical: 'auto' }} height="100%" width="100%">
                <Box height={{ min: 'max-content' }}>
                    <Box
                        pad={{
                            left: DefaultPagePaddingPixels,
                            right: DefaultPagePaddingPixels,
                            top: 'small',
                            bottom: 'xsmall',
                        }}
                        border={{ side: 'bottom', color: 'border' }}
                    >
                        <TwHeading>Internal Settings</TwHeading>
                    </Box>
                    <Box
                        pad={{
                            left: DefaultPagePaddingPixels,
                            right: DefaultPagePaddingPixels,
                            top: 'small',
                            bottom: 'medium',
                        }}
                    >
                        <InternalSettingsBody />
                    </Box>
                </Box>
            </Box>
        </BasicPage3>
    );
}

export default withAuth(InternalSettingsV2);
