// Copyright 2021
// ThatWorks.xyz Limited

import { NEW_COLLECTION_URL_ID, QueryParams } from '@thatworks/shared-frontend/pages';
import { useEffect, useState } from 'react';
import { useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import { useUserStateContext } from '../../../../components/UserContext';
import { withAuth } from '../../../../components/withAuth';
import { ParamKey } from '../../../../shared/param-keys';
import WorkspaceCreate from './WorkspaceCreate';
import WorkspaceRouter from './WorkspaceRouter';

function WorkspaceOutlet(): JSX.Element {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const { organizationId } = useUserStateContext();
    const setTitle: (title: string) => void = useOutletContext();
    const [workspaceUuid, setWorkspaceUuid] = useState<string | undefined>();
    const [showComposer, setShowComposer] = useState(false);

    useEffect(() => {
        setShowComposer(searchParams.get(QueryParams.ShowComposer) != null ? true : false);
        const uuid = params[ParamKey.WorkspaceUuid];
        setWorkspaceUuid(uuid);
        setTitle('Topics');
    }, [params, searchParams, setTitle]);

    if (!organizationId || !workspaceUuid) {
        return <></>;
    }

    return workspaceUuid === NEW_COLLECTION_URL_ID ? (
        <WorkspaceCreate organizationId={organizationId} />
    ) : (
        <WorkspaceRouter
            organizationId={organizationId}
            workspaceUuid={workspaceUuid}
            showComposer={showComposer}
            setTitle={setTitle}
        />
    );
}

export default withAuth(WorkspaceOutlet);
