// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box, Spinner, Text } from 'grommet';
import { GridAreaType } from 'grommet/utils';
import { useMemo } from 'react';
import { TwHeading } from '../../../../../components/TwHeading';
import { FontFamily } from '../../../../../theme';
import { TemplatePreview } from '../../templates/components/TemplatePreview';
import { TemplateBlockState } from './TemplateBlock';

export function MagicComposerPreview(props: {
    gridArea: GridAreaType;
    templateName: string;
    blocks: TemplateBlockState[];
    loadingPreview: boolean;
}): JSX.Element {
    const hasSummaries = useMemo(() => props.blocks.some((b) => b.summary != null), [props.blocks]);

    return (
        <Box gridArea={props.gridArea} background={Colors.background_back}>
            {/* Title */}
            <Box
                height={{ min: 'min-content' }}
                direction="row"
                justify="between"
                align="center"
                margin="xsmall"
                gap="xsmall"
            >
                <Text alignSelf="start" size="28px" color={Colors.dark_2} style={{ fontFamily: FontFamily.Callout }}>
                    Summary Preview
                </Text>
            </Box>

            {/* No summaries or loading */}
            {(!hasSummaries || props.loadingPreview) && (
                <Box alignSelf="center" flex justify="center" align="center" width={'80%'} margin={{ bottom: '60px' }}>
                    {/* No summaries */}
                    {!hasSummaries && !props.loadingPreview && (
                        <Text
                            color={Colors.neutral_4}
                            size="28px"
                            textAlign="center"
                            style={{ fontFamily: FontFamily.Heading }}
                        >
                            Compose it and see the magic
                        </Text>
                    )}
                    {/* Loading */}
                    {props.loadingPreview && <Spinner size="medium" />}
                </Box>
            )}
            {hasSummaries && !props.loadingPreview && (
                <Box gap="small" overflow={{ vertical: 'auto' }} pad={{ horizontal: 'xsmall' }}>
                    <TwHeading level={3}>{props.templateName}</TwHeading>
                    <TemplatePreview blocks={props.blocks} editable={false} />
                </Box>
            )}
        </Box>
    );
}
