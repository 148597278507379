// Copyright 2021
// ThatWorks.xyz Limited

import { useCallback, useEffect, useRef } from 'react';
import { NavigateOptions, To, useNavigate } from 'react-router-dom';

// https://github.com/remix-run/react-router/issues/7634
export function useNavNoRerender() {
    const navigate = useNavigate();
    const navigateRef = useRef({ navigate });

    useEffect(() => {
        navigateRef.current.navigate = navigate;
    }, [navigate]);

    return useCallback((to: To, options?: NavigateOptions) => {
        navigateRef.current.navigate(to, options);
    }, []);
}

export type NavNoRenderFunction = ReturnType<typeof useNavNoRerender>;
