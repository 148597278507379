// Copyright 2021
// ThatWorks.xyz Limited

import { Box } from 'grommet';
import { Icon } from 'grommet-icons';
import { ReactNode } from 'react';
import { FontFamily } from '../theme';
import { TwHeading } from './TwHeading';

export function PageTitle(props: { children?: ReactNode; icon: Icon }): JSX.Element {
    return (
        <Box direction="row" align="center" gap="12px">
            <props.icon size="20px" color="brand" />
            <TwHeading style={{ fontFamily: FontFamily.Callout }} level={2}>
                {props.children}
            </TwHeading>
        </Box>
    );
}
