// Copyright 2021
// ThatWorks.xyz Limited

import { Anchor } from 'grommet';
import { GenericBrandPage } from '../../components/GenericBrandPage';
import { TwHeading } from '../../components/TwHeading';

export function NotFound(): JSX.Element {
    return (
        <GenericBrandPage browserPageTitle="Not found" fill>
            <TwHeading>Sorry, couldn't find anything here!</TwHeading>
            <TwHeading level={3}>
                <Anchor href={`https://${process.env.REACT_APP_DOMAIN}`}>Go back</Anchor>
            </TwHeading>
        </GenericBrandPage>
    );
}
