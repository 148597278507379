// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { DynamicConfigs, Gates } from '@thatworks/shared-frontend/metrics';
import { getMainWebsitePageUrl } from '@thatworks/shared-frontend/pages';
import { Box, Nav, Text } from 'grommet';
import { useCallback, useMemo } from 'react';
import { useConfig, useGate } from 'statsig-react';
import { FontFamily } from '../theme';
import { ReactComponent as TwIconName } from './../icons/tw_header_name.svg';
import { ReactComponent as TwIcon } from './../icons/tw_icon.svg';
import { BasicPage3NavFunction } from './BasicPage3';
import Divider from './Divider';
import { AccountNavDropButton, NavButton } from './NavButtons';
import { getLabel, getNavButtonId, getNavIcon, SidebarNav } from './SidebarNav';
import WorkspacesNav from './WorkspacesNav';

export enum SidebarState {
    Opened = 'opened',
    Overlay = 'overlay',
    Closed = 'closed',
}

export const SidebarPadding = { opened: { horizontal: 22, vertical: 25 }, closed: { horizontal: 22, vertical: 25 } };
export const SidebarContentWidth = { opened: 216, closed: 40 };

export function TwSidebar(props: {
    sidebarState: SidebarState;
    onHover: (hover: boolean) => void;
    isHover: boolean;
    organizationId?: string;
    organizationDisplayName?: string;
    onboarding?: boolean;
    activeNavButton?: SidebarNav;
    onNav?: BasicPage3NavFunction;
}): JSX.Element {
    const urlConfig = useConfig(DynamicConfigs.FrontendUrls);
    const showDataSourcesGate = useGate(Gates.FrontendShowDataSources);

    // Closed sidebar boolean
    const closed = useMemo(() => props.sidebarState === SidebarState.Closed, [props.sidebarState]);

    // Sidebar content width
    const sidebarContentWidth = useMemo(
        () => (closed ? SidebarContentWidth.closed : SidebarContentWidth.opened),
        [closed],
    );

    // Sidebar padding
    const sidebarPadding = useMemo(() => (closed ? SidebarPadding.closed : SidebarPadding.opened), [closed]);

    // Sidebar width
    const sidebarWidth = useMemo(
        () => sidebarContentWidth + sidebarPadding.horizontal * 2,
        [sidebarContentWidth, sidebarPadding],
    );

    // Default sidebarNavButton
    const sidebarNavButton = useCallback(
        (sidebarNav: SidebarNav, onClick?: () => void) => (
            <NavButton
                label={!closed ? getLabel(sidebarNav) : undefined}
                icon={getNavIcon(sidebarNav)}
                active={props.activeNavButton === sidebarNav}
                onClick={
                    onClick ??
                    (() => {
                        if (props.onNav) {
                            props.onNav({ nav: sidebarNav });
                        }
                    })
                }
                buttonId={getNavButtonId(sidebarNav)}
                width="100%"
            />
        ),
        [closed, props],
    );

    return (
        <Box
            gridArea="sidebar"
            style={{ position: props.sidebarState === SidebarState.Overlay ? 'fixed' : 'relative', zIndex: '1' }}
            align="center"
            height={{ height: '100%' }}
            background={Colors.dark_2}
            pad={{ horizontal: `${sidebarPadding.horizontal}px`, vertical: `${sidebarPadding.vertical}px` }}
            width={`${sidebarWidth}px`}
            onMouseEnter={() => props.onHover(true)}
            onMouseLeave={() => props.onHover(false)}
            overflow={{ vertical: 'auto' }}
        >
            <Box
                width={`${sidebarContentWidth}px`}
                gap={'medium'}
                height={{ height: '100%', min: '650px' }}
                justify={closed ? 'center' : 'stretch'}
            >
                {/* Logo */}
                <a href={getMainWebsitePageUrl('')}>
                    <Box direction="row" gap="14px" margin={{ horizontal: '4px' }} align="center">
                        <TwIcon width="32px" height="32px" fill={Colors.white} />
                        {!closed && <TwIconName width="160px" height="26px" fill={Colors.white} />}
                    </Box>
                </a>

                <Box gap={'xxsmall'}>
                    {/* Magic composer*/}
                    {!props.onboarding && sidebarNavButton(SidebarNav.Create)}

                    {/* Automations */}
                    {!props.onboarding && sidebarNavButton(SidebarNav.Automations)}

                    {/* Automations */}
                    {!props.onboarding && sidebarNavButton(SidebarNav.SavedQueries)}
                </Box>

                {/* Topics */}
                {!props.onboarding && props.organizationId && (
                    <Nav flex>
                        <WorkspacesNav organizationId={props.organizationId} closed={closed} />
                    </Nav>
                )}

                {/* Footer */}
                <Box gap="8px" align={closed ? 'center' : 'stretch'} width={'100%'}>
                    {/* Data sources */}
                    {!props.onboarding && showDataSourcesGate.value && sidebarNavButton(SidebarNav.Data)}

                    {/* Divider */}
                    {!props.onboarding && <Divider color={Colors.neutral_2} margin={'0px'} />}

                    {/* Apps */}
                    {sidebarNavButton(SidebarNav.Connect)}

                    {/* Account */}
                    <AccountNavDropButton
                        closed={closed}
                        activeNavButton={props.activeNavButton}
                        onNav={(n) => {
                            if (props.onNav) {
                                props.onNav({ nav: n });
                            }
                        }}
                        width="100%"
                    />

                    {/* Help */}
                    {sidebarNavButton(SidebarNav.Help, () => {
                        const url = urlConfig.config.get<string>('knowledgeBase', '');
                        if (url) {
                            window.open(url, '_blank', 'noopener,noreferrer');
                        }
                    })}

                    {/* Organization name */}
                    {props.organizationDisplayName && (
                        <Box align="center" fill="horizontal" pad={{ top: 'xxsmall' }} height="22px">
                            {!closed && (
                                <Text
                                    size="12px"
                                    style={{ fontFamily: FontFamily.Mono }}
                                    color={Colors.neutral_4}
                                    truncate
                                >
                                    {props.organizationDisplayName}
                                </Text>
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
}
