// Copyright 2021
// ThatWorks.xyz Limited

import { Pages } from '@thatworks/shared-frontend/pages';
import { Route, Routes } from 'react-router-dom';
import { withAuth } from '../../../../components/withAuth';
import WorkspaceSettingsView from './WorkspaceSettings';
import WorkspaceView from './WorkspaceView';

function WorkspaceRouter(props: {
    organizationId: string;
    workspaceUuid: string;
    showComposer: boolean;
    setTitle: (title: string) => void;
}): JSX.Element {
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <WorkspaceView
                        organizationUuid={props.organizationId}
                        workspaceUuid={props.workspaceUuid}
                        showComposer={props.showComposer}
                        setTitle={props.setTitle}
                    />
                }
            />
            <Route
                path={Pages.app.subs.topics.subs.settings}
                element={
                    <WorkspaceSettingsView
                        organizationId={props.organizationId}
                        workspaceId={props.workspaceUuid}
                        setTitle={props.setTitle}
                    />
                }
            />
        </Routes>
    );
}

export default withAuth(WorkspaceRouter);
