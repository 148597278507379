// Copyright 2021
// ThatWorks.xyz Limited

import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';

// Defined as an interface rather than an abstract class
// so that the functions can be bound to console.log to preserve
// line numbers
export interface Logger {
    debug: (msg: string) => void;
    info: (msg: string) => void;
    warn: (msg: string) => void;
    error: (msg: string) => void;
    exception: (error: unknown) => void;
}

export abstract class Telemetry {
    abstract get logger(): Logger;
}

export class StubTelemetry extends Telemetry {
    get logger(): Logger {
        return {
            debug: console.debug.bind(console.log),
            info: console.info.bind(console.log),
            warn: console.info.bind(console.log),
            error: console.error.bind(console.log),
            exception: (err) => console.error(err instanceof Error ? err.message : JSON.stringify(err)),
        };
    }
}

export class AppInsightsTelemetry extends Telemetry {
    private _appInsights: ApplicationInsights;
    private _reactPlugin: ReactPlugin;
    private _logger: Logger;

    constructor(instrumentationKey: string) {
        super();

        this._reactPlugin = new ReactPlugin();
        this._appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: instrumentationKey,
                disableFetchTracking: false,
                disableCookiesUsage: true,
                enableAutoRouteTracking: true,
                extensions: [this._reactPlugin],
            },
        });
        this._appInsights.loadAppInsights();
        this._logger = {
            debug: (msg: string) => this.logDebug(msg),
            info: (msg: string) => this.logInfo(msg),
            warn: (msg: string) => this.logWarn(msg),
            error: (msg: string) => this.logError(msg),
            exception: (err: unknown) => this.logException(err),
        };
    }

    get instance(): ApplicationInsights {
        return this._appInsights;
    }

    get logger(): Logger {
        return this._logger;
    }

    private logDebug(msg: string): void {
        this._appInsights.trackTrace({ message: msg, severityLevel: SeverityLevel.Verbose });
    }

    private logInfo(msg: string): void {
        this._appInsights.trackTrace({ message: msg, severityLevel: SeverityLevel.Information });
    }

    private logWarn(msg: string): void {
        this._appInsights.trackTrace({ message: msg, severityLevel: SeverityLevel.Warning });
    }

    private logError(msg: string): void {
        this._appInsights.trackTrace({ message: msg, severityLevel: SeverityLevel.Error });
    }

    private logException(error: unknown): void {
        if (error instanceof Error) {
            this._appInsights.trackException({ exception: error });
        } else {
            this.logError(JSON.stringify(error));
        }
    }
}
