// Copyright 2021
// ThatWorks.xyz Limited

import { useMutation } from '@apollo/client';
import { Colors } from '@thatworks/colors';
import { getReadableDateTimeString, getReadableDateTimeStringLuxon } from '@thatworks/shared-frontend/date-helpers';
import { joinPagesPaths, Pages, QueryParams } from '@thatworks/shared-frontend/pages';
import { Box, DropButton, Text, Tip } from 'grommet';
import { Down, Edit, Globe, Link, Secure, Trash } from 'grommet-icons';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import {
    WorkspacePostsQuery,
    WorkspacePostUserAbilities,
    WorkspacePostVisibility,
} from '../../../../__generated__/graphql';
import { ConfirmationModal, DeleteConfirmationModal } from '../../../../components/ConfirmationModal';
import DropdownButton from '../../../../components/DropdownButton';
import { useTelemetryContext } from '../../../../components/TelemetryContext';
import { TextFieldCopyToClipboard } from '../../../../components/TextFieldCopyToClipboard';
import { tooltipProps } from '../../../../components/Tooltip';
import { TwHeading } from '../../../../components/TwHeading';
import { useUserStateContext } from '../../../../components/UserContext';
import { FontFamily } from '../../../../theme';
import { UPDATE_WORKSPACE_POST } from '../magic-composer/components/MagicComposerEditPost';
import { CommentSection } from './PostComment';
import { WorkspacePostContent } from './WorkspacePostContent';

enum WorkspacePostSettings {
    Edit = 'Edit',
    Delete = 'Delete',
    CopyLink = 'Copy link',
    MakePublic = 'Make public',
    MakePrivate = 'Make private',
}

function getPostUrl(workspaceId: string, postId: string, visibility: WorkspacePostVisibility): string {
    if (visibility === WorkspacePostVisibility.Private) {
        const url = `${window.location.origin}${joinPagesPaths([
            Pages.app.root,
            Pages.app.subs.topics.root,
            workspaceId,
        ])}?${QueryParams.PostExpansion}=${postId}`;
        return url;
    } else {
        const url = `${window.location.origin}${joinPagesPaths([Pages.public.root, Pages.public.subs.post, postId])}`;
        return url;
    }
}

function WorkspacePostDropButton(props: {
    userAbilities: WorkspacePostUserAbilities[];
    visibility: WorkspacePostVisibility;
    workspaceId: string;
    postId: string;
    onEdit: () => void;
    onDelete: () => void;
    onVisibilityChange: (visibility: WorkspacePostVisibility) => void;
}): JSX.Element {
    const [open, setOpen] = useState(false);
    const { postErrorMessage } = useUserStateContext();

    return (
        <DropButton
            icon={
                <Down
                    color={Colors.brand}
                    size="small"
                    onClick={() => {
                        setOpen(true);
                    }}
                />
            }
            style={{ padding: '0px' }}
            dropContent={
                <Box background="transparent" direction="row">
                    <Box elevation="medium" background="brand" round="5px" pad={{ top: '10px', bottom: '10px' }}>
                        {props.userAbilities.includes(WorkspacePostUserAbilities.CanEdit) && (
                            <DropdownButton
                                label={WorkspacePostSettings.Edit}
                                icon={Edit}
                                onClick={async () => {
                                    props.onEdit();
                                    setOpen(false);
                                }}
                            />
                        )}
                        <DropdownButton
                            label={WorkspacePostSettings.CopyLink}
                            icon={Link}
                            onClick={async () => {
                                await window.navigator.clipboard.writeText(
                                    getPostUrl(props.workspaceId, props.postId, props.visibility),
                                );
                                postErrorMessage(
                                    {
                                        title: 'Copied',
                                        shortDesc:
                                            props.visibility === WorkspacePostVisibility.Public
                                                ? 'Public link copied to clipboard'
                                                : 'Private link copied to clipboard',
                                    },
                                    'info',
                                );
                                setOpen(false);
                            }}
                        />
                        {props.userAbilities.includes(WorkspacePostUserAbilities.CanEdit) && (
                            <DropdownButton
                                label={
                                    props.visibility === WorkspacePostVisibility.Private
                                        ? WorkspacePostSettings.MakePublic
                                        : WorkspacePostSettings.MakePrivate
                                }
                                icon={props.visibility === WorkspacePostVisibility.Private ? Globe : Secure}
                                onClick={async () => {
                                    props.onVisibilityChange(
                                        props.visibility === WorkspacePostVisibility.Private
                                            ? WorkspacePostVisibility.Public
                                            : WorkspacePostVisibility.Private,
                                    );
                                    setOpen(false);
                                }}
                            />
                        )}
                        {props.userAbilities.includes(WorkspacePostUserAbilities.CanDelete) && (
                            <DropdownButton
                                label={WorkspacePostSettings.Delete}
                                icon={Trash}
                                onClick={async () => {
                                    props.onDelete();
                                    setOpen(false);
                                }}
                            />
                        )}
                    </Box>
                </Box>
            }
            dropAlign={{ left: 'right', top: 'bottom' }}
            dropProps={{
                background: 'transparent',
                elevation: 'none',
                onEsc: () => {
                    setOpen(false);
                },
                onClickOutside: () => {
                    setOpen(false);
                },
            }}
            open={open}
        />
    );
}

const SHOW_COMMENT_SECTION = false;

function WorkspacePostVisibilityModal(props: {
    organizationId: string;
    workspaceId: string;
    postId: string;
    targetVisiblity: WorkspacePostVisibility;
    onClose: () => void;
}): JSX.Element {
    const { logger } = useTelemetryContext();
    const { postErrorMessage } = useUserStateContext();
    const [updatePost] = useMutation(UPDATE_WORKSPACE_POST, {
        onError: (err) => {
            postErrorMessage({ title: `Error`, shortDesc: 'Failed to update post' });
            logger.error(err.message);
        },
    });

    return (
        <ConfirmationModal
            cta={{
                color: Colors.accent_3,
                text: props.targetVisiblity === WorkspacePostVisibility.Public ? 'Make public' : 'Make private',
            }}
            message={
                props.targetVisiblity === WorkspacePostVisibility.Public
                    ? 'By making this post public, anyone with this link will be able view the post.'
                    : 'By making this post private, only users with access to this Topic will be able to view the post.'
            }
            title={
                props.targetVisiblity === WorkspacePostVisibility.Public
                    ? 'Make this post public?'
                    : 'Make this post private?'
            }
            onClose={() => props.onClose()}
            onCancel={() => props.onClose()}
            onConfirm={async () => {
                await updatePost({
                    variables: {
                        postId: props.postId,
                        organizationId: props.organizationId,
                        props: {
                            visibility: props.targetVisiblity,
                        },
                    },
                });

                if (props.targetVisiblity === WorkspacePostVisibility.Public) {
                    const url = getPostUrl(props.workspaceId, props.postId, props.targetVisiblity);
                    await window.navigator.clipboard.writeText(url);
                    postErrorMessage({ title: 'Copied', shortDesc: 'Public link copied to clipboard' }, 'info');
                }
            }}
            showModal={true}
        >
            {props.targetVisiblity === WorkspacePostVisibility.Public && (
                <TextFieldCopyToClipboard text={getPostUrl(props.workspaceId, props.postId, props.targetVisiblity)} />
            )}
        </ConfirmationModal>
    );
}

export function WorkspacePost(props: {
    workspaceId?: string;
    post: WorkspacePostsQuery['workspacePosts']['posts'][number];
    onEdit: () => void;
    onDelete: () => Promise<void>;
    plain?: boolean;
    handleOverflow?: boolean;
}): JSX.Element {
    const [showVisibilityModal, setShowVisibilityModal] = useState<WorkspacePostVisibility | undefined>(undefined);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const { organizationId } = useUserStateContext();

    const authorString = useMemo(() => {
        if (props.post.template) {
            return 'Automation';
        }
        return `${props.post.creators.map((creator) => creator.name).join(', ')}`;
    }, [props.post.template, props.post.creators]);

    const dateHeader = useMemo(() => {
        const from = DateTime.fromJSDate(new Date(props.post.created));
        const now = DateTime.now();
        const diff = now.diff(from);
        const days = diff.as('days');

        if (Math.abs(days) <= 2) {
            const s = now.minus(diff).toRelativeCalendar({ unit: 'days', base: now });
            if (s) {
                return s;
            }
        }

        if (Math.abs(days) <= 14) {
            const s = now.minus(diff).toRelativeCalendar({ unit: 'weeks', base: now });
            if (s) {
                return s;
            }
        }

        return now.minus(diff).toRelativeCalendar({ base: now });
    }, [props.post.created]);

    return (
        <Box
            key={props.post.id}
            border={props.plain ? undefined : { color: Colors.border_light, size: '1px' }}
            round={props.plain ? undefined : '30px'}
            elevation={props.plain ? undefined : 'small'}
            pad="small"
            background={{ color: Colors.background_back }}
        >
            <Box
                direction="row"
                pad={{ vertical: 'xsmall', horizontal: 'small' }}
                background={{ color: Colors.accent_2 }}
                justify="between"
                align="baseline"
                height={{ min: 'max-content' }}
                round="15px"
                elevation="xsmall"
            >
                <Box direction="row" align="center" gap="6px">
                    {props.workspaceId && props.post.visibility === WorkspacePostVisibility.Public && (
                        <Tip content="Anyone with a link can view this post">
                            <Globe size="12px" color={Colors.brand} />
                        </Tip>
                    )}
                    <Text
                        size="12px"
                        style={{
                            fontFamily: FontFamily.Callout,
                            cursor: 'default',
                            textTransform: 'uppercase',
                            letterSpacing: '1px',
                        }}
                        weight="bold"
                        color={Colors.brand}
                    >
                        {dateHeader}
                    </Text>
                </Box>
                <Box gap="xsmall" direction="row" align="center">
                    <Box direction="row" align="center" gap="2px" round="5px">
                        <Text
                            size="12px"
                            style={{ fontFamily: FontFamily.Mono, cursor: 'default' }}
                            color={Colors.brand}
                            tip={
                                props.post.template && props.post.template.templateName
                                    ? tooltipProps(`Automation: ${props.post.template.templateName}`)
                                    : undefined
                            }
                        >
                            By{' '}
                            <span style={{ fontFamily: FontFamily.Callout, letterSpacing: '0.5px' }}>
                                {authorString}
                            </span>{' '}
                            at{' '}
                            <span style={{ fontFamily: FontFamily.Callout, letterSpacing: '0.5px' }}>
                                {getReadableDateTimeString(new Date(props.post.created))}
                            </span>
                        </Text>
                    </Box>

                    {props.workspaceId && (
                        <WorkspacePostDropButton
                            userAbilities={props.post.userAbilities}
                            onDelete={() => setShowDeleteModal(true)}
                            onEdit={props.onEdit}
                            postId={props.post.id}
                            workspaceId={props.workspaceId}
                            visibility={props.post.visibility}
                            onVisibilityChange={(visibility) => setShowVisibilityModal(visibility)}
                        />
                    )}
                </Box>
            </Box>
            <Box
                overflow={props.handleOverflow ? { vertical: 'auto' } : undefined}
                round={props.plain ? undefined : '8px'}
            >
                <Box height={props.handleOverflow ? { min: 'fit-content' } : undefined}>
                    <Box pad="xxsmall">
                        <Box pad={{ top: 'xsmall', bottom: 'xxsmall' }}>
                            <TwHeading level={3} style={{ fontFamily: FontFamily.Heading }}>
                                {props.post.title}
                            </TwHeading>
                        </Box>
                        {props.post.template && props.post.template.automation && (
                            <Box>
                                <Text size="small">
                                    Automated summary from{' '}
                                    {getReadableDateTimeStringLuxon(
                                        DateTime.fromISO(props.post.template.automation.fromDateIso),
                                    )}{' '}
                                    to{' '}
                                    {getReadableDateTimeStringLuxon(
                                        DateTime.fromISO(props.post.template.automation.toDateIso),
                                    )}
                                    .
                                </Text>
                            </Box>
                        )}
                        <WorkspacePostContent content={props.post.content} />
                    </Box>
                </Box>
            </Box>
            {SHOW_COMMENT_SECTION && props.workspaceId && (
                <CommentSection workspaceId={props.workspaceId} post={props.post} />
            )}
            {organizationId && props.workspaceId && showVisibilityModal !== undefined && (
                <WorkspacePostVisibilityModal
                    onClose={() => setShowVisibilityModal(undefined)}
                    organizationId={organizationId}
                    postId={props.post.id}
                    workspaceId={props.workspaceId}
                    targetVisiblity={showVisibilityModal}
                />
            )}
            <DeleteConfirmationModal
                itemName={props.post.title}
                onCancel={() => setShowDeleteModal(false)}
                onClose={() => setShowDeleteModal(false)}
                onConfirm={() => props.onDelete()}
                showModal={showDeleteModal}
            />
        </Box>
    );
}
