// Copyright 2021
// ThatWorks.xyz Limited

import { MetricBoxAttributes, MetricBoxNode } from '@thatworks/shared-frontend/prosemirror-nodes';
import { Box } from 'grommet';
import { FunctionComponent, useEffect, useState } from 'react';
import { MetricBox as MetricBoxQl, StringType } from '../../../../../../__generated__/graphql';
import { MetricBoxV2 } from '../../../../../../components/MetricBox';
import {
    BaseReactNodeComponentProps,
    BaseReactNodeInjected,
} from '../../../../../../components/prosemirror/BaseReactNode';

export class MetricBoxNodeReact extends BaseReactNodeInjected<MetricBoxAttributes> {
    _injectedNode = new MetricBoxNode();

    ComponentToRender: FunctionComponent<BaseReactNodeComponentProps<MetricBoxAttributes>> = (props) => {
        const [data, setData] = useState<MetricBoxQl[] | undefined>(undefined);

        useEffect(() => {
            if (
                props.currentAttributes.metrics &&
                Array.isArray(props.currentAttributes.metrics) &&
                props.currentAttributes.metrics.length
            ) {
                const parsed = props.currentAttributes.metrics.map((m) => {
                    const r: MetricBoxQl = {
                        connector: m.connector,
                        parents: m.parents,
                        deltas: [],
                        title: m.title,
                        value: m.value,
                        valueFormatted: m.valueFormatted,
                        itemUuid: m.itemUuid,
                        identifier: m.identifier,
                        xAxisValue: m.xAxisValue
                            ? {
                                  type: m.xAxisValue.type === 'date-iso' ? StringType.DateIso : StringType.DateIso,
                                  value: m.xAxisValue.value,
                              }
                            : undefined,
                    };
                    return r;
                });
                setData(parsed);
            } else if (
                // for older schemas that used serialized json as a string
                props.currentAttributes.data &&
                typeof props.currentAttributes.data === 'string' &&
                props.currentAttributes.data.length > 0
            ) {
                const parsed = (
                    typeof props.currentAttributes.data === 'string'
                        ? JSON.parse(props.currentAttributes.data)
                        : props.currentAttributes.data
                ) as MetricBoxQl[] | undefined;
                setData(parsed);
            }
        }, [props.currentAttributes.data, props.currentAttributes.metrics]);

        return (
            <Box direction="row" gap="xxsmall" width="max-content">
                {data && data.map((d, di) => <MetricBoxV2 mode="summary" metricBox={d} key={di} />)}
            </Box>
        );
    };
}
