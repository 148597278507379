// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box } from 'grommet';
import { FormTrash } from 'grommet-icons';
import { IconButtonV2 } from '../../../../../../components/IconButton';

export function RemoveGroupButton(props: { onDelete: () => void }): JSX.Element {
    return (
        <Box>
            <IconButtonV2
                icon={(hover, disabled) => (
                    <FormTrash size="16px" color={hover && !disabled ? Colors.brand : undefined} />
                )}
                tooltip={'Remove group'}
                onClick={props.onDelete}
            />
        </Box>
    );
}
