// Copyright 2021
// ThatWorks.xyz Limited

import { Events } from '@thatworks/shared-frontend/metrics';
import { Box } from 'grommet';
import { useStatsigLogEffect } from 'statsig-react';
import { BasicPage3, BasicPage3NavFunction, DefaultPagePaddingPixels } from '../../../../components/BasicPage3';
import { PageTitle } from '../../../../components/PageTitle';
import { getNavIcon, SidebarNav } from '../../../../components/SidebarNav';
import { useUserStateContext } from '../../../../components/UserContext';
import { withAuth } from '../../../../components/withAuth';
import ConnectBody from './ConnectBody';

function ConnectV2(props: { onboarding: boolean; onNav: BasicPage3NavFunction }): JSX.Element {
    const { postErrorMessage } = useUserStateContext();
    useStatsigLogEffect(Events.FrontendConnectAppsPageLoaded);

    return (
        <BasicPage3
            activeNavButton={SidebarNav.Connect}
            browserPageTitle={props.onboarding ? 'Welcome' : 'Connected Apps'}
            onNav={props.onNav}
            onboarding={props.onboarding}
        >
            <Box overflow={{ vertical: 'auto' }} height="100%" width="100%">
                <Box height={{ min: 'max-content' }}>
                    <Box
                        pad={{
                            left: DefaultPagePaddingPixels,
                            right: DefaultPagePaddingPixels,
                            top: 'small',
                            bottom: 'xsmall',
                        }}
                        border={{ side: 'bottom', color: 'border' }}
                    >
                        <PageTitle icon={getNavIcon(SidebarNav.Connect)}>
                            {props.onboarding ? 'Connect your apps' : 'Connected Apps'}
                        </PageTitle>
                    </Box>
                    <Box
                        pad={{
                            left: DefaultPagePaddingPixels,
                            right: DefaultPagePaddingPixels,
                            top: 'small',
                            bottom: 'small',
                        }}
                    >
                        <ConnectBody onboarding={props.onboarding} onError={(err) => postErrorMessage(err)} />
                    </Box>
                </Box>
            </Box>
        </BasicPage3>
    );
}

export default withAuth(ConnectV2);
