// Copyright 2021
// ThatWorks.xyz Limited

import { PatTestRequest, PatTestResponse } from '@thatworks/shared-frontend/connectors';
import * as emailValidator from 'email-validator';
import { Anchor, Box, Card, Text } from 'grommet';
import { useState } from 'react';
import CtaButtonSpinner from '../../components/CtaButtonSpinner';
import { GenericBrandPage } from '../../components/GenericBrandPage';
import { StringEntry } from '../../components/StringEntryCard';
import { TwHeading } from '../../components/TwHeading';
import Api, { ApiError } from '../../shared/Api';

export function Tester(): JSX.Element {
    const [atlassianBaseUrl, setAtlassianBaseUrl] = useState<string>();
    const [atlassianToken, setAtlassianToken] = useState<string>();
    const [atlassianResult, setAtlassianResult] = useState<{ message: string; isError: boolean }>();
    const [atlassianEmail, setAtlassianEmail] = useState<string>();

    return (
        <GenericBrandPage browserPageTitle="Atlassian Tester" fill>
            <Card pad="medium" gap="small">
                <TwHeading level={2}>Atlassian Token Tester</TwHeading>
                <Box gap="xxsmall">
                    <Text>1. Enter the url for your Atlassian site. For example: https://thatworks.atlassian.net</Text>
                    <StringEntry
                        onErrorChange={(err) => {}}
                        onValidation={(userInput, validated) => {
                            setAtlassianBaseUrl(validated);
                            return undefined;
                        }}
                        validationFunc={(v) => {
                            if (!v.startsWith('https')) {
                                return undefined;
                            }
                            if (v.endsWith('/')) {
                                return v.slice(0, v.length - 1);
                            }
                            return `${v}`;
                        }}
                    />
                </Box>
                <Box gap="xxsmall">
                    <Text>2. Enter the email address for your Atlassian account.</Text>
                    <StringEntry
                        onErrorChange={(err) => {}}
                        onValidation={(userInput, validated) => {
                            setAtlassianEmail(validated);
                            return undefined;
                        }}
                        validationFunc={(v) => {
                            return emailValidator.validate(v) ? v : undefined;
                        }}
                    />
                </Box>
                <Text>
                    3.{' '}
                    <Anchor href="https://id.atlassian.com/manage-profile/security/api-tokens" target="_blank">
                        Go to
                    </Anchor>{' '}
                    your Atlassian API token page
                </Text>
                <Text>4. Click on "Create API Token" and enter a name</Text>
                <Box gap="xxsmall">
                    <Text>5. Copy the generated token and paste it below</Text>
                    <StringEntry
                        onErrorChange={(err) => {}}
                        onValidation={(userInput, validated) => {
                            setAtlassianToken(validated);
                            return undefined;
                        }}
                        placeholder="Enter token"
                    />
                </Box>
                <Box width="150px">
                    <CtaButtonSpinner
                        label="Test"
                        onClick={async () => {
                            if (!atlassianBaseUrl || !atlassianEmail || !atlassianToken) {
                                return;
                            }

                            try {
                                const res = await Api.post<PatTestResponse, PatTestRequest>(
                                    [Api.routes.CONNECTORS, 'pat', 'test'],
                                    undefined,
                                    {
                                        data: {
                                            pat: atlassianToken,
                                            username: atlassianEmail,
                                            url: atlassianBaseUrl,
                                        },
                                    },
                                );

                                setAtlassianResult({
                                    message: res.message || res.error || `Unknown error`,
                                    isError:
                                        res.error !== undefined ||
                                        (res.error === undefined && res.message === undefined),
                                });
                            } catch (error) {
                                console.error('Failed', error);
                                if (error instanceof ApiError) {
                                    setAtlassianResult({
                                        message: `Failed! ${error.status} ${error.name} ${error.message}`,
                                        isError: true,
                                    });
                                } else {
                                    setAtlassianResult({ message: `Failed! ${JSON.stringify(error)}`, isError: true });
                                }
                            }
                        }}
                        disabled={atlassianToken === undefined || atlassianBaseUrl === undefined}
                    />
                </Box>
                {atlassianResult && (
                    <Box
                        background={atlassianResult.isError ? 'status-warning' : 'status-ok'}
                        pad="xsmall"
                        round="15px"
                    >
                        <Text>{atlassianResult.message}</Text>
                    </Box>
                )}
            </Card>
        </GenericBrandPage>
    );
}
