// Copyright 2021
// ThatWorks.xyz Limited

import { Box } from 'grommet';
import { MarginType, PadType } from 'grommet/utils';

export default function Divider(props: {
    color: string;
    pad?: PadType;
    margin?: MarginType;
    type?: 'horizontal' | 'vertical';
    size?: string;
    thickness?: string;
}): JSX.Element {
    return (
        <Box
            border={{
                color: props.color,
                side: props.type === 'vertical' ? 'right' : 'bottom',
                size: props.thickness || '1px',
            }}
            width={props.type === 'vertical' ? props.thickness || '1px' : props.size || '100%'}
            height={props.type === 'vertical' ? props.size || '100%' : props.thickness || '1px'}
            alignSelf="center"
            margin={props.margin ?? { top: '2px' }}
            pad={props.pad}
        />
    );
}
