// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box, Text } from 'grommet';
import { AddCircle } from 'grommet-icons';
import { IconButtonV2 } from '../../../../../../components/IconButton';
import { FontFamily } from '../../../../../../theme';
import { PropertyFilterGroup } from './helpers';

export function AddGroupButton(props: {
    onChange: (filter: PropertyFilterGroup[]) => void;
    propertyFilterGroups: PropertyFilterGroup[];
}): JSX.Element {
    return (
        <Box
            height={{ min: 'max-content' }}
            background={Colors.background_back}
            border={{ color: Colors.border_dark, size: '1px' }}
            pad="10px"
            round="5px"
        >
            <IconButtonV2
                icon={(hover, disabled) => <AddCircle size="16px" color={hover ? Colors.accent_3 : Colors.dark_2} />}
                label={() => (
                    <Text size="14px" style={{ fontFamily: FontFamily.Mono }}>
                        Add filter group
                    </Text>
                )}
                tooltip={'Add filter group'}
                reverse
                onClick={() => {
                    const newGroup: PropertyFilterGroup = {
                        propertyFilters: [
                            {
                                property: undefined,
                                operator: undefined,
                                value: '',
                            },
                        ],
                    };
                    const newSelection = [...props.propertyFilterGroups, newGroup];
                    props.onChange(newSelection);
                }}
            />
        </Box>
    );
}
