// Copyright 2021
// ThatWorks.xyz Limited

import { useLazyQuery, useMutation } from '@apollo/client';
import { Colors } from '@thatworks/colors';
import { joinPagesPaths, Pages, QueryParams } from '@thatworks/shared-frontend/pages';
import { Box, Button, Grid, Layer, Text } from 'grommet';
import { Add, Icon } from 'grommet-icons';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { gql } from '../../../../../__generated__';
import { WorkspacePostTemplateQuery } from '../../../../../__generated__/graphql';
import { CtaButtonSpinnerV2 } from '../../../../../components/CtaButtonSpinner';
import { useTelemetryContext } from '../../../../../components/TelemetryContext';
import { useUserStateContext } from '../../../../../components/UserContext';
import { Thunder } from '../../../../../icons/Thunder';
import { FontFamily } from '../../../../../theme';
import { CREATE_TEMPLATE, UPDATE_TEMPLATE } from '../../templates/components/template-queries';
import {
    createOrEditTemplate,
    getAutomation,
    getBlocks,
    getValidQueryBlocks,
    TemplateQl,
} from '../helpers/automation-helpers';
import { AutomationState, MagicComposerAutomate } from './MagicComposerAutomate';
import { MagicComposerBuilder } from './MagicComposerBuilder';
import { MagicComposerPreview } from './MagicComposerPreview';
import { MagicComposerQuery } from './MagicComposerQuery';
import { MagicComposerShareTo } from './MagicComposerShareTo';
import { getDefaultTemplateBlockProps, TemplateBlockState } from './TemplateBlock';

export const GENERATE_POST_TITLE = gql(/* GraphQL */ `
    query GeneratePostTitle($data: [String!]!) {
        generatePostTitleFromDataSources(data: $data)
    }
`);

export enum CreationMode {
    Automation = 'automation',
    Post = 'post',
    SavedQueries = 'saved-queries',
}

const ComposerOptions: { icon: Icon; title: string; label: string; mode: CreationMode }[] = [
    {
        icon: Thunder,
        title: 'New Automation',
        label: 'Automate routines like catchups, standups, and meetings',
        mode: CreationMode.Automation,
    },
    { icon: Add, title: 'New Post', label: 'Share ad-hoc information with others', mode: CreationMode.Post },
    {
        icon: Add,
        title: 'New Saved Query',
        label: 'A query that can be reused later to generate a summary',
        mode: CreationMode.SavedQueries,
    },
];

export function MagicComposer(props: {
    mode: CreationMode | null;
    edit: boolean;
    data?: WorkspacePostTemplateQuery['workspacePostTemplate'];
}): JSX.Element {
    // Initialize
    const navigate = useNavigate();
    const { logger } = useTelemetryContext();
    const { postErrorMessage } = useUserStateContext();
    const sessionCacheId = useRef(Math.random().toString(36).substring(7));
    const [templateName, setTemplateName] = useState(props.data?.title ?? '');
    const [blocks, setBlocks] = useState<TemplateBlockState[]>(getBlocks(props.data));
    const [automationState, setAutomationState] = useState<AutomationState>(getAutomation(props.data));
    const [slackNotifications, setSlackNotifications] = useState(props.data?.slackNotifications ?? []);
    const [showShareToModal, setShowShareToModal] = useState<boolean>(false);
    const [showAutomateModal, setShowAutomateModal] = useState<boolean>(false);
    const [showQueryModal, setShowQueryModal] = useState<boolean>(false);
    const [loadingPreview, setLoadingPreview] = useState<boolean>(false);

    // GraphQL
    const [createTemplateQl] = useMutation(CREATE_TEMPLATE, {
        onError: (error) => {
            postErrorMessage({ title: 'Error', shortDesc: 'Failed to create automation' });
            logger.error(error.message);
        },
    });
    const [updateTemplateQl] = useMutation(UPDATE_TEMPLATE, {
        onError: (error) => {
            postErrorMessage({ title: 'Error', shortDesc: 'Failed to update automation' });
            logger.error(error.message);
        },
    });
    const templateQl: TemplateQl = useMemo(
        () => ({
            createTemplateQl,
            updateTemplateQl,
        }),
        [createTemplateQl, updateTemplateQl],
    );

    const goToAutomations = useCallback(() => {
        navigate(joinPagesPaths([Pages.app.root, Pages.app.subs.automations]));
    }, [navigate]);

    const goToSavedQueries = useCallback(() => {
        navigate(joinPagesPaths([Pages.app.root, Pages.app.subs.savedQueries]));
    }, [navigate]);

    // Valid query blocks
    const validQueryBlocks = useMemo(() => getValidQueryBlocks(blocks), [blocks]);

    // Handle on save automation
    const onSaveAutomation = useCallback(
        async (title: string) => {
            // Create or edit template
            await createOrEditTemplate(
                title,
                validQueryBlocks,
                slackNotifications,
                props.data,
                templateQl,
                props.edit,
                automationState,
            );

            // Redirect to automations page
            goToAutomations();
        },
        [automationState, goToAutomations, props.data, props.edit, slackNotifications, templateQl, validQueryBlocks],
    );

    // Handle on save saved queries
    const onSaveSavedQueries = useCallback(
        async (title: string) => {
            // Create or edit template
            await createOrEditTemplate(title, validQueryBlocks, slackNotifications, props.data, templateQl, props.edit);

            // Redirect to saved queries page
            goToSavedQueries();
        },
        [goToSavedQueries, props.data, props.edit, slackNotifications, templateQl, validQueryBlocks],
    );

    // Flag to know if everything is ready to be saved
    const canSave = useMemo(() => validQueryBlocks.length > 0, [validQueryBlocks.length]);

    // Add empty block to the template
    const addBlock = () => {
        setBlocks((previousBlocks) => [
            ...previousBlocks,
            getDefaultTemplateBlockProps(previousBlocks[previousBlocks.length - 1] || undefined),
        ]);
    };

    // Update the blocks with the received block
    const updateBlock = (block: TemplateBlockState) => {
        setBlocks((previousBlocks) => {
            const newBlocks = [...previousBlocks];

            // Check if the block is still present in the blocks list. If so, update its value
            const blockIndex = newBlocks.findIndex((b) => b.id === block.id);
            if (blockIndex >= 0) {
                newBlocks[blockIndex] = block;
            }
            // Return the new state
            return newBlocks;
        });
    };

    // Delete the block received
    const deleteBlock = (blockIndex: number) => {
        setBlocks((pBlocks) => pBlocks.slice(0, blockIndex).concat(pBlocks.slice(blockIndex + 1)));
    };

    const dataSourcesDescription = useMemo(() => {
        return validQueryBlocks.map((block) => {
            const dataSources = block.scopes.map((s) => s.itemName);
            if (block.filterName) {
                return `${block.filterName} for ${dataSources.join(', ')}`;
            }
            return dataSources.join(', ');
        });
    }, [validQueryBlocks]);

    const [generateTitle, { loading: loadingTitle }] = useLazyQuery(GENERATE_POST_TITLE, {
        onCompleted: (data) => {
            if (data.generatePostTitleFromDataSources) {
                setTemplateName(data.generatePostTitleFromDataSources);
            }
        },
    });

    // Get page title and button label
    const { pageTitle, buttonLabel, action } = useMemo(() => {
        switch (props.mode) {
            case CreationMode.Post:
                return {
                    pageTitle: 'Create new post',
                    buttonLabel: 'Publish post',
                    action: async () => setShowShareToModal(true),
                };
            case CreationMode.SavedQueries:
                return {
                    pageTitle: 'Create new saved query',
                    buttonLabel: 'Save query',
                    action: async () => setShowQueryModal(true),
                };
            case CreationMode.Automation:
                return {
                    pageTitle: 'Create new automation',
                    buttonLabel: 'Save automation',
                    action: async () => setShowAutomateModal(true),
                };
            default:
                return { pageTitle: 'Create', buttonLabel: '', action: async () => {} };
        }
    }, [props.mode]);

    return (
        <>
            {!props.mode && <MagicComposerOptions pageTitle={pageTitle} />}
            {props.mode && (
                <Grid columns={['58%', '42%']} rows={['flex', 'min-content']} width="100%" height="100%" responsive>
                    {/* Magic composer builder */}
                    <MagicComposerBuilder
                        pageTitle={pageTitle}
                        gridArea="1/1/3/2"
                        templateName={templateName}
                        onUpdateTemplateName={(templateName) => setTemplateName(templateName)}
                        blocks={blocks}
                        onAddBlock={addBlock}
                        onUpdateBlock={updateBlock}
                        onDeleteBlock={deleteBlock}
                        sessionCacheId={sessionCacheId.current}
                        onLoading={(loading) => setLoadingPreview(loading)}
                    />

                    {/* Magic composer preview */}
                    <MagicComposerPreview
                        gridArea="1/2/3/3"
                        templateName={templateName}
                        blocks={blocks}
                        loadingPreview={loadingPreview}
                    />

                    {/* Action button */}
                    <Box
                        gridArea="2/2/3/3"
                        style={{ zIndex: '1' }}
                        direction="row"
                        background={{ color: Colors.background_back, opacity: 'strong' }}
                        gap="xsmall"
                        pad="xsmall"
                        justify="end"
                    >
                        <CtaButtonSpinnerV2
                            label={buttonLabel}
                            style={{ padding: '1px 14px' }}
                            onClick={async () => {
                                if (props.data == null) {
                                    generateTitle({ variables: { data: dataSourcesDescription } });
                                }
                                action();
                            }}
                            disabled={!canSave}
                        />
                    </Box>
                </Grid>
            )}

            {/* Share to modal */}
            {showShareToModal && (
                <Layer
                    onEsc={() => setShowShareToModal(false)}
                    onClickOutside={() => setShowShareToModal(false)}
                    background={{ color: 'unset' }}
                >
                    <MagicComposerShareTo
                        title={templateName}
                        blocks={blocks}
                        onCloseModal={() => setShowShareToModal(false)}
                        loadingSummary={loadingPreview}
                        loadingTitle={loadingTitle}
                    />
                </Layer>
            )}

            {/* Automate modal */}
            {showAutomateModal && (
                <Layer
                    onEsc={() => setShowAutomateModal(false)}
                    onClickOutside={() => setShowAutomateModal(false)}
                    background={{ color: 'unset' }}
                >
                    <MagicComposerAutomate
                        title={templateName}
                        label={props.edit ? 'Update automation' : 'Create automation'}
                        automationState={automationState}
                        slackNotifications={slackNotifications}
                        onUpdateAutomation={(automation) => setAutomationState(automation)}
                        onUpdateSlackNotifications={(slackNotifications) => setSlackNotifications(slackNotifications)}
                        onCloseModal={() => setShowAutomateModal(false)}
                        onAutomate={onSaveAutomation}
                        loadingSummary={loadingPreview}
                        loadingTitle={loadingTitle}
                    />
                </Layer>
            )}

            {/* Query modal */}
            {showQueryModal && (
                <Layer
                    onEsc={() => setShowAutomateModal(false)}
                    onClickOutside={() => setShowAutomateModal(false)}
                    background={{ color: 'unset' }}
                >
                    <MagicComposerQuery
                        title={templateName}
                        label={props.edit ? 'Update saved query' : 'Create saved query'}
                        onCloseModal={() => setShowQueryModal(false)}
                        onSavedQueries={onSaveSavedQueries}
                        loadingSummary={loadingPreview}
                        loadingTitle={loadingTitle}
                    />
                </Layer>
            )}
        </>
    );
}

function MagicComposerOptions(props: { pageTitle: string }): JSX.Element {
    return (
        <Box fill pad={{ horizontal: 'xsmall', vertical: '10px' }}>
            <Text
                size="28px"
                style={{ fontFamily: FontFamily.Callout, position: 'absolute' }}
                color={Colors.dark_2}
                weight="bold"
            >
                {props.pageTitle}
            </Text>
            <Box gap="xsmall" direction="row" justify="center" fill>
                {ComposerOptions.map((option) => (
                    <Box key={option.title} align="center" justify="center">
                        <MagicComposerOption {...option} />
                    </Box>
                ))}
            </Box>
        </Box>
    );
}

function MagicComposerOption(props: { icon: Icon; title: string; label: string; mode: CreationMode }): JSX.Element {
    const navigate = useNavigate();
    return (
        <Button
            plain
            onClick={() =>
                navigate(`${joinPagesPaths([Pages.app.root, Pages.app.subs.create])}?${QueryParams.Mode}=${props.mode}`)
            }
        >
            {({ hover }) => (
                <Box
                    direction="column"
                    background={Colors.background_back}
                    align="center"
                    width="280px"
                    height="200px"
                    round="10px"
                    justify="center"
                    elevation="small"
                    border={{ size: '1px', color: hover ? Colors.accent_3 : Colors.border_light }}
                    pad="small"
                >
                    <Box direction="row" align="center" gap="xxsmall">
                        {props.icon && <props.icon size="16px" color={Colors.dark_2} />}
                        <Text
                            size="20px"
                            weight="bold"
                            color={Colors.dark_2}
                            style={{ fontFamily: FontFamily.Standard }}
                        >
                            {`${props.title}`}
                        </Text>
                    </Box>
                    <Text
                        size="13px"
                        color={Colors.dark_2}
                        style={{ fontFamily: FontFamily.Standard }}
                        textAlign="center"
                    >
                        {`${props.label}`}
                    </Text>
                </Box>
            )}
        </Button>
    );
}
