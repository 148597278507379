// Copyright 2021
// ThatWorks.xyz Limited

import { Connect, Database, Help, Icon, Logout, SettingsOption } from 'grommet-icons';
import { Inbox } from '../icons/Inbox';
import { MagicWand } from '../icons/MagicWand';
import { Thunder } from '../icons/Thunder';

export enum SidebarNav {
    Connect = 'connect',
    InternalSettings = 'internal_settings',
    Logout = 'logout',
    Data = 'data',
    Automations = 'automations',
    Help = 'help',
    Create = 'create',
    SavedQueries = 'saved_queries',
}

export function getLabel(op: SidebarNav): string {
    switch (op) {
        case SidebarNav.Connect:
            return 'Apps';
        case SidebarNav.InternalSettings:
            return 'Internal Settings';
        case SidebarNav.Logout:
            return 'Logout';
        case SidebarNav.Data:
            return 'Data Sources';
        case SidebarNav.Automations:
            return 'Automations';
        case SidebarNav.Help:
            return 'Help';
        case SidebarNav.Create:
            return 'Create';
        case SidebarNav.SavedQueries:
            return 'Saved Queries';
    }
}

export function getNavIcon(op: SidebarNav): Icon {
    switch (op) {
        case SidebarNav.Connect:
            return Connect;
        case SidebarNav.InternalSettings:
            return SettingsOption;
        case SidebarNav.Logout:
            return Logout;
        case SidebarNav.Data:
            return Database;
        case SidebarNav.Automations:
            return Thunder;
        case SidebarNav.Help:
            return Help;
        case SidebarNav.Create:
            return MagicWand;
        case SidebarNav.SavedQueries:
            return Inbox;
    }
}

export function getNavButtonId(n: SidebarNav) {
    return `nav-${n}`;
}
