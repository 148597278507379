// Copyright 2021
// ThatWorks.xyz Limited

import { ConnectorName, ConnectPreAuthInput, ConnectRequestBody } from '@thatworks/connector-api';
import { joinPagesPaths, Pages } from '@thatworks/shared-frontend/pages';
import { Capitalize } from '@thatworks/string-utils';
import { ErrorMessage } from '../components/ErrorToast';
import Api from './Api';
import { Logger } from './Telemetry';
import { NavNoRenderFunction } from './UseNavNoRerender';

export interface ConnectConnectorParams {
    connectorName: ConnectorName;
    preAuthState?: ConnectPreAuthInput;
    selectedLinkedApps?: ConnectorName[];
}

export function getAbsoluteUrl(page: string) {
    let root = `${window.location.protocol}//${window.location.host}`;
    if (root.endsWith('/')) {
        root = root.slice(0, -1);
    }
    return `${root}${page}`;
}

export async function connectConnectorGetUrl(
    connectorName: ConnectorName,
    token: string,
    state: { preAuthState?: ConnectPreAuthInput; selectedLinkedApps?: ConnectorName[] },
    returnToUrl: string,
): Promise<string | undefined> {
    let resUrl: string | undefined;

    const params = new URLSearchParams({ returnTo: returnToUrl });
    if (state.selectedLinkedApps && state.selectedLinkedApps.length > 0) {
        params.append('selectedLinkedApps', state.selectedLinkedApps.join(','));
    }
    let body: ConnectRequestBody | undefined;
    if (state.preAuthState) {
        body = { preAuthInput: state.preAuthState };
    }

    if (body) {
        const res = await Api.post<{ location: string } | undefined, ConnectRequestBody>(
            [Api.routes.CONNECTORS, `connect`, connectorName],
            token,
            { data: body, params, sendCookies: true },
        );
        if (res && res.location) {
            resUrl = res.location;
        } else {
            resUrl = `${window.location.origin}${joinPagesPaths([
                Pages.connector_oauth.root,
                Pages.connector_oauth.subs.oauthComplete,
            ])}`;
        }
    } else {
        const res = await Api.get<{ location: string }>([Api.routes.CONNECTORS, `connect`, connectorName], token, {
            params,
            sendCookies: true,
        });
        resUrl = res.location;
    }

    return resUrl;
}

export async function connectConnector(
    connectorName: ConnectorName,
    token: string,
    logger: Logger,
    navigate: NavNoRenderFunction,
    state: { preAuthState?: ConnectPreAuthInput; selectedLinkedApps?: ConnectorName[] },
): Promise<{ ok: boolean; err?: ErrorMessage }> {
    try {
        const returnToUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
        const params = new URLSearchParams({ returnTo: returnToUrl });
        if (state.selectedLinkedApps && state.selectedLinkedApps.length > 0) {
            params.append('selectedLinkedApps', state.selectedLinkedApps.join(','));
        }
        let body: ConnectRequestBody | undefined;
        if (state.preAuthState) {
            body = { preAuthInput: state.preAuthState };
        }

        if (body) {
            const res = await Api.post<{ cookie: string; location: string } | undefined, ConnectRequestBody>(
                [Api.routes.CONNECTORS, `connect`, connectorName],
                token,
                { data: body, params },
            );
            if (res && res.location && res.cookie) {
                document.cookie = res.cookie;
                window.location.href = res.location;
            } else {
                window.location.reload();
            }
        } else {
            const res = await Api.get<{ cookie: string; location: string }>(
                [Api.routes.CONNECTORS, `connect`, connectorName],
                token,
                { params },
            );
            document.cookie = res.cookie;
            window.location.href = res.location;
        }

        return { ok: true };
    } catch (error) {
        Api.handleException(error, logger, navigate);
        return {
            ok: false,
            err: {
                title: `${Capitalize(connectorName)}`,
                shortDesc: 'Error reaching the server.',
            },
        };
    }
}
