// Copyright 2021
// ThatWorks.xyz Limited

import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { Environment, getEnvironment } from '../shared/Environment';
import { AppInsightsTelemetry, Logger, StubTelemetry, Telemetry } from '../shared/Telemetry';

export interface TelemetryContext {
    _telemetry: Telemetry;
    logger: Logger;
}

function createTelemtry() {
    if (!process.env.REACT_APP_AZURE_APP_INSIGHTS_INSTRUMENTATION) {
        throw new Error('process.env.REACT_APP_AZURE_APP_INSIGHTS_INSTRUMENTATION not defined');
    }

    return getEnvironment() === Environment.Production
        ? new AppInsightsTelemetry(process.env.REACT_APP_AZURE_APP_INSIGHTS_INSTRUMENTATION)
        : new StubTelemetry();
}

const TelemetryContextValue = createContext<TelemetryContext>({
    _telemetry: new StubTelemetry(),
    logger: new StubTelemetry().logger,
});

export function useTelemetryContext() {
    return useContext(TelemetryContextValue);
}

export function TelemetryContextProvider(props: { children: ReactNode }): JSX.Element {
    const [telemetry] = useState(createTelemtry());

    const contextValue: TelemetryContext = useMemo(() => {
        return {
            _telemetry: telemetry,
            logger: telemetry.logger,
        };
    }, [telemetry]);

    return <TelemetryContextValue.Provider value={contextValue}>{props.children}</TelemetryContextValue.Provider>;
}
