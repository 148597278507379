// Copyright 2021
// ThatWorks.xyz Limited

import { getMainWebsitePageUrl, WebsitePages } from '@thatworks/shared-frontend/pages';
import { Box, Button, ResponsiveContext, Text } from 'grommet';
import { FillType, WidthType } from 'grommet/utils';
import { ReactNode, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import TwIconPattern from './../icons/tw_bg_pattern_blue.svg';
import { ReactComponent as TwIconWhite } from './../icons/tw-white.svg';
import { ReactComponent as TwIcon } from './../icons/tw.svg';

const BackgroundSvgWrapper = styled.div<{ bg: string }>`
    display: inline-block;
    background-image: linear-gradient(to bottom, rgba(249, 251, 255, 0.85), rgba(249, 251, 255, 1)),
        url(${(props) => props.bg});
    background-repeat: repeat;
    height: 100%;
`;

export function GenericBrandFooter(props: { logoIsClickable?: boolean }) {
    const navigate = useNavigate();
    const size = useContext(ResponsiveContext);
    return (
        <Box
            id="footer"
            background="#290C2F"
            fill="horizontal"
            border={{ color: 'light-3', side: 'top' }}
            pad="small"
            justify="between"
            direction="row"
            align="center"
            height={{ min: 'unset' }}
        >
            <Box>
                <Box direction="row" gap="small">
                    <Button plain size="small" href={getMainWebsitePageUrl(WebsitePages.privacy)}>
                        <Text color="neutral-1" size="xsmall" style={{ whiteSpace: 'nowrap' }}>
                            Privacy
                        </Text>
                    </Button>
                    <Button plain size="small" href={getMainWebsitePageUrl(WebsitePages.terms)}>
                        <Text color="neutral-1" size="xsmall" style={{ whiteSpace: 'nowrap' }}>
                            Terms
                        </Text>
                    </Button>
                    <Button plain size="small" href={getMainWebsitePageUrl(WebsitePages.cookiePolicy)}>
                        <Text color="neutral-1" size="xsmall" style={{ whiteSpace: 'nowrap' }}>
                            Cookie Policy
                        </Text>
                    </Button>
                    <Button plain size="small" href={process.env.REACT_APP_SUPPORT_HREF}>
                        <Text color="neutral-1" size="xsmall" style={{ whiteSpace: 'nowrap' }}>
                            Contact
                        </Text>
                    </Button>
                </Box>
            </Box>
            {size !== 'small' && (
                <Box>
                    <TwIconWhite
                        width="10em"
                        fill="#FFFFFF"
                        style={props.logoIsClickable ? { cursor: 'pointer' } : undefined}
                        onClick={props.logoIsClickable ? () => navigate('/') : undefined}
                    />
                </Box>
            )}
        </Box>
    );
}

export function GenericBrandPage(props: {
    children?: ReactNode;
    browserPageTitle: string;
    fill?: FillType;
    innerWidth?: WidthType;
}): JSX.Element {
    const navigate = useNavigate();
    const size = useContext(ResponsiveContext);

    useEffect(() => {
        document.title = props.browserPageTitle;
    }, [props.browserPageTitle]);

    return (
        <Box
            background="background-back"
            fill={props.fill || 'horizontal'}
            id="generic-brand-bg"
            height="inherit"
            justify="between"
        >
            <BackgroundSvgWrapper
                bg={TwIconPattern}
                style={{ alignContent: 'center', justifyContent: 'center', height: 'auto' }}
            >
                <Box pad="small">
                    <TwIcon width="12em" style={{ cursor: 'pointer' }} onClick={() => navigate('/')} />
                </Box>
                <Box
                    pad={{
                        horizontal: size === 'small' ? 'small' : 'large',
                        vertical: size === 'small' ? 'small' : 'medium',
                    }}
                    align="center"
                    height="flex"
                >
                    <Box gap="xsmall" width={props.innerWidth || { width: '100%', max: '1000px' }}>
                        {props.children}
                    </Box>
                </Box>
            </BackgroundSvgWrapper>
            <GenericBrandFooter logoIsClickable={true} />
        </Box>
    );
}
