// Copyright 2021
// ThatWorks.xyz Limited

import { useLazyQuery, useMutation } from '@apollo/client';
import { Colors } from '@thatworks/colors';
import { Box, Spinner, Text } from 'grommet';
import { Down, FormTrash, Up } from 'grommet-icons';
import { useCallback, useEffect, useMemo } from 'react';
import { gql } from '../../../../../__generated__';
import {
    ActivityItemFilterOperator,
    ConnectorScopeInput,
    GetTimelineMetricChartsQuery,
    GroupSettingsInput,
    SummarizationStyle,
    TimelineActivityQuery,
    TimelineIndicatorsQuery,
} from '../../../../../__generated__/graphql';
import { IconButtonV2 } from '../../../../../components/IconButton';
import { HoverPlainTextInput } from '../../../../../components/PlainTextInput';
import { useTelemetryContext } from '../../../../../components/TelemetryContext';
import { useUserStateContext } from '../../../../../components/UserContext';
import { FontFamily } from '../../../../../theme';
import { getActivityFilters } from '../filters/activity-property-helpers';
import { GET_TIMELINE_CHARTS, GET_TIMELINE_INDICATORS } from '../filters/ActivityLists';
import { ActivityPresetsButton } from '../filters/ActivityPresetsButton';
import { PropertyFilterGroup } from '../filters/filter-toolbar-button/helpers';
import { DEFAULT_GROUP_SETTINGS } from '../filters/GroupToolbarButton';
import { SearchItemsToolbarButton } from '../filters/SearchItemsToolbarButton';
import {
    DatePreset,
    TimelineDateSelection,
    timelineDateSelectionToTimelineCreateDate,
} from '../filters/timeline-date-selection';
import { TimeSelectionToolbarButton } from '../filters/TimeSelectionToolbarButton';
import { getActivityQueryVars, getFiltersForPreset, getPresetTitle, PresetFilter } from '../helpers/preset-filters';

const GET_ACTIVITY = gql(/* GraphQL */ `
    query TimelineActivity(
        $timelineId: ID!
        $sort: ItemSort
        $limit: Int
        $grouping: GroupSettingsInput
        $filters: ActivityItemFiltersInput
        $summarize: TimelineActivitySummarizationInput
    ) {
        timelineActivity(
            timelineId: $timelineId
            sort: $sort
            limit: $limit
            grouping: $grouping
            filters: $filters
            summarize: $summarize
        ) {
            title
            groups {
                type
                subgroups {
                    ids
                    name
                    props {
                        ... on ItemSubgroupTaskStatus {
                            isDoneStatus
                            sortOrder
                            status
                        }
                    }
                    summary {
                        summary {
                            sections {
                                markdown
                                pills {
                                    value
                                    color
                                    iconUrl
                                    itemUuids
                                    connector
                                }
                                newRowForPills
                            }
                        }
                    }
                }
            }
            items {
                title
                id
                iconUrl
                connector
                url
                actors {
                    names {
                        color
                        isUser
                        name
                    }
                    hasMore
                }
                parents {
                    connectorObjectType
                    name
                    url
                }
                properties {
                    color
                    name
                    value
                    iconUrl
                    valueType
                    propertyType
                }
                changeDescription {
                    color
                    decoration
                    value
                }
                timeRange {
                    newest
                    oldest
                }
                docDiff {
                    ... on DocDiffSummarized {
                        summary
                    }
                }
                comments {
                    comments {
                        authorDisplayName
                        comment
                        date
                        userMention
                    }
                    more
                }
            }
        }
    }
`);

const CREATE_TIMELINE_FROM_SCOPES = gql(/* GraphQL */ `
    mutation GetTimeFromScopes(
        $scopes: [ConnectorScopeInput!]!
        $fromDate: TimelineCreateDate!
        $cacheSessionKey: String!
    ) {
        timelineCreateFromScopes(scopes: $scopes, fromDate: $fromDate, cacheSessionKey: $cacheSessionKey)
    }
`);

export type TemplateBlockSummaryData =
    | { type: 'activity'; activity: TimelineActivityQuery['timelineActivity'] }
    | { type: 'indicators'; indicators: TimelineIndicatorsQuery['timelineIndicators'] }
    | { type: 'charts'; charts: GetTimelineMetricChartsQuery['timelineMetricCharts'] };

export interface TemplateBlockState {
    id: string;
    preset: PresetFilter | undefined;
    propertyFilterGroups: PropertyFilterGroup[];
    filtersOperator: ActivityItemFilterOperator;
    groupSettings: GroupSettingsInput;
    title: string;
    selectedScopes: ConnectorScopeInput[];
    dateSelection: TimelineDateSelection;
    summary: TemplateBlockSummaryData | undefined;
    timelineId: string | undefined;
    summarizationStyle: SummarizationStyle;
    selectedInsightIds: string[];
}

// '*' is a special value so the ui starts with selecting all indicators by default
export const SHOW_ALL_INSIGHTS_SPECIAL_CASE_ID = '*';

export function getDefaultTemplateBlockProps(props?: Partial<TemplateBlockState>): TemplateBlockState {
    const res: TemplateBlockState = {
        groupSettings: DEFAULT_GROUP_SETTINGS,
        dateSelection: { preset: DatePreset.OneWeek, customDaysStr: '' },
        selectedScopes: [],
        ...props,
        preset: undefined,
        propertyFilterGroups: [],
        filtersOperator: ActivityItemFilterOperator.Or,
        id: Math.random().toString(36).substring(7),
        title: '',
        summary: undefined,
        timelineId: undefined,
        summarizationStyle: SummarizationStyle.Highlights,
        selectedInsightIds: [SHOW_ALL_INSIGHTS_SPECIAL_CASE_ID],
    };
    return res;
}

export function TemplateBlock(props: {
    sessionCacheId: string;
    state: TemplateBlockState;
    onUpdateState: (state: TemplateBlockState) => void;
    onDelete: () => void;
    setActiveBlock: (active: boolean) => void;
    onLoadingSummary: (loading: boolean) => void;
    onLoadingTimeline: (loading: boolean) => void;
    active: boolean;
}): JSX.Element {
    const { logger } = useTelemetryContext();
    const { postErrorMessage } = useUserStateContext();

    const [getSummarizedActivity, { loading: activityLoading }] = useLazyQuery(GET_ACTIVITY, {
        onError: (error) => {
            postErrorMessage({ title: 'Error', shortDesc: `Failed to summarize` });
            logger.error(error.message);
        },
    });
    const [getIndicators, { loading: indicatorLoading }] = useLazyQuery(GET_TIMELINE_INDICATORS, {
        onError: (error) => {
            postErrorMessage({ title: 'Error', shortDesc: `Failed to get indicators` });
            logger.error(error.message);
        },
    });
    const [getCharts, { loading: chartsLoading }] = useLazyQuery(GET_TIMELINE_CHARTS, {
        onError: (error) => {
            postErrorMessage({ title: 'Error', shortDesc: `Failed to get charts` });
            logger.error(error.message);
        },
    });
    const [getConnectorsScopesTimeline, { loading: createTimelineLoading }] = useMutation(CREATE_TIMELINE_FROM_SCOPES, {
        onError: (error) => {
            props.onLoadingTimeline(false);
            postErrorMessage({ title: 'Error', shortDesc: `Failed to fetch timeline` });
            logger.error(error.message);
        },
    });

    const dataLoading = useMemo(() => {
        return activityLoading || indicatorLoading || chartsLoading;
    }, [activityLoading, indicatorLoading, chartsLoading]);

    const generateSummary = useCallback(
        async (timelineId: string, state: TemplateBlockState) => {
            let summary: TemplateBlockSummaryData | undefined = undefined;
            let indicatorIds: string[] = [SHOW_ALL_INSIGHTS_SPECIAL_CASE_ID];
            if (timelineId) {
                // Indicators
                if (state.preset === PresetFilter.Indicators) {
                    // Get indicators
                    const d = await getIndicators({ variables: { timelineId: timelineId } });

                    // If we've received indicators data, update the summary
                    if (d.data) {
                        summary = { type: 'indicators', indicators: d.data.timelineIndicators };
                        d.data.timelineIndicators.insights.forEach((insight) => indicatorIds.push(insight.identifier));
                        d.data.timelineIndicators.groupedInsights.forEach((groupedInsight) =>
                            indicatorIds.push(groupedInsight.identifier),
                        );
                        d.data.timelineIndicators.metricBoxes.forEach((metricBox) =>
                            indicatorIds.push(metricBox.identifier),
                        );
                    }
                }
                // Charts
                else if (state.preset === PresetFilter.Charts) {
                    // Get charts
                    const d = await getCharts({ variables: { timelineId: timelineId } });

                    // If we've received charts data, update the summary
                    if (d.data) {
                        summary = { type: 'charts', charts: d.data.timelineMetricCharts };
                    }
                }
                // Other presets
                else if (state.propertyFilterGroups.length > 0 || state.preset) {
                    // Get activity filters
                    const f = getActivityQueryVars(
                        state.groupSettings,
                        getActivityFilters(state.propertyFilterGroups, state.filtersOperator, state.dateSelection),
                    );

                    // Get summarized acitivity
                    const d = await getSummarizedActivity({
                        variables: {
                            timelineId: timelineId,
                            filters: f.filters,
                            grouping: f.grouping || DEFAULT_GROUP_SETTINGS,
                            sort: f.sort,
                            summarize: {
                                summarize: true,
                                style: state.summarizationStyle,
                                //itemIds: [...selectedItems],
                            },
                        },
                    });

                    // If we've received summarized activity data, update the summary
                    if (d.data) {
                        summary = { type: 'activity', activity: d.data.timelineActivity };
                    }
                }
            } else if (state.title) {
                summary = { type: 'activity', activity: { groups: [], items: [] } };
            }

            // Return summary
            return { summary, indicatorIds };
        },
        [getCharts, getIndicators, getSummarizedActivity],
    );

    const onUpdateState = props.onUpdateState;
    const onLoadingSummary = props.onLoadingSummary;
    const generateSummaryAndNotify = useCallback(
        async (state: TemplateBlockState) => {
            if (!state.timelineId) {
                onUpdateState({ ...state, summary: undefined });
                return;
            }

            onLoadingSummary(true);
            try {
                const summary = await generateSummary(state.timelineId, state);
                if (summary) {
                    onUpdateState({ ...state, summary: summary.summary, selectedInsightIds: summary.indicatorIds });
                }
            } finally {
                onLoadingSummary(false);
            }
        },
        [generateSummary, onLoadingSummary, onUpdateState],
    );

    const updateStateAndNotify = useCallback(
        (state: TemplateBlockState) => {
            onUpdateState(state);
            if (state.timelineId) {
                generateSummaryAndNotify(state);
            }
        },
        [generateSummaryAndNotify, onUpdateState],
    );

    const updatePreset = useCallback(
        (p: PresetFilter) => {
            // Get the filters based on the selected preset and update the propertyFilterGroups with it
            const propertyFilterGroups = getFiltersForPreset(p);

            // Update the state and notify
            updateStateAndNotify({
                ...props.state,
                preset: p,
                title: props.state.title || getPresetTitle(p),
                propertyFilterGroups: propertyFilterGroups,
                filtersOperator: ActivityItemFilterOperator.Or,
            });
        },
        [props.state, updateStateAndNotify],
    );

    const fetchData = useCallback(() => {
        if (props.state.selectedScopes.length === 0) {
            return;
        }

        // No additional checks required because graphql will return cached data
        // if the array values are the same
        const items = [...props.state.selectedScopes];
        // stable sort-ish to aid with returning cached values
        items.sort((a, b) => a.id.localeCompare(b.id));
        props.onLoadingTimeline(true);
        getConnectorsScopesTimeline({
            variables: {
                scopes: items.map((item) => ({
                    connector: item.connector,
                    hierarchyType: item.hierarchyType,
                    id: item.id,
                    itemName: item.itemName,
                    itemUuid: item.itemUuid,
                })),
                fromDate: timelineDateSelectionToTimelineCreateDate(props.state.dateSelection),
                cacheSessionKey: props.sessionCacheId,
            },
            onCompleted: (data) => {
                const newState = { ...props.state, timelineId: data.timelineCreateFromScopes };
                props.onUpdateState(newState);
                generateSummaryAndNotify(newState);
                props.onLoadingTimeline(false);
            },
        });
    }, [generateSummaryAndNotify, getConnectorsScopesTimeline, props]);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        props.state.selectedScopes,
        props.state.dateSelection,
        props.state.groupSettings.alsoGroupByItemType,
        props.state.groupSettings.groupType,
        props.state.summarizationStyle,
    ]);

    // Return opened block
    if (props.active) {
        return (
            <Box
                background={{ color: Colors.background_front }}
                round={{ size: '15px' }}
                border={{ color: Colors.accent_3, size: '1px' }}
                gap="xsmall"
                pad="small"
            >
                <Box direction="row" gap="xsmall">
                    <Box
                        border={{ color: Colors.border_dark, size: '1px' }}
                        background={Colors.background_back}
                        round="5px"
                        height="31px"
                        flex
                    >
                        <HoverPlainTextInput
                            hoverBgColor={Colors.background_back}
                            inputProps={{ fontSize: '20px' }}
                            inputAttr={{
                                placeholder: 'Untitled Section',
                                onChange: (e) => {
                                    props.onUpdateState({ ...props.state, title: e.target.value });
                                },
                                maxLength: 100,
                            }}
                            inputStyle={{
                                fontFamily: FontFamily.Callout,
                                fontSize: '16px',
                                background: 'unset',
                                padding: '2px 4px',
                                overflow: 'hidden',
                                resize: 'none',
                                width: '100%',
                            }}
                            disableEdit={createTimelineLoading}
                            value={props.state.title}
                        />
                    </Box>
                    <IconButtonV2
                        icon={(hover) => (
                            <FormTrash
                                size="22px"
                                color={hover ? Colors.brand : undefined}
                                onClick={() => props.onDelete()}
                            />
                        )}
                    />
                    <IconButtonV2
                        icon={(hover) => (
                            <Up
                                size="22px"
                                color={hover ? Colors.brand : undefined}
                                onClick={() => props.setActiveBlock(false)}
                            />
                        )}
                    />
                </Box>
                <Box gap="xxsmall">
                    <Box direction="row" justify="between">
                        <Text
                            size="14px"
                            weight="bold"
                            color={Colors.dark_6}
                            style={{
                                fontFamily: FontFamily.Callout,
                                textTransform: 'uppercase',
                                letterSpacing: '2px',
                            }}
                        >
                            Input
                        </Text>
                        {createTimelineLoading && <Spinner size="xsmall" />}
                    </Box>
                    <TimeSelectionToolbarButton
                        onDateSelection={(d) => {
                            props.onUpdateState({ ...props.state, dateSelection: d });
                        }}
                        selection={props.state.dateSelection}
                        disabled={createTimelineLoading}
                    />
                    <SearchItemsToolbarButton
                        scopes={props.state.selectedScopes}
                        onScopesChange={(s) => props.onUpdateState({ ...props.state, selectedScopes: s })}
                    />
                    <ActivityPresetsButton
                        preset={props.state.preset}
                        timelineId={props.state.timelineId}
                        dataLoading={createTimelineLoading}
                        onPresetSelection={updatePreset}
                        onChangePropertyFilters={(f) =>
                            updateStateAndNotify({ ...props.state, propertyFilterGroups: f })
                        }
                        onChangeFilterOperator={(o) => updateStateAndNotify({ ...props.state, filtersOperator: o })}
                        propertyFilterGroups={props.state.propertyFilterGroups}
                        filtersOperator={props.state.filtersOperator}
                        selectedConnectorScopes={props.state.selectedScopes}
                    />
                </Box>
            </Box>
        );
    }

    // Return closed block
    return (
        <Box
            background={{ color: Colors.background_front }}
            round={{ size: '15px' }}
            pad="small"
            direction="row"
            gap="xsmall"
        >
            <Box
                border={{ color: Colors.border_dark, size: '1px' }}
                background={Colors.background_back}
                round="5px"
                flex
            >
                <HoverPlainTextInput
                    hoverBgColor={Colors.background_back}
                    inputProps={{ fontSize: '20px' }}
                    inputAttr={{
                        placeholder: 'Untitled Section',
                        onChange: (e) => {
                            props.onUpdateState({ ...props.state, title: e.target.value });
                        },
                        maxLength: 100,
                    }}
                    inputStyle={{
                        fontFamily: FontFamily.Callout,
                        fontSize: '16px',
                        background: 'unset',
                        padding: '2px 4px',
                        overflow: 'hidden',
                        resize: 'none',
                        width: '100%',
                    }}
                    disableEdit={dataLoading}
                    value={props.state.title}
                />
            </Box>
            <IconButtonV2
                icon={(hover) => (
                    <FormTrash size="22px" color={hover ? Colors.brand : undefined} onClick={() => props.onDelete()} />
                )}
            />
            <IconButtonV2
                icon={(hover) => (
                    <Down
                        size="22px"
                        color={hover ? Colors.brand : undefined}
                        onClick={() => props.setActiveBlock(true)}
                    />
                )}
            />
        </Box>
    );
}
