// Copyright 2021
// ThatWorks.xyz Limited

import { ChartNode, ChartNodeAttributes } from '@thatworks/shared-frontend/prosemirror-nodes';
import { Box } from 'grommet';
import { FunctionComponent, useEffect, useState } from 'react';
import { MetricChartData } from '../../../../../../__generated__/graphql';
import { MetricChartDataQl } from '../../../../../../components/MetricChartDataQl';
import {
    BaseReactNodeComponentProps,
    BaseReactNodeInjected,
} from '../../../../../../components/prosemirror/BaseReactNode';

export class ChartNodeReact extends BaseReactNodeInjected<ChartNodeAttributes> {
    _injectedNode = new ChartNode();

    ComponentToRender: FunctionComponent<BaseReactNodeComponentProps<ChartNodeAttributes>> = (props) => {
        const [data, setData] = useState<MetricChartData | undefined>(undefined);

        useEffect(() => {
            if (!props.currentAttributes.data) {
                return;
            }

            const parsed = JSON.parse(props.currentAttributes.data) as MetricChartData | undefined;
            setData(parsed);
        }, [props.currentAttributes.data]);

        return (
            <Box direction="row" gap="xxsmall" width="max-content">
                {data && <MetricChartDataQl chart={data} />}
            </Box>
        );
    };
}
