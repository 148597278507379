// Copyright 2021
// ThatWorks.xyz Limited

import { joinPagesPaths, Pages, QueryParams } from '@thatworks/shared-frontend/pages';
import { Box, DropButton } from 'grommet';
import { Article, Down, Edit, Trash } from 'grommet-icons';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetWorkspacePostTemplatesQuery, WorkspacePostUserAbilities } from '../../../../../__generated__/graphql';
import DropdownButton from '../../../../../components/DropdownButton';
import { Thunder } from '../../../../../icons/Thunder';
import { CreationMode } from '../../magic-composer/components/MagicComposer';

export function SavedQueriesOptions(props: {
    template: GetWorkspacePostTemplatesQuery['workspacePostTemplates'][0];
    onDeleteSavedQueries: () => void;
    onUpdateSavedQueries: () => void;
}): JSX.Element {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const goToMagicComposer = useCallback(
        async (mode: CreationMode) => {
            let path = `${joinPagesPaths([Pages.app.root, Pages.app.subs.create])}?${QueryParams.Mode}=${mode}`;
            navigate(`${path}&${QueryParams.SavedQueries}=${props.template.id}`);
        },
        [navigate, props.template.id],
    );

    return (
        <DropButton
            icon={
                <Down
                    size="small"
                    onClick={() => {
                        setOpen(true);
                    }}
                />
            }
            style={{ padding: '0px' }}
            dropContent={
                <Box background="transparent" direction="row">
                    <Box elevation="medium" background="brand" round="5px" pad={{ top: '10px', bottom: '10px' }}>
                        {/* Create automation button */}
                        <DropdownButton
                            label={'Create new automation'}
                            icon={Thunder}
                            onClick={async () => {
                                setOpen(false);
                                goToMagicComposer(CreationMode.Automation);
                            }}
                        />
                        {/* Create post button */}
                        <DropdownButton
                            label={'Create new post'}
                            icon={Article}
                            onClick={async () => {
                                setOpen(false);
                                goToMagicComposer(CreationMode.Post);
                            }}
                        />
                        {/* Edit saved queries button */}
                        {props.template.userAbilities.includes(WorkspacePostUserAbilities.CanEdit) && (
                            <DropdownButton
                                label={'Edit'}
                                icon={Edit}
                                onClick={async () => {
                                    props.onUpdateSavedQueries();
                                    setOpen(false);
                                }}
                            />
                        )}
                        {/* Delete saved queries button */}
                        {props.template.userAbilities.includes(WorkspacePostUserAbilities.CanDelete) && (
                            <DropdownButton
                                label={'Delete'}
                                icon={Trash}
                                onClick={async () => {
                                    props.onDeleteSavedQueries();
                                    setOpen(false);
                                }}
                            />
                        )}
                    </Box>
                </Box>
            }
            dropAlign={{ left: 'right', top: 'bottom' }}
            dropProps={{
                background: 'transparent',
                elevation: 'none',
                onEsc: () => {
                    setOpen(false);
                },
                onClickOutside: () => {
                    setOpen(false);
                },
            }}
            open={open}
        />
    );
}
