// Copyright 2021
// ThatWorks.xyz Limited

import { Blank, IconProps } from 'grommet-icons';

export const Folder = (props: IconProps & JSX.IntrinsicElements['svg']) => (
    <Blank {...props}>
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.9688 10.8725C22.8295 10.6795 22.6464 10.5224 22.4345 10.4142C22.2226 10.3059 21.988 10.2497 21.75 10.25H20.25V8.75C20.25 8.35218 20.092 7.97064 19.8107 7.68934C19.5294 7.40804 19.1478 7.25 18.75 7.25H12.2503L9.65063 5.3C9.39054 5.10611 9.07503 5.00094 8.75062 5H3.75C3.35218 5 2.97064 5.15804 2.68934 5.43934C2.40804 5.72064 2.25 6.10218 2.25 6.5V20C2.25 20.1989 2.32902 20.3897 2.46967 20.5303C2.61032 20.671 2.80109 20.75 3 20.75H19.7906C19.948 20.75 20.1015 20.7005 20.2292 20.6085C20.3569 20.5164 20.4524 20.3865 20.5022 20.2372L23.1731 12.2244C23.2482 11.9989 23.2689 11.7589 23.2334 11.5239C23.1978 11.2889 23.1072 11.0657 22.9688 10.8725ZM8.75062 6.5L11.3503 8.45C11.6103 8.64401 11.9259 8.7492 12.2503 8.75H18.75V10.25H6.54094C6.2261 10.25 5.91924 10.349 5.66382 10.5331C5.4084 10.7172 5.21738 10.9769 5.11781 11.2756L3.75 15.3781V6.5H8.75062ZM19.2506 19.25H4.04062L6.54094 11.75H21.75L19.2506 19.25Z"
                stroke="none"
            />
        </svg>
    </Blank>
);
