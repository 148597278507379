// Copyright 2021
// ThatWorks.xyz Limited

import { Attrs, Fragment, Node, DOMParser as PmDomParser, Schema } from '@remirror/pm/model';
import { ConnectorName } from '@thatworks/connector-api';
import { HeadingExtensionAttributes } from 'remirror/extensions';
import showdown from 'showdown';
import { v4 } from 'uuid';
import { MetricBoxAttributes } from './prosemirror-nodes';

// What's happening here?
//
// This file is a shared file between the frontend and the backend.
// It contains shared types and interfaces for the ProseMirror nodes.
// The frontend uses these types to render ProseMirror nodes in the editor.
// The backend uses these types to parse ProseMirror nodes for posting templates.
//
// The interfaces are templatized so that the generated types from GraphQL can
// be injected separately from the frontend and the backend because they each
// have different imports for the same GraphQL types, while still using the
// same logic and type checking.
//
// There is still some overlap in code/logic but that is minimized to the parse()
// and fromActivityItemToPreview() methods.

export enum PmNodeNames {
    TaskPreview = 'task-preview',
    InsightPill = 'insight-pill',
    GroupedInsightPill = 'grouped-insight-pill',
    InlineInsightPill = 'inline-insight-pill',
    Chart = 'chart',
    MetricBox = 'metric',
}

export interface TaskPreviewBase<PropertValueType, PropertyType, CommentsType, ChangeDescriptionType> {
    title: string;
    id: string;
    connector: ConnectorName;
    url?: string;
    iconUrl?: string;
    parents: {
        name: string;
        connectorObjectType: string;
        url?: string;
    }[];
    properties: {
        name: string;
        value: string;
        color?: string;
        iconUrl?: string;
        valueType: PropertValueType; // ActivityItemPropertyPropertValueType;
        propertyType: PropertyType; // ActivityItemPropertyType;
    }[];
    changeDescription: ChangeDescriptionType[][];
    docDiff?: {
        summary: string;
    };
    description?: string;
    comments?: CommentsType; // ActivityItemComments;
}

export interface InlineInsightPillNodeDataBase<TaskPreviewItemType> {
    value: string;
    color?: string;
    iconUrl?: string;
    connector?: ConnectorName;
    items: TaskPreviewItemType[];
}

// -----

export type InsightNodeDataBase<
    InsightPillType,
    TaskPreviewInfoType extends TaskPreviewBase<unknown, unknown, unknown, unknown>,
> = {
    items: Array<TaskPreviewInfoType>;
} & Omit<InsightPillType, 'itemUuids'>;

export interface GroupedInsightNodeDataBase<
    InsightPillType,
    TaskPreviewInfoType extends TaskPreviewBase<unknown, unknown, unknown, unknown>,
> {
    title: string;
    description: string | undefined;
    identifier: string;
    insights: InsightNodeDataBase<InsightPillType, TaskPreviewInfoType>[];
}

export interface SummarizedActivityTextContent {
    text: string;
    mark?: 'bold';
    type: 'text';
}

export interface SummarizedActivityInlineInsightPill<
    TaskPreviewInfoType extends TaskPreviewBase<unknown, unknown, unknown, unknown>,
> {
    type: 'inline-insight-pill';
    data: InlineInsightPillNodeDataBase<TaskPreviewInfoType>;
}

export interface SummarizedActivityParagraphNode<
    TaskPreviewInfoType extends TaskPreviewBase<unknown, unknown, unknown, unknown>,
> {
    type: 'paragraph';
    attrs: unknown;
    content: Array<SummarizedActivityTextContent | SummarizedActivityInlineInsightPill<TaskPreviewInfoType>>;
}

export interface SummarizedActivityTableNode<
    TaskPreviewInfoType extends TaskPreviewBase<unknown, unknown, unknown, unknown>,
    InsightNodeDataType,
> {
    type: 'table';
    rows: Array<{
        cells: {
            content: SummarizedActivityNodeBase<TaskPreviewInfoType, InsightNodeDataType>[];
            attrs?: { colwidth?: number[] };
        }[];
    }>;
}

export type SummarizedActivityNodeBase<
    TaskPreviewInfoType extends TaskPreviewBase<unknown, unknown, unknown, unknown>,
    InsightNodeDataType,
> =
    | {
          type: 'heading';
          attrs: HeadingExtensionAttributes;
          content: SummarizedActivityTextContent[];
      }
    | SummarizedActivityParagraphNode<TaskPreviewInfoType>
    | { type: 'tasks'; items: Array<TaskPreviewInfoType> }
    | {
          type: 'bulletList';
          attrs: unknown;
          content: SummarizedActivityTextContent[][];
      }
    | {
          type: 'insight-pill';
          insights: Array<InsightNodeDataType>;
      }
    | {
          type: 'group-insight-pill';
          group: GroupedInsightNodeDataBase<InsightNodeDataType, TaskPreviewInfoType>;
      }
    | { type: 'metric-chart'; dataJsonString: string }
    | { type: 'indicators'; data: MetricBoxAttributes['metrics'] }
    | { type: 'text'; text: string; mark?: 'bold' }
    | { type: 'markdown'; text: string }
    | SummarizedActivityTableNode<TaskPreviewInfoType, InsightNodeDataType>;

//----

export abstract class SummarizedActivityNodeParser<
    TaskPreviewInfoType extends TaskPreviewBase<unknown, unknown, unknown, unknown>,
    TimelineActivityQueryData,
    TimelineActivityQueryItem,
    MetricChartData,
    IndicatorData,
    R extends SummarizedActivityNodeBase<TaskPreviewInfoType, unknown>,
> {
    abstract fromActivityItemToPreview(item: TimelineActivityQueryItem): TaskPreviewInfoType;

    abstract parseActivity(title: string | undefined, data: TimelineActivityQueryData): Array<R>;
    abstract parseMetricChartData(title: string | undefined, data: MetricChartData): Array<R>;
    abstract parseIndicatorData(title: string | undefined, data: IndicatorData): Array<R>;
    abstract parseStringToHtml(text: string): globalThis.Node;

    toProseMirrorNodes(
        title: string | undefined,
        data:
            | { type: 'activity'; activity: TimelineActivityQueryData }
            | { type: 'charts'; chart: MetricChartData }
            | { type: 'indicator'; indicator: IndicatorData },
        schema: Schema,
        onError: (nodeName: string, message: string, error: unknown) => void,
    ): Fragment {
        let summaryNodes: R[] = [];
        if (data.type === 'activity') {
            summaryNodes = this.parseActivity(title, data.activity);
        } else if (data.type === 'charts') {
            summaryNodes = this.parseMetricChartData(title, data.chart);
        } else if (data.type === 'indicator') {
            summaryNodes = this.parseIndicatorData(title, data.indicator);
        }
        return this.fromSummaryToProseMirroNodes(summaryNodes, schema, onError);
    }

    private fromSummaryToProseMirroNodes(
        summaryNodes: R[],
        schema: Schema,
        onError: (nodeName: string, message: string, error: unknown) => void,
    ): Fragment {
        let fragment: Fragment = Fragment.empty;
        summaryNodes.forEach((n) => {
            try {
                let newNode: Node | undefined = undefined;
                switch (n.type) {
                    case 'tasks':
                        {
                            newNode = schema.nodes[PmNodeNames.TaskPreview].createChecked({
                                tasks: n.items,
                                uuid: v4(),
                            });
                        }
                        break;
                    case 'bulletList':
                        newNode = schema.nodes['bulletList'].createChecked(
                            n.attrs as Attrs,
                            n.content.map((c) =>
                                schema.nodes['listItem'].createChecked(
                                    {},
                                    schema.nodes['paragraph'].createChecked(
                                        n.attrs as Attrs,
                                        c.map((cc) =>
                                            schema.text(
                                                cc.text,
                                                cc.mark === 'bold' ? [schema.marks.bold.create()] : [],
                                            ),
                                        ),
                                    ),
                                ),
                            ),
                        );
                        break;
                    case 'insight-pill':
                        newNode = schema.nodes[PmNodeNames.InsightPill].createChecked({
                            data: n.insights,
                        });
                        break;
                    case 'group-insight-pill':
                        newNode = schema.nodes[PmNodeNames.GroupedInsightPill].createChecked({
                            data: n.group,
                        });
                        break;
                    case 'metric-chart':
                        newNode = schema.nodes[PmNodeNames.Chart].createChecked({
                            data: n.dataJsonString,
                        });
                        break;
                    case 'indicators':
                        const atts: MetricBoxAttributes = {
                            uuid: v4(),
                            metrics: n.data,
                            data: '',
                        };
                        newNode = schema.nodes[PmNodeNames.MetricBox].createChecked(atts);
                        break;
                    case 'text':
                        newNode = schema.text(n.text, n.mark === 'bold' ? [schema.marks.bold.create()] : []);
                        break;
                    case 'table':
                        {
                            const rows = n.rows.map((row) => {
                                const cells = row.cells.map((c) => {
                                    const cellContent = this.fromSummaryToProseMirroNodes(
                                        c.content as R[],
                                        schema,
                                        onError,
                                    );
                                    return schema.nodes['tableCell'].createChecked(c.attrs, cellContent);
                                });
                                return schema.nodes['tableRow'].createChecked({}, cells);
                            });
                            newNode = schema.nodes['table'].createChecked({}, rows);
                        }
                        break;
                    case 'markdown':
                        {
                            // markdown -> html -> prosemirror nodes
                            const html = new showdown.Converter().makeHtml(n.text);
                            const parsedHtml = this.parseStringToHtml(html);
                            const pmDomParser = PmDomParser.fromSchema(schema);
                            fragment = pmDomParser.parse(parsedHtml).content;
                        }
                        break;
                    default:
                        const content = n.content.map((c) => {
                            try {
                                if (c.type === 'text') {
                                    return schema.text(c.text, c.mark === 'bold' ? [schema.marks.bold.create()] : []);
                                } else {
                                    return schema.nodes[PmNodeNames.InlineInsightPill].createChecked({
                                        data: c.data,
                                    });
                                }
                            } catch (error) {
                                onError(c.type, `Failed creating node ${c.type} for ${n.type}`, error as Error);
                                return undefined;
                            }
                        });
                        newNode = schema.nodes[n.type].createChecked(
                            n.attrs as Attrs,
                            content.filter((c) => c) as Node[],
                        );
                        break;
                }

                if (newNode) {
                    fragment = fragment.addToEnd(newNode);
                }
            } catch (error) {
                onError(n.type, `Failed to create node ${n.type}`, error);
            }
        });
        return fragment;
    }

    getPreviewItemsFromUuids(uuids: string[], map: Map<string, TimelineActivityQueryItem>): TaskPreviewInfoType[] {
        const res: TaskPreviewInfoType[] = [];
        uuids.forEach((uuid) => {
            const item = map.get(uuid);
            if (item) {
                res.push(this.fromActivityItemToPreview(item));
            }
        });
        return res;
    }
}
