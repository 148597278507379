// Copyright 2021
// ThatWorks.xyz Limited

import { Box, Grid, Notification } from 'grommet';
import React, { useEffect, useMemo, useState } from 'react';
import { BasicErrorBoundary } from './BasicErrorBoundary';
import { SidebarNav } from './SidebarNav';
import { useTelemetryContext } from './TelemetryContext';
import { SidebarContentWidth, SidebarPadding, SidebarState, TwSidebar } from './TwSidebar';
import { useUserStateContext } from './UserContext';

export const DefaultPagePaddingPixels = '40px';

export type BasicPage3NavFunctionProps = { nav: SidebarNav };
export type BasicPage3NavFunction = (to: BasicPage3NavFunctionProps) => void;

export interface BasicPage3Props {
    browserPageTitle: string;
    activeNavButton?: SidebarNav;
    children?: React.ReactNode;
    onNav?: BasicPage3NavFunction;
    onboarding?: boolean;
}

export function BasicPage3(props: BasicPage3Props): JSX.Element {
    const { logger } = useTelemetryContext();
    const { problematicConnectorsError, organizationDisplayName, organizationId } = useUserStateContext();
    const [isHover, setIsHover] = useState<boolean>(false);
    const [sidebarState, setSidebarState] = useState<SidebarState>(
        props.activeNavButton === SidebarNav.Create ? SidebarState.Overlay : SidebarState.Opened,
    );

    useEffect(() => {
        document.title = props.browserPageTitle;
    }, [props.browserPageTitle]);

    // Sidebar column width
    const sidebarColumnWidth = useMemo(() => {
        switch (sidebarState) {
            case SidebarState.Closed:
            case SidebarState.Overlay:
                return SidebarContentWidth.closed + SidebarPadding.closed.horizontal * 2;
            case SidebarState.Opened:
                return SidebarContentWidth.opened + SidebarPadding.opened.horizontal * 2;
        }
    }, [sidebarState]);

    useEffect(() => {
        if (sidebarState === SidebarState.Closed && isHover) {
            setSidebarState(SidebarState.Overlay);
        } else if (sidebarState === SidebarState.Overlay && !isHover) {
            setSidebarState(SidebarState.Closed);
        }
    }, [isHover, sidebarState]);

    return (
        <Grid columns={[`${sidebarColumnWidth}px`, 'auto']} rows={['100vh']} areas={[['sidebar', 'body']]}>
            {/* Sidebar */}
            <TwSidebar
                sidebarState={sidebarState}
                organizationId={organizationId}
                organizationDisplayName={organizationDisplayName}
                onboarding={props.onboarding}
                activeNavButton={props.activeNavButton}
                onNav={props.onNav}
                onHover={setIsHover}
                isHover={isHover}
            />
            {/* Body */}
            <Box gridArea="body" background="background-front" fill height="100vh" overflow={{ vertical: 'auto' }}>
                <Box height={{ min: 'unset', height: '100%' }} id="basic-page-container">
                    <BasicErrorBoundary logger={logger}>
                        {problematicConnectorsError && props.activeNavButton !== SidebarNav.Connect && (
                            <Box height="max-content">
                                <Notification
                                    status="warning"
                                    message={problematicConnectorsError.message}
                                    actions={[
                                        {
                                            label: problematicConnectorsError.ctaLabel,
                                            onClick: async () => {
                                                if (props.onNav) {
                                                    props.onNav({ nav: SidebarNav.Connect });
                                                }
                                            },
                                        },
                                    ]}
                                    global
                                />
                            </Box>
                        )}
                        <Box flex>{props.children}</Box>
                    </BasicErrorBoundary>
                </Box>
            </Box>
        </Grid>
    );
}
