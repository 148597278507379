// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { ConnectorName } from '@thatworks/connector-api';
import { getReadableDateString } from '@thatworks/shared-frontend/date-helpers';
import { InsightNodeDataBase } from '@thatworks/shared-frontend/prosemirror';
import { InsightAttributes, InsightPillNode } from '@thatworks/shared-frontend/prosemirror-nodes';
import { Box, BoxProps, Drop, Image, Text } from 'grommet';
import { FunctionComponent, useMemo, useRef, useState } from 'react';
import { ActivityItemPropertyValueType, InsightPill, InsightPillData } from '../../../../../../__generated__/graphql';
import { ConnectorIconSmall } from '../../../../../../components/ConnectorIcon';
import {
    BaseReactNodeComponentProps,
    BaseReactNodeInjected,
} from '../../../../../../components/prosemirror/BaseReactNode';
import { TaskOverview, TaskPreviewInfo } from './TaskPreviewNode';

export type InsightNodeData = InsightNodeDataBase<InsightPill, TaskPreviewInfo>;

export function InsightLabelDataComponent(props: { value: InsightPillData; items: TaskPreviewInfo[] }): JSX.Element {
    const [modal, setModal] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    const itemsToDisplay = useMemo(() => {
        return props.items.filter((item) => props.value.itemUuids.includes(item.id));
    }, [props.items, props.value.itemUuids]);

    return (
        <Box onMouseEnter={() => setModal(true)} onMouseLeave={() => setModal(false)}>
            <Box
                ref={ref}
                round="10px"
                border={{ color: Colors.light_5 }}
                background={{ color: props.value.color || Colors.accent_4, opacity: 0.1 }}
                pad={{ horizontal: '6px', vertical: '2px' }}
                direction="row"
                gap="5px"
                align="center"
                margin={{ bottom: '2px', top: '2px' }}
            >
                {props.value.iconUrl && <Image src={props.value.iconUrl} width={'12px'} height={'12px'} />}
                <Text size="12px">{props.value.value}</Text>
                {props.value.delta != null && props.value.delta !== 0 && (
                    <Text size="12px">[{props.value.delta > 0 ? `+${props.value.delta}` : props.value.delta}]</Text>
                )}
            </Box>

            {modal && itemsToDisplay.length > 0 && (
                <Drop
                    target={ref}
                    onClickOutside={() => setModal(false)}
                    plain
                    align={{ top: 'bottom', left: 'left' }}
                    pad={{ top: 'xxsmall', horizontal: 'xsmall', bottom: 'xsmall' }}
                    width={{ max: '500px' }}
                    animation={{ type: 'fadeIn', duration: 100 }}
                >
                    <Box
                        overflow={{ vertical: 'auto' }}
                        pad="xxsmall"
                        background={{ color: Colors.background_back }}
                        border={{ color: Colors.border_light }}
                        elevation="medium"
                        round="4px"
                    >
                        <Box height={{ min: 'max-content' }} gap="xxsmall">
                            {itemsToDisplay.map((item, i) => (
                                <TaskOverview
                                    key={`overview-${i}`}
                                    item={item}
                                    cardColor={{ color: Colors.background_back, opacity: 1 }}
                                    borderColor={Colors.border_light}
                                />
                            ))}
                        </Box>
                    </Box>
                </Drop>
            )}
        </Box>
    );
}

export function InsightLabelComponent(props: {
    insight: InsightNodeData;
    boxProps?: BoxProps;
    mode: 'inspector' | 'summary';
    selectedIndicators?: string[];
    onToggle?: () => void;
}): JSX.Element {
    const borderColor = useMemo(() => {
        if (props.mode === 'inspector') {
            if (props.selectedIndicators === undefined || props.selectedIndicators.includes(props.insight.identifier)) {
                return Colors.accent_3;
            }
            return Colors.border_dark;
        }
        return Colors.light_6;
    }, [props.insight.identifier, props.mode, props.selectedIndicators]);

    return (
        <Box
            style={{ cursor: props.mode === 'inspector' ? 'pointer' : 'default' }}
            border={{ color: borderColor }}
            background={{ color: Colors.light_6 }}
            pad={{ horizontal: '10px', vertical: '10px' }}
            round="10px"
            elevation="xsmall"
            onClick={props.onToggle}
            focusIndicator={false}
            {...props.boxProps}
        >
            <Box gap="xsmall" wrap>
                <Box direction="row" align="center" gap="5px">
                    {props.insight.connector && (
                        <ConnectorIconSmall name={props.insight.connector as ConnectorName} sizePixels="14px" />
                    )}
                    {props.insight.iconUrl && <Image src={props.insight.iconUrl} width={'14px'} height={'14px'} />}
                    <Text size="14px">
                        {props.insight.title
                            .map((t) =>
                                t.type === ActivityItemPropertyValueType.DateIso
                                    ? getReadableDateString(new Date(t.value))
                                    : t.value,
                            )
                            .join('')}
                    </Text>
                </Box>
                <Box gap="xxsmall">
                    <Box direction="row" align="center" gap="5px" wrap>
                        {props.insight.data.map((value, vi) => (
                            <InsightLabelDataComponent key={`value-${vi}`} value={value} items={props.insight.items} />
                        ))}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export class InsightPillNodeReact extends BaseReactNodeInjected<InsightAttributes<InsightNodeData>> {
    _injectedNode = new InsightPillNode<InsightNodeData>();

    ComponentToRender: FunctionComponent<BaseReactNodeComponentProps<InsightAttributes<InsightNodeData>>> = (props) => {
        return (
            <Box direction="row" gap="xxsmall" width="max-content" wrap>
                {props.currentAttributes.data &&
                    props.currentAttributes.data.map((d, di) => (
                        <InsightLabelComponent
                            key={`label-${di}`}
                            insight={d}
                            boxProps={{ margin: { bottom: 'xxsmall' } }}
                            mode="summary"
                        />
                    ))}
            </Box>
        );
    };
}
