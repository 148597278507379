// Copyright 2021
// ThatWorks.xyz Limited

import { TableExtension, wysiwygPreset } from 'remirror/extensions';
import styled from 'styled-components';

export const ProseWrapper = styled.div`
    .ProseMirror:focus {
        outline: none;
    }
    .remirror-is-empty:first-of-type::before {
        position: absolute;
        color: #aaa;
        pointer-events: none;
        content: attr(data-placeholder);
    }
    .ProseMirror table {
        border-collapse: collapse;
        width: 100%;
        margin-top: 8px;
        border-bottom: 1px solid #f2eeeb;
    }
    .ProseMirror th,
    .ProseMirror td {
        padding-right: 4px;
        padding-bottom: 0px;
        text-align: left;
        vertical-align: top;
    }
`;

export function getDefaultProseMirrorExtensions() {
    return [...wysiwygPreset(), new TableExtension({})];
}
