// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box, Select } from 'grommet';
import { isCustomProperty } from '../activity-property-helpers';
import { Operator, PropertyFilterSelection } from './helpers';

export function OperatorSelect(props: {
    onChange: (filter: PropertyFilterSelection) => void;
    selection: PropertyFilterSelection;
    operatorOptions: { label: string; value: Operator }[];
}): JSX.Element {
    return (
        <Box
            border={{ color: Colors.accent_3, size: '1px' }}
            round="8px"
            height="32px"
            direction="row"
            align="center"
            justify="center"
            width="180px"
        >
            <Select
                plain
                focusIndicator
                icon={false}
                placeholder="Operator"
                options={props.operatorOptions ?? []}
                value={props.selection.operator}
                valueKey="value"
                labelKey="label"
                size="small"
                onChange={({ option }) => {
                    const newSelection = { ...props.selection };
                    newSelection.operator = option.value;
                    // dont reset value for custom properties since they are limited
                    // to a few operators and aren't tied to specific enums
                    if (!(props.selection.property && isCustomProperty(props.selection.property))) {
                        newSelection.value = '';
                    }
                    props.onChange(newSelection);
                }}
            />
        </Box>
    );
}
