// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box, CheckBox, Drop, Grid, Text } from 'grommet';
import { Close } from 'grommet-icons';
import { useRef, useState } from 'react';
import { SummarizationStyle } from '../../../../../__generated__/graphql';
import { CtaTextButtonSpinner } from '../../../../../components/CtaButtonSpinner';
import { IconButtonV2 } from '../../../../../components/IconButton';
import { ToolbarButton } from '../../../../../components/prosemirror/ToolbarButton';
import { Palette } from '../../../../../icons/Palette';
import { FontFamily } from '../../../../../theme';

const SummarizationStyleArray = Object.values(SummarizationStyle);

function getLabelForSummarizationStyle(style: SummarizationStyle): string {
    switch (style) {
        case SummarizationStyle.Highlights:
            return 'Highlights';
        case SummarizationStyle.ReleaseNotes:
            return 'Release Notes';
    }
}

export function SummaryStyleButton(props: {
    groupByItemType: boolean;
    style: SummarizationStyle;
    onStyleChange: (style: SummarizationStyle) => void;
    onGroupByItemTypeChange: (groupByItemType: boolean) => void;
    dataLoading: boolean;
}): JSX.Element {
    const [buttonActive, setButtonActive] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    return (
        <Box ref={ref}>
            <ToolbarButton
                active={true}
                icon={Palette}
                label="Summary Style"
                onClick={async () => {
                    setButtonActive(!buttonActive);
                }}
                disabled={props.dataLoading}
            />
            {buttonActive && (
                <Drop
                    plain
                    target={ref.current || undefined}
                    align={{ top: 'bottom', left: 'left' }}
                    pad={'xxsmall'}
                    onClickOutside={() => {
                        setButtonActive(false);
                    }}
                >
                    <Box
                        width={{ max: '700px' }}
                        background={{ color: Colors.background_front }}
                        border={{ color: Colors.border_dark, size: '1px' }}
                        pad={'xsmall'}
                        round={'10px'}
                        elevation="xsmall"
                        gap="xsmall"
                    >
                        <Box direction="row" justify="between" fill="horizontal">
                            <Text size="16px" style={{ fontFamily: FontFamily.Heading }} weight="bold">
                                Summary Style
                            </Text>
                            <IconButtonV2
                                icon={(hover) => <Close size="18px" color={hover ? Colors.brand : undefined} />}
                                reverse
                                onClick={() => setButtonActive(false)}
                                alignSelf="end"
                            />
                        </Box>
                        <Grid columns={{ count: 3, size: '170px' }} gap="xsmall">
                            {SummarizationStyleArray.map((style, si) => (
                                <CtaTextButtonSpinner
                                    key={`style-${si}`}
                                    label={getLabelForSummarizationStyle(style)}
                                    getBoxProps={() => {
                                        return {
                                            pad: { horizontal: 'xsmall' },
                                            border: {
                                                color: Colors.accent_3,
                                                size: '1px',
                                            },
                                            height: '25px',
                                            justify: 'center',
                                            round: '8px',
                                            background: {
                                                color:
                                                    props.style === style
                                                        ? Colors.background_back
                                                        : Colors.background_front,
                                            },
                                            elevation: 'xsmall',
                                        };
                                    }}
                                    getTextProps={(hover) => {
                                        return {
                                            color: hover
                                                ? Colors.brand
                                                : props.style === style
                                                ? Colors.black
                                                : Colors.dark_6,
                                            size: '14px',
                                        };
                                    }}
                                    onClick={async () => {
                                        props.onStyleChange(style);
                                    }}
                                />
                            ))}
                        </Grid>
                        <CheckBox
                            label="Group highlights by item type (e.g. bugs, tasks, docs)"
                            checked={props.groupByItemType}
                            color={Colors.accent_3}
                            disabled={props.style !== SummarizationStyle.Highlights}
                            onChange={(event) => {
                                props.onGroupByItemTypeChange(event.target.checked);
                            }}
                        />
                    </Box>
                </Drop>
            )}
        </Box>
    );
}
