// Copyright 2021
// ThatWorks.xyz Limited

import { useEffect } from 'react';
import { WorkspacePost as WorkspacePostQl } from '../../../__generated__/graphql';
import { WorkspacePost } from '../../app/pages/ws/WorkspacePost';

export function PublicWorkspacePost(props: { post: WorkspacePostQl }): JSX.Element {
    useEffect(() => {
        document.title = `${props.post.title} | That Works` || `That Works`;
    }, [props.post.title]);

    return <WorkspacePost onDelete={async () => {}} onEdit={async () => {}} post={props.post} />;
}
