// Copyright 2021
// ThatWorks.xyz Limited

import { Box, Button, DropType, Text } from 'grommet';
import { Icon } from 'grommet-icons';
import { AlignSelfType, BackgroundType, BorderType, PadType, RoundType } from 'grommet/utils';
import React, { useState } from 'react';
import { tooltipProps } from './Tooltip';

export function IconButton(props: {
    icon: Icon;
    label?: string;
    size?: string;
    textSize?: string;
    justify?: 'around' | 'between' | 'center' | 'end' | 'evenly' | 'start' | 'stretch';
    active?: boolean;
    onClick?: (
        e?: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => void;
    color?: { hover: string; color: string };
    tooltip?: string | React.ReactNode;
    disabled?: boolean;
    dsiableHover?: boolean;
    border?: { hover: BorderType; border: BorderType; round: RoundType };
    background?: BackgroundType;
    applyBorderColorWhenActive?: boolean;
}) {
    return (
        <Button
            onClick={(e) => {
                if (props.disabled) {
                    return;
                }

                if (props.onClick) {
                    props.onClick(e);
                }
            }}
            plain
            tip={props.tooltip ? tooltipProps(props.tooltip) : undefined}
            style={{ width: 'fit-content' }}
            disabled={props.disabled}
        >
            {({ hover, disabled }) => (
                <Box
                    direction="row"
                    gap="10px"
                    align="center"
                    justify={props.justify || 'center'}
                    border={
                        props.border
                            ? hover || (props.active && props.applyBorderColorWhenActive)
                                ? props.border.hover
                                : props.border.border
                            : undefined
                    }
                    round={props.border?.round || undefined}
                    background={props.background}
                >
                    <props.icon
                        size={props.size || '20px'}
                        color={
                            props.disabled
                                ? 'light-6'
                                : (!props.dsiableHover && hover) || props.active
                                ? props.color?.hover || 'brand'
                                : props.color?.color || 'dark-4'
                        }
                        style={{ alignSelf: 'center' }}
                    />
                    {props.label && <Text size={props.textSize}>{props.label}</Text>}
                </Box>
            )}
        </Button>
    );
}

export function IconButtonV2(props: {
    icon: (hover: boolean, disabled: boolean, submitting: boolean) => React.ReactNode;
    label?: (hover: boolean, disabled: boolean) => React.ReactNode;
    justify?: 'around' | 'between' | 'center' | 'end' | 'evenly' | 'start' | 'stretch';
    active?: boolean;
    onClick?: (
        e?: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => void;
    onClickWithSpinner?: () => Promise<void>;
    tooltip?: string | React.ReactNode;
    tooltipDropProps?: DropType;
    disabled?: boolean;
    border?: { hover: BorderType; border: BorderType; round: RoundType };
    background?: BackgroundType;
    reverse?: boolean;
    pad?: PadType;
    style?: React.CSSProperties;
    alignSelf?: AlignSelfType;
}) {
    const [submitting, setSubmitting] = useState(false);

    return (
        <Button
            onClick={(e) => {
                if (props.disabled) {
                    return;
                }

                if (props.onClickWithSpinner) {
                    setSubmitting(true);
                    props
                        .onClickWithSpinner()
                        .then(() => setSubmitting(false))
                        .catch(() => setSubmitting(false));
                } else if (props.onClick) {
                    props.onClick(e);
                }
            }}
            plain
            tip={props.tooltip ? tooltipProps(props.tooltip, undefined, undefined, props.tooltipDropProps) : undefined}
            style={{ width: 'fit-content' }}
            disabled={props.disabled}
            alignSelf={props.alignSelf}
        >
            {({ hover, disabled }) => (
                <Box
                    direction="row"
                    gap="10px"
                    align="center"
                    justify={props.justify || 'center'}
                    border={props.border ? (hover ? props.border.hover : props.border.border) : undefined}
                    round={props.border?.round || undefined}
                    background={props.background}
                    pad={props.pad}
                    style={props.style}
                >
                    {props.reverse && props.icon(hover, disabled, submitting)}
                    {props.label && props.label(hover, disabled)}
                    {!props.reverse && props.icon(hover, disabled, submitting)}
                </Box>
            )}
        </Button>
    );
}

export function RoundIconButton(props: {
    icon: Icon;
    label?: string;
    sizePixels?: number;
    active?: boolean;
    onClick?: () => void;
    color?: { hover: string; color: string };
    borderColor?: { hover: string; color: string; active: string };
    padPixels?: number;
    tooltip?: string | React.ReactNode;
}) {
    const sizePixels = props.sizePixels || 18;

    return (
        <Button
            onClick={() => {
                if (props.onClick) {
                    props.onClick();
                }
            }}
            plain
            style={{ width: 'fit-content' }}
            tip={props.tooltip ? tooltipProps(props.tooltip, true) : undefined}
        >
            {({ hover, disabled }) => (
                <Box
                    direction="row"
                    gap="10px"
                    align="center"
                    justify="center"
                    pad={`${props.padPixels || sizePixels * 0.1}px`}
                    background="background-front"
                    style={{ borderRadius: '50%' }}
                    border={
                        props.borderColor
                            ? {
                                  color: props.active
                                      ? props.borderColor.active
                                      : hover
                                      ? props.borderColor.hover
                                      : props.borderColor.color,
                              }
                            : undefined
                    }
                >
                    <props.icon
                        size={`${sizePixels}px`}
                        color={hover || props.active ? props.color?.hover || 'brand' : props.color?.color || 'light-6'}
                        style={{ alignSelf: 'center' }}
                    />
                    {props.label && <Text>{props.label}</Text>}
                </Box>
            )}
        </Button>
    );
}
