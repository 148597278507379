// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { getReadableDateString } from '@thatworks/shared-frontend/date-helpers';
import { GroupedInsightNodeDataBase } from '@thatworks/shared-frontend/prosemirror';
import { GroupedInsightAttributes, GroupedInsightPillNode } from '@thatworks/shared-frontend/prosemirror-nodes';
import { Box, BoxProps, Text } from 'grommet';
import { FunctionComponent, useMemo } from 'react';
import { ActivityItemPropertyValueType, InsightPill } from '../../../../../../__generated__/graphql';
import {
    BaseReactNodeComponentProps,
    BaseReactNodeInjected,
} from '../../../../../../components/prosemirror/BaseReactNode';
import { FontFamily } from '../../../../../../theme';
import { InsightLabelDataComponent } from './InsightNode';
import { TaskPreviewInfo } from './TaskPreviewNode';

export type GroupedInsightNodeData = GroupedInsightNodeDataBase<InsightPill, TaskPreviewInfo>;

export function GroupInsightLabelComponent(props: {
    group: GroupedInsightNodeData;
    boxProps?: BoxProps;
    mode: 'inspector' | 'summary';
    selectedIndicators?: string[];
    onToggle?: () => void;
}): JSX.Element {
    const borderColor = useMemo(() => {
        if (props.mode === 'inspector') {
            if (props.selectedIndicators === undefined || props.selectedIndicators.includes(props.group.identifier)) {
                return Colors.accent_3;
            }
            return Colors.border_dark;
        }
        return Colors.light_6;
    }, [props.group.identifier, props.mode, props.selectedIndicators]);

    return (
        <Box
            style={{ cursor: props.mode === 'inspector' ? 'pointer' : 'default' }}
            border={{ color: borderColor }}
            background={{ color: Colors.light_6 }}
            pad={{ horizontal: '10px', vertical: '10px' }}
            width={{ min: '300px' }}
            round="10px"
            elevation="xsmall"
            onClick={props.onToggle}
            focusIndicator={false}
            {...props.boxProps}
        >
            <Box gap="xsmall" wrap>
                <Box direction="row" align="center" gap="5px">
                    <Text size="14px" weight={'bold'}>
                        {props.group.title}
                    </Text>
                </Box>
                {props.group.description && <Text size="14px">{props.group.description}</Text>}
                {props.group.insights.map((insight, ii) => (
                    <Box key={`insight-group-${insight.title}-${ii}`}>
                        <Box gap="xxsmall">
                            <Text style={{ fontFamily: FontFamily.Heading }} size="14px">
                                {insight.title
                                    .map((t) =>
                                        t.type === ActivityItemPropertyValueType.DateIso
                                            ? getReadableDateString(new Date(t.value))
                                            : t.value,
                                    )
                                    .join('')}
                            </Text>
                            <Box direction="row" align="center" gap="5px" wrap>
                                {insight.data.map((value, vi) => (
                                    <InsightLabelDataComponent
                                        key={`value-${vi}`}
                                        value={value}
                                        items={insight.items}
                                    />
                                ))}
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    );
}

export class GroupedInsightPillNodeReact extends BaseReactNodeInjected<
    GroupedInsightAttributes<GroupedInsightNodeData>
> {
    _injectedNode = new GroupedInsightPillNode<GroupedInsightNodeData>();

    ComponentToRender: FunctionComponent<
        BaseReactNodeComponentProps<GroupedInsightAttributes<GroupedInsightNodeData>>
    > = (props) => {
        return (
            <Box direction="row" gap="xxsmall" width="max-content" wrap>
                {props.currentAttributes.data && (
                    <GroupInsightLabelComponent
                        group={props.currentAttributes.data}
                        boxProps={{ margin: { bottom: 'xxsmall' } }}
                        mode={'summary'}
                    />
                )}
            </Box>
        );
    };
}
