// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box, Text } from 'grommet';
import { useCallback, useMemo } from 'react';
import { FontFamily } from '../../../../../../theme';
import { isCustomProperty } from '../activity-property-helpers';
import { AddFilterButton } from './AddFilterButton';
import { FilterRow } from './FilterRow';
import {
    CUSTOM_PROPERTY_OPERATORS,
    getPropertyLabel,
    Property,
    PropertyFilterGroup,
    PropertyFilterSelection,
    PropertyOption,
    ValueOptions,
} from './helpers';
import { RemoveGroupButton } from './RemoveGroupButton';

export function FilterGroup(props: {
    groupIndex: number;
    onChange: (filter: PropertyFilterGroup) => void;
    onDelete: () => void;
    propertyFilterGroup: PropertyFilterGroup;
    propertiesOptions: Partial<Record<Property, PropertyOption>>;
}): JSX.Element {
    // Base property options
    const basePropertyOptions = useMemo(() => {
        return Object.values(props.propertiesOptions).map((p) => ({
            label: getPropertyLabel(p.value),
            value: p.value,
        }));
    }, [props.propertiesOptions]);

    // Operator options
    const getOperatorOptions = useCallback(
        (selected: PropertyFilterSelection) => {
            // Check if there is a property already selected
            if (!selected.property) {
                return [];
            }

            if (isCustomProperty(selected.property)) {
                return CUSTOM_PROPERTY_OPERATORS;
            }

            // Check if we have operators for the selected property
            const operatorOptions = props.propertiesOptions[selected.property as Property]?.operatorOptions;
            if (!operatorOptions) {
                return [];
            }

            // Return the operator options available
            return Object.values(operatorOptions).map((o) => ({ label: o.label, value: o.value }));
        },
        [props.propertiesOptions],
    );

    // Value options
    const getValueOptions = useCallback(
        (selected: PropertyFilterSelection) => {
            // Check if there is a property and an operator already selected
            if (!selected.property || !selected.operator) {
                return;
            }

            if (isCustomProperty(selected.property)) {
                const valueOptions: ValueOptions = {
                    allowCustomSearch: true,
                    allowMultiple: false,
                    isDate: false,
                };
                return valueOptions;
            }

            // Check if we have options for the selected property and operator
            const valueOptions =
                props.propertiesOptions[selected.property as Property]?.operatorOptions[selected.operator]
                    ?.valueOptions;
            if (!valueOptions) {
                return;
            }

            // Return the value options available
            return valueOptions;
        },
        [props.propertiesOptions],
    );

    // Return
    return (
        <Box direction="row" align="center">
            <Box
                width={{ min: '350px', width: '500px', max: '50vw' }}
                background={{ color: Colors.background_back }}
                border={{ color: Colors.border_dark, size: '1px' }}
                pad="10px"
                round="5px"
                gap="10px"
            >
                <Box direction="row" align="center" justify="between">
                    <Text style={{ fontFamily: FontFamily.Mono, textTransform: 'uppercase' }} size="14px" weight="bold">
                        Filter Group {props.groupIndex}
                    </Text>
                    <RemoveGroupButton onDelete={props.onDelete} />
                </Box>
                {/* Filter rows */}
                {props.propertyFilterGroup.propertyFilters.length > 0 && (
                    <Box gap="xxsmall">
                        {props.propertyFilterGroup.propertyFilters.map((selected, index) => (
                            <Box key={`filter-${index}`} gap={'xxsmall'} height={{ min: 'max-content' }}>
                                <FilterRow
                                    selection={selected}
                                    propertiesOptions={basePropertyOptions}
                                    operatorOptions={getOperatorOptions(selected)}
                                    valueOptions={getValueOptions(selected)}
                                    onChange={(filter) => {
                                        const group = structuredClone(props.propertyFilterGroup);
                                        group.propertyFilters[index] = filter;
                                        props.onChange(group);
                                    }}
                                    onDelete={() => {
                                        const group = structuredClone(props.propertyFilterGroup);
                                        group.propertyFilters.splice(index, 1);
                                        props.onChange(group);
                                    }}
                                />
                                {index !== props.propertyFilterGroup.propertyFilters.length - 1 && (
                                    <Text size="12px">and</Text>
                                )}
                            </Box>
                        ))}
                    </Box>
                )}
                {/* Add button */}
                <AddFilterButton onChange={props.onChange} propertyFilterGroup={props.propertyFilterGroup} />
            </Box>
        </Box>
    );
}
