// Copyright 2021
// ThatWorks.xyz Limited

import { Box, Layer, Text } from 'grommet';
import React, { useState } from 'react';
import { CtaButtonSpinnerV2 } from './CtaButtonSpinner';
import { TwHeading } from './TwHeading';

export function Modal(props: {
    show: boolean;
    title: string;
    buttons?: {
        hideCancel?: boolean;
        cancelText?: string;
        ctaBgColor?: string;
        ctaText?: string;
        ctaCompletionText?: string;
    };
    children?: React.ReactNode;
    onClose: () => void;
    onCta?: () => Promise<boolean>;
}): JSX.Element {
    const [ctaDone, setCtaDone] = useState(false);

    return !props.show ? (
        <></>
    ) : (
        <Layer
            modal
            onClickOutside={() => props.onClose()}
            onEsc={() => props.onClose()}
            background={{ color: 'unset' }}
        >
            <Box overflow="auto" background="background-front" round="8px" gap="small">
                <Box pad="small" gap="small">
                    <TwHeading level="3">{props.title}</TwHeading>
                    {props.children}
                    {(!props.buttons || (!props.buttons.hideCancel && props.onCta)) && (
                        <Box direction="row" gap="xsmall" align="center">
                            {!props.buttons?.hideCancel && (
                                <CtaButtonSpinnerV2
                                    label={props.buttons?.cancelText || 'Cancel'}
                                    notPrimary
                                    style={{ width: '80px', height: '28px' }}
                                    onClick={async () => props.onClose()}
                                />
                            )}

                            {props.onCta !== undefined && (
                                <CtaButtonSpinnerV2
                                    label={props.buttons?.ctaText || 'OK'}
                                    color={props.buttons?.ctaBgColor || 'accent-3'}
                                    style={{ width: '80px', height: '28px' }}
                                    onClick={async () => {
                                        setCtaDone(false);
                                        if (!props.onCta) {
                                            return;
                                        }
                                        const ok = await props.onCta();
                                        if (ok) {
                                            setCtaDone(true);
                                        }
                                    }}
                                />
                            )}
                            {ctaDone && props.buttons && props.buttons.ctaCompletionText !== undefined && (
                                <Box
                                    pad={{ horizontal: '6px', vertical: '4px' }}
                                    background={{ color: 'status-ok', opacity: 'medium' }}
                                    round="5px"
                                >
                                    <Text size="xsmall">{props.buttons.ctaCompletionText}</Text>
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
        </Layer>
    );
}
