// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { getReadableDateString } from '@thatworks/shared-frontend/date-helpers';
import { Box, BoxProps, Button, Calendar, CheckBox, Drop, Grid, Select, Text } from 'grommet';
import { History } from 'grommet-icons';
import { BorderType, MarginType, PadType, WidthType } from 'grommet/utils';
import { DateTime } from 'luxon';
import React, { useRef, useState } from 'react';
import { FontFamily, getGrommetTheme } from '../theme';
import { ReactComponent as DrowndownIcon } from './../icons/drowndown.svg';
import { TwHeading } from './TwHeading';

const theme = getGrommetTheme();

export function getDateRangeString(from: Date, to: Date) {
    const fromDT = DateTime.fromJSDate(from);
    const toDT = DateTime.fromJSDate(to);
    const diffDays = toDT.diff(fromDT, 'days');
    const diffWeeks = toDT.diff(fromDT, 'weeks');
    const days = Math.floor(diffDays.days);
    const weeks = Math.floor(diffWeeks.weeks);
    if (days === 0) {
        return `today`;
    }
    if (days === 1) {
        return `1 day`;
    }
    if ((days > 1 && days < 7) || (days > 7 && days < 14)) {
        return `${days} days`;
    }
    if (days === 7 || days === 14) {
        return `${weeks} ${weeks < 2 ? 'week' : 'weeks'}`;
    }
    const diffNow = toDT.diffNow();
    return `${from.toLocaleDateString()}-${Math.floor(diffNow.days) === 0 ? 'today' : to.toLocaleDateString()}`;
}

export function DateButton(props: {
    value: Date;
    textSize?: string;
    suffix?: string;
    onSelect: (date: Date) => void;
    bounds: { from: Date; to: Date };
    dropAlign?: {
        top?: 'bottom' | 'top' | undefined;
        bottom?: 'bottom' | 'top' | undefined;
        right?: 'left' | 'right' | undefined;
        left?: 'left' | 'right' | undefined;
    };
    hideIcon?: boolean;
}) {
    const [showCalendar, setShowCalendar] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const [mouseEnter, setMouseEnter] = useState(false);

    return (
        <Box>
            <Button
                onClick={() => {
                    if (!showCalendar) {
                        setShowCalendar(true);
                    }
                }}
                plain
            >
                {({ hover, disabled }) => (
                    // 4px required for safari to show the underline/colored border
                    <Box
                        direction="row"
                        margin={{ bottom: '4px' }}
                        onMouseEnter={() => setMouseEnter(true)}
                        onMouseLeave={() => setMouseEnter(false)}
                        gap="xxsmall"
                    >
                        <Box
                            ref={containerRef}
                            direction="row"
                            gap="xxsmall"
                            border={'bottom'}
                            style={{
                                borderColor: theme.global?.colors?.['accent-3'] as string,
                                borderWidth: mouseEnter ? '2px' : '1px',
                                marginBottom: mouseEnter ? '1px' : '2px',
                            }}
                        >
                            {!props.hideIcon && (
                                <Box margin={{ top: '3px' }} align="center">
                                    <History size="19px" color="dark-3" />
                                </Box>
                            )}
                            <Text
                                size={props.textSize || '24px'}
                                style={{ textTransform: 'capitalize', alignSelf: 'center' }}
                            >
                                {getDateRangeString(props.value, props.bounds.to)}
                                {props.suffix}
                            </Text>
                        </Box>
                    </Box>
                )}
            </Button>
            {showCalendar && (
                <Drop
                    overflow="auto"
                    onClickOutside={() => setShowCalendar(false)}
                    onEsc={() => setShowCalendar(false)}
                    target={containerRef.current || undefined}
                    margin="small"
                    align={props.dropAlign || { top: 'bottom', right: 'left' }}
                >
                    <Calendar
                        bounds={[props.bounds.from.toISOString(), props.bounds.to.toISOString()]}
                        defaultValue={props.value.toISOString()}
                        date={props.value.toISOString()}
                        daysOfWeek
                        size="medium"
                        onSelect={(e) => {
                            const dateSelected = new Date(e as string);
                            setShowCalendar(false);
                            props.onSelect(dateSelected);
                        }}
                    />
                </Drop>
            )}
        </Box>
    );
}

export const SIMPLE_BORDER_TEXT_BUTTON_ROUND = '6px';

export function SimpleBorderTextButton(props: {
    onClick: () => void;
    render: (hover: boolean, disabled: boolean) => React.ReactNode;
    disabled?: boolean;
    pad?: PadType;
    width?: WidthType;
    border?: BorderType;
    margin?: MarginType;
    boxProps?: BoxProps;
}): JSX.Element {
    return (
        <Button onClick={() => props.onClick()} plain disabled={props.disabled}>
            {({ hover, disabled }) => (
                <Box
                    direction="row"
                    gap="xxsmall"
                    border={props.border || { color: hover && !disabled ? 'brand' : 'dark-4', size: '1px' }}
                    pad={props.pad || { horizontal: 'xxsmall', vertical: '2px' }}
                    round={SIMPLE_BORDER_TEXT_BUTTON_ROUND}
                    width={props.width}
                    margin={props.margin}
                    {...props.boxProps}
                >
                    {props.render(hover, disabled)}
                </Box>
            )}
        </Button>
    );
}

export function DateButtonV2(props: {
    value: Date;
    textSize?: string;
    suffix?: string;
    onSelect: (date: Date) => void;
    bounds: { from: Date; to: Date };
    dropAlign?: {
        top?: 'bottom' | 'top' | undefined;
        bottom?: 'bottom' | 'top' | undefined;
        right?: 'left' | 'right' | undefined;
        left?: 'left' | 'right' | undefined;
    };
    hideIcon?: boolean;
}) {
    const [showCalendar, setShowCalendar] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    return (
        <Box>
            <SimpleBorderTextButton
                onClick={() => {
                    if (!showCalendar) {
                        setShowCalendar(true);
                    }
                }}
                render={(hover) => {
                    return (
                        <Box ref={containerRef} direction="row" gap="xxsmall">
                            {!props.hideIcon && (
                                <Box margin={{ top: '3px' }} align="center">
                                    <History size="14px" color={hover ? 'brand' : 'dark-4'} />
                                </Box>
                            )}
                            <Text
                                size={props.textSize || '24px'}
                                color={hover ? 'brand' : 'dark-4'}
                                style={{ textTransform: 'capitalize', alignSelf: 'center' }}
                            >
                                {getDateRangeString(props.value, props.bounds.to)}
                                {props.suffix}
                            </Text>
                        </Box>
                    );
                }}
            />
            {showCalendar && (
                <Drop
                    overflow="auto"
                    onClickOutside={() => setShowCalendar(false)}
                    onEsc={() => setShowCalendar(false)}
                    target={containerRef.current || undefined}
                    margin="xsmall"
                    align={props.dropAlign || { top: 'bottom', right: 'left' }}
                >
                    <Calendar
                        bounds={[props.bounds.from.toISOString(), props.bounds.to.toISOString()]}
                        defaultValue={props.value.toISOString()}
                        date={props.value.toISOString()}
                        daysOfWeek
                        size="medium"
                        firstDayOfWeek={1}
                        showAdjacentDays="trim"
                        onSelect={(e) => {
                            const dateSelected = new Date(e as string);
                            setShowCalendar(false);
                            props.onSelect(dateSelected);
                        }}
                    />
                </Drop>
            )}
        </Box>
    );
}

export enum PresetDateSelectionOptions {
    ThisQuarter = 'This Quarter',
    ThreeMonths = '3 Months',
    OneMonth = '1 Month',
    TwoWeeks = '2 weeks',
    OneWeek = '1 week',
    ThreeDays = '3 days',
    OneDay = '1 day',
    AllTime = 'All Time',
}

export function getDateFromPresetDateSelectionOption(op: PresetDateSelectionOptions): Date {
    const now = DateTime.now();
    switch (op) {
        case PresetDateSelectionOptions.ThisQuarter:
            return DateTime.local().startOf('quarter').toJSDate();
        case PresetDateSelectionOptions.AllTime:
            return now.minus({ years: 10 }).toJSDate();
        case PresetDateSelectionOptions.ThreeMonths:
            return now.minus({ month: 3 }).toJSDate();
        case PresetDateSelectionOptions.OneMonth:
            return now.minus({ month: 1 }).toJSDate();
        case PresetDateSelectionOptions.TwoWeeks:
            return now.minus({ weeks: 2 }).toJSDate();
        case PresetDateSelectionOptions.OneWeek:
            return now.minus({ week: 1 }).toJSDate();
        case PresetDateSelectionOptions.ThreeDays:
            return now.minus({ days: 3 }).toJSDate();
        case PresetDateSelectionOptions.OneDay:
            return now.minus({ day: 1 }).toJSDate();
    }
}

export function PresetDateSelection(props: {
    value: PresetDateSelectionOptions;
    optionsToFilter?: PresetDateSelectionOptions[];
    textSize?: string;
    onSelection: (op: PresetDateSelectionOptions) => void;
    dropAlign?: {
        top?: 'bottom' | 'top' | undefined;
        bottom?: 'bottom' | 'top' | undefined;
        right?: 'left' | 'right' | undefined;
        left?: 'left' | 'right' | undefined;
    };
    hideIcon?: boolean;
}) {
    const [showDrop, setShowDrop] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    return (
        <Box>
            <SimpleBorderTextButton
                onClick={() => {
                    if (!showDrop) {
                        setShowDrop(true);
                    }
                }}
                render={(hover) => {
                    return (
                        <Box ref={containerRef} direction="row" gap="xxsmall">
                            {!props.hideIcon && (
                                <Box margin={{ top: '3px' }} align="center">
                                    <History size="14px" color={hover ? 'brand' : 'dark-4'} />
                                </Box>
                            )}
                            <Text
                                size={props.textSize || '24px'}
                                color={hover ? 'brand' : 'dark-4'}
                                style={{ textTransform: 'capitalize', alignSelf: 'center' }}
                            >
                                {props.value}
                            </Text>
                        </Box>
                    );
                }}
            />
            {showDrop && (
                <Drop
                    overflow="auto"
                    onClickOutside={() => setShowDrop(false)}
                    onEsc={() => setShowDrop(false)}
                    target={containerRef.current || undefined}
                    margin="xxsmall"
                    align={props.dropAlign || { top: 'bottom', right: 'left' }}
                    round="10px"
                >
                    <Box pad="xsmall">
                        <Grid gap="xxsmall" columns={{ count: 3, size: 'flex' }}>
                            {Object.values(PresetDateSelectionOptions)
                                .filter((op) => {
                                    const filter = props.optionsToFilter || [];
                                    return !filter.includes(op);
                                })
                                .map((op, opi) => (
                                    <SimpleBorderTextButton
                                        key={`date-selection-${opi}`}
                                        onClick={() => {
                                            props.onSelection(op);
                                            setShowDrop(false);
                                        }}
                                        render={(hover) => (
                                            <Text size="16px" color={hover ? Colors.brand : undefined}>
                                                {op}
                                            </Text>
                                        )}
                                    />
                                ))}
                        </Grid>
                    </Box>
                </Drop>
            )}
        </Box>
    );
}

export function DateDropdown(props: { value: Date; onSelect: (date: Date) => void; bounds: { from: Date; to: Date } }) {
    const [mouseEnter, setMouseEnter] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    return (
        <Button
            onClick={() => {
                if (!showCalendar) {
                    setShowCalendar(true);
                }
            }}
            plain
        >
            {({ hover, disabled }) => (
                <Box
                    border={'bottom'}
                    style={{
                        borderColor: theme.global?.colors?.['accent-3'] as string,
                        borderWidth: mouseEnter ? '2px' : '1px',
                        marginBottom: mouseEnter ? '1px' : '2px',
                    }}
                    direction="row"
                    gap="xxsmall"
                    onMouseEnter={() => setMouseEnter(true)}
                    onMouseLeave={() => setMouseEnter(false)}
                    ref={containerRef}
                >
                    <TwHeading level="2" callout>
                        {getReadableDateString(props.value, new Date())}
                    </TwHeading>
                    <DrowndownIcon style={{ alignSelf: 'center' }} />
                    {showCalendar && (
                        <Drop
                            overflow="visible"
                            onClickOutside={() => setShowCalendar(false)}
                            onEsc={() => setShowCalendar(false)}
                            target={containerRef.current || undefined}
                        >
                            <Calendar
                                bounds={[props.bounds.from.toISOString(), props.bounds.to.toISOString()]}
                                defaultValue={props.value.toISOString()}
                                date={props.value.toISOString()}
                                onSelect={(e) => {
                                    const dateSelected = new Date(e as string);
                                    setShowCalendar(false);
                                    props.onSelect(dateSelected);
                                }}
                            />
                        </Drop>
                    )}
                </Box>
            )}
        </Button>
    );
}

export function FilterDropdown(props: {
    items: string[];
    label: string;
    onChange?: (items: number[]) => void;
    multiple?: boolean;
    plainText?: { size: string };
}): JSX.Element {
    const [mouseEnter, setMouseEnter] = useState(false);

    return (
        <Select
            options={props.items}
            plain
            defaultValue={props.label}
            dropProps={{ background: 'dark-1', round: '5px' }}
            dropAlign={{ top: 'bottom', right: 'right' }}
            multiple={props.multiple}
            closeOnChange={!props.multiple}
            valueLabel={
                <Box
                    border={'bottom'}
                    style={{
                        borderColor: theme.global?.colors?.['accent-3'] as string,
                        borderWidth: mouseEnter ? '2px' : '1px',
                        marginBottom: mouseEnter ? '1px' : '2px',
                    }}
                    direction="row"
                    gap="xxsmall"
                    onMouseEnter={() => setMouseEnter(true)}
                    onMouseLeave={() => setMouseEnter(false)}
                >
                    {props.plainText ? (
                        <Text style={{ fontFamily: FontFamily.Callout, fontSize: props.plainText.size }}>
                            {props.label}&nbsp;
                        </Text>
                    ) : (
                        <TwHeading level={2} callout={true}>
                            {props.label}&nbsp;
                        </TwHeading>
                    )}

                    <DrowndownIcon style={{ alignSelf: 'center' }} />
                </Box>
            }
            icon={false}
            onChange={(p: { selected: number[] }) => {
                if (props.onChange) {
                    props.onChange(p.selected);
                }
            }}
        >
            {(
                option: string,
                _index: number,
                _options: string[],
                state: { active: boolean; disabled: boolean; selected: boolean },
            ) => {
                return (
                    <Box pad="xsmall" direction="row" gap="xsmall">
                        {props.multiple && <CheckBox tabIndex={-1} checked={state.selected} />}
                        <Text
                            color="white"
                            style={{
                                fontFamily: state.selected && !props.multiple ? FontFamily.Callout : undefined,
                                fontSize: props.multiple ? undefined : '1.5em',
                            }}
                        >
                            {option}
                        </Text>
                    </Box>
                );
            }}
        </Select>
    );
}
