// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { ConnectorName } from '@thatworks/connector-api';
import { Box, Text } from 'grommet';
import { GetWorkspacePostTemplatesQuery } from '../../../../../__generated__/graphql';
import { ConnectorIconSmall } from '../../../../../components/ConnectorIcon';
import { TwHeading } from '../../../../../components/TwHeading';
import { SavedQueriesOptions } from './SavedQueriesOptions';

export function SavedQueriesSummary(props: {
    template: GetWorkspacePostTemplatesQuery['workspacePostTemplates'][0];
    onDeleteSavedQueries: () => void;
    onUpdateSavedQueries: () => void;
}): JSX.Element {
    return (
        <Box
            pad="xsmall"
            border={{ color: Colors.border_light, size: '2px' }}
            round="8px"
            width="large"
            gap="xsmall"
            background={{ color: Colors.background_back }}
        >
            {/* Header */}
            <Box gap="xxsmall">
                <Box direction="row" align="center" justify="between">
                    {/* Saved queries title */}
                    <TwHeading level={4}>{props.template.title}</TwHeading>

                    {/* Saved queries dropdown */}
                    <SavedQueriesOptions
                        template={props.template}
                        onDeleteSavedQueries={props.onDeleteSavedQueries}
                        onUpdateSavedQueries={props.onUpdateSavedQueries}
                    />
                </Box>
            </Box>
            {/* Body */}
            <Box gap="xxsmall" wrap>
                <Text size="16px" weight="bold">
                    Sections
                </Text>
                <Box
                    background={Colors.background_front}
                    round="5px"
                    pad="xxsmall"
                    gap="xxsmall"
                    border={{ size: '1px', color: Colors.border_light }}
                >
                    {props.template.queryBlocks.map((qb, qbi) => (
                        <Box
                            key={`${props.template.id}-${qbi}`}
                            id={qb.filterName ?? `$id-{qbi}`}
                            round="8px"
                            gap={'xxsmall'}
                            wrap
                        >
                            <Text size="14px" weight="bold">
                                {qb.filterName}
                            </Text>
                            <Box gap={'xxsmall'}>
                                {qb.scopes.map((s, si) => (
                                    <Box key={`${props.template.id}-${qbi}-${si}`} direction="row" align="center">
                                        <ConnectorIconSmall sizePixels="14px" name={s.connector as ConnectorName} />
                                        <Text size="14px" truncate>
                                            &nbsp;{s.itemName}
                                        </Text>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
}
