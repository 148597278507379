// Copyright 2021
// ThatWorks.xyz Limited

import { useMutation, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { Colors } from '@thatworks/colors';
import { ConnectorName, getFormattedConnectorName } from '@thatworks/connector-api';
import { CustomFilePickerIds, FilterHierarchyType } from '@thatworks/shared-frontend/collections';
import { Box, Spinner, Text } from 'grommet';
import { AddCircle, FormClose } from 'grommet-icons';
import { useState } from 'react';
import { gql } from '../../../../__generated__';
import { BasicPage3, BasicPage3NavFunction, DefaultPagePaddingPixels } from '../../../../components/BasicPage3';
import { DeleteConfirmationModal } from '../../../../components/ConfirmationModal';
import { ConnectorIconSmall } from '../../../../components/ConnectorIcon';
import { IconButtonV2, RoundIconButton } from '../../../../components/IconButton';
import { PageTitle } from '../../../../components/PageTitle';
import { getLabel, getNavIcon, SidebarNav } from '../../../../components/SidebarNav';
import { useTelemetryContext } from '../../../../components/TelemetryContext';
import { ConnectorConnectedWithoutStatus, useUserStateContext } from '../../../../components/UserContext';
import { withAuth } from '../../../../components/withAuth';
import { picker } from '../../../../shared/pickers/get-picker';
import { FontFamily } from '../../../../theme';

const GET_DATA_SOURCES = gql(/* GraphQL */ `
    query GetDataSources($connector: String!, $connectorUserId: String!) {
        dataSources(connector: $connector, connectorUserId: $connectorUserId) {
            id
            title
            connector
            connectorUserId
        }
    }
`);

const CREATE_DATA_SOURCE = gql(/* GraphQL */ `
    mutation DataSourceCreate(
        $connector: String!
        $connectorUserId: String!
        $idFromConnector: String!
        $connectorObjectType: String!
        $hierarchyType: String!
    ) {
        dataSourceCreate(
            connector: $connector
            connectorUserId: $connectorUserId
            idFromConnector: $idFromConnector
            connectorObjectType: $connectorObjectType
            hierarchyType: $hierarchyType
        )
    }
`);

const DELETE_DATA_SOURCE = gql(/* GraphQL */ `
    mutation DataSourceDelete($dataSourceDeleteId: ID!) {
        dataSourceDelete(id: $dataSourceDeleteId)
    }
`);

function ConnectorDataSource(props: { connector: ConnectorConnectedWithoutStatus }): JSX.Element | null {
    const { postErrorMessage } = useUserStateContext();
    const { logger } = useTelemetryContext();
    const { getAccessTokenSilently } = useAuth0();
    const [createDataSource] = useMutation(CREATE_DATA_SOURCE);
    const [showDeleteModal, setShowDeleteModal] = useState<
        { dataSourceId: string; dataSourceName: string } | undefined
    >(undefined);

    const { loading, data, refetch } = useQuery(GET_DATA_SOURCES, {
        variables: {
            connector: props.connector.connector,
            connectorUserId: props.connector.connectorUserId,
        },
        onError: (error) => {
            postErrorMessage({
                title: `Error`,
                shortDesc: `Failed to get sources for ${getFormattedConnectorName(props.connector.connector)} account ${
                    props.connector.accountDisplayName
                }`,
            });
            logger.error(error.message);
        },
    });

    const [deleteDataSource] = useMutation(DELETE_DATA_SOURCE, {
        onError: (error) => {
            postErrorMessage({
                title: `Error`,
                shortDesc: `Failed to delete`,
            });
            logger.error(error.message);
        },
    });

    if (data && data.dataSources.length === 0 && !loading) {
        return null;
    }

    return (
        <Box pad="xsmall" border={{ color: Colors.border_light }} round="5px">
            <Box align="center" direction="row" gap="xsmall" pad="15px">
                <ConnectorIconSmall name={props.connector.connector as ConnectorName} sizePixels={`20px`} />
                <Text size="14px" weight={'bold'} wordBreak="break-all">
                    {props.connector.accountDisplayName}
                </Text>
            </Box>
            <Box>
                {loading && <Spinner />}

                {!loading && (
                    <Box
                        direction="row"
                        wrap
                        pad={{ horizontal: data && data.dataSources.length > 0 ? 'xsmall' : '14px' }}
                        align="center"
                        gap="xxsmall"
                    >
                        {data &&
                            data.dataSources.map((d) => (
                                <Box
                                    direction="row"
                                    key={d.id}
                                    gap="xxsmall"
                                    background="accent-4"
                                    pad={{ horizontal: 'xsmall', vertical: 'xxsmall' }}
                                    round="10px"
                                    align="center"
                                    border={{ color: Colors.light_3, size: '2px' }}
                                    margin={{ bottom: 'xxsmall' }}
                                >
                                    <Box direction="row" gap="xsmall">
                                        <Text size="14px" style={{ fontFamily: FontFamily.Mono }}>
                                            {d.title}
                                        </Text>
                                    </Box>
                                    <RoundIconButton
                                        icon={FormClose}
                                        sizePixels={12}
                                        onClick={() => {
                                            setShowDeleteModal({ dataSourceId: d.id, dataSourceName: d.title });
                                        }}
                                    />
                                </Box>
                            ))}
                        {props.connector.connector === ConnectorName.GOOGLE &&
                            props.connector.linked &&
                            props.connector.linked.find(
                                (l) => l.connected && l.name === ConnectorName.GOOGLE_DRIVE,
                            ) && (
                                <Box
                                    margin={{
                                        bottom: 'xxsmall',
                                        left: data && data.dataSources.length > 0 ? 'xxsmall' : undefined,
                                    }}
                                >
                                    <IconButtonV2
                                        tooltip="Add Data Source"
                                        icon={(hover, _, submitting) => (
                                            <Box elevation="xsmall" round="50%" pad="2px">
                                                {!submitting && (
                                                    <AddCircle
                                                        size="20px"
                                                        color={hover ? Colors.brand : Colors.dark_4}
                                                    />
                                                )}
                                                {submitting && <Spinner size="1px" width={'18px'} height={'18px'} />}
                                            </Box>
                                        )}
                                        onClickWithSpinner={async () => {
                                            try {
                                                const tokens = await getAccessTokenSilently({ detailedResponse: true });
                                                const picked = await picker(
                                                    tokens.id_token,
                                                    {
                                                        name: ConnectorName.GOOGLE_DRIVE,
                                                        connectorUserId: props.connector.connectorUserId,
                                                    },
                                                    CustomFilePickerIds.GoogleSheetCustomization,
                                                    (error) => {
                                                        postErrorMessage({
                                                            title: 'Error',
                                                            shortDesc: 'Failed to load Google Drive browser',
                                                        });
                                                        logger.exception(error);
                                                    },
                                                );
                                                if (picked.length > 0) {
                                                    const r = await createDataSource({
                                                        variables: {
                                                            connector: ConnectorName.GOOGLE_DRIVE,
                                                            connectorUserId: props.connector.connectorUserId,
                                                            connectorObjectType: picked[0].id.connectorObjectType,
                                                            hierarchyType: FilterHierarchyType.GoogleSheet,
                                                            idFromConnector: picked[0].id.idFromConnector,
                                                        },
                                                        onError: (error) => {
                                                            postErrorMessage({
                                                                title: 'Error',
                                                                shortDesc: 'Failed to add data source',
                                                            });
                                                            logger.error(error.message);
                                                        },
                                                    });
                                                    if (r.data) {
                                                        await refetch();
                                                    }
                                                }
                                            } catch (error) {
                                                postErrorMessage({
                                                    title: 'Error',
                                                    shortDesc: 'Failed to find Google Drive browser',
                                                });
                                                logger.exception(error);
                                            }
                                        }}
                                    />
                                </Box>
                            )}
                    </Box>
                )}
            </Box>
            <DeleteConfirmationModal
                showModal={showDeleteModal !== undefined}
                itemName={showDeleteModal?.dataSourceName || ''}
                onCancel={() => setShowDeleteModal(undefined)}
                onConfirm={async () => {
                    if (showDeleteModal) {
                        await deleteDataSource({
                            variables: {
                                dataSourceDeleteId: showDeleteModal.dataSourceId,
                            },
                        });
                        await refetch();
                    }
                }}
                onClose={() => setShowDeleteModal(undefined)}
            />
        </Box>
    );
}

function Data(props: { onNav: BasicPage3NavFunction }): JSX.Element {
    const { connectedConnectorsWithoutStatus } = useUserStateContext();

    return (
        <BasicPage3 activeNavButton={SidebarNav.Data} browserPageTitle={getLabel(SidebarNav.Data)} onNav={props.onNav}>
            <Box overflow={{ vertical: 'auto' }} height="100%" width="100%">
                <Box height={{ min: 'max-content' }}>
                    <Box
                        pad={{
                            left: DefaultPagePaddingPixels,
                            right: DefaultPagePaddingPixels,
                            top: 'small',
                            bottom: 'xsmall',
                        }}
                        border={{ side: 'bottom', color: 'border' }}
                    >
                        <PageTitle icon={getNavIcon(SidebarNav.Data)}>{'Data Sources'}</PageTitle>
                    </Box>
                    <Box
                        pad={{
                            left: DefaultPagePaddingPixels,
                            right: DefaultPagePaddingPixels,
                            top: 'small',
                            bottom: 'small',
                        }}
                        gap="xxsmall"
                    >
                        {connectedConnectorsWithoutStatus.length === 0 && <Text>No compatible Apps connected.</Text>}
                        {connectedConnectorsWithoutStatus.map((c, ci) => (
                            <ConnectorDataSource connector={c} key={`connector-data-${ci}`} />
                        ))}
                    </Box>
                </Box>
            </Box>
        </BasicPage3>
    );
}

export default withAuth(Data);
