// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box, Text } from 'grommet';

export function WorkspacePostErrorBox(props: { message: string }): JSX.Element {
    return (
        <Box pad="small" background={{ color: Colors.status_warning, opacity: 'weak' }} round={'10px'}>
            <Text>{props.message}</Text>
        </Box>
    );
}
