// Copyright 2021
// ThatWorks.xyz Limited

import { withAuthenticationRequired } from '@auth0/auth0-react';
import { ComponentType } from 'react';
import Loading from './Loading';

export function withAuth<P extends object>(Component: ComponentType<P>, RedirectComponent = <Loading />) {
    return withAuthenticationRequired(Component, {
        onRedirecting: () => RedirectComponent,
        returnTo: window.location.pathname,
    });
}
