// Copyright 2021
// ThatWorks.xyz Limited

import { useAuth0 } from '@auth0/auth0-react';
import { ConnectorName, ConnectPreAuthInput } from '@thatworks/connector-api';
import { joinPagesPaths, Pages, QueryParams } from '@thatworks/shared-frontend/pages';
import { Capitalize } from '@thatworks/string-utils';
import { Box, Button, Card, CardBody, Text } from 'grommet';
import { useCallback, useEffect, useState } from 'react';
import { ConnectorAvailableState, ConnectorConnectedState } from '../../../../__generated__/graphql';
import { ConnectionButtonsGrid } from '../../../../components/ConnectionComponents';
import { ErrorMessage, GenericErrorToast } from '../../../../components/ErrorToast';
import Loading from '../../../../components/Loading';
import { useTelemetryContext } from '../../../../components/TelemetryContext';
import { useUserStateContext } from '../../../../components/UserContext';
import Api from '../../../../shared/Api';
import { ConnectConnectorParams, getAbsoluteUrl } from '../../../../shared/connectors-helper';
import { useNavNoRerender } from '../../../../shared/UseNavNoRerender';

export default function ConnectBody(props: {
    onboarding: boolean;
    onError: (err: ErrorMessage | undefined) => void;
}): JSX.Element {
    const _onError = props.onError;

    const onError = useCallback(
        (err: ErrorMessage | undefined) => {
            _onError(err);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const { getAccessTokenSilently } = useAuth0();
    const { logger } = useTelemetryContext();
    const navigate = useNavNoRerender();
    const [connectorErrorMessage, setConnectorErrorMessage] = useState<ErrorMessage | undefined>(undefined);
    const { userState, pollState } = useUserStateContext();

    // const { cache } = useApolloClient();
    // const clearCache = useCallback(() => {
    //     const fields = [GET_SCOPE_INDICATORS_QUERY_FIELD_NAME];
    //     fields.forEach((f) => cache.evict({ id: 'ROOT_QUERY', fieldName: f }));
    //     cache.gc();
    // }, [cache]);

    const onConnectorConnect = useCallback(
        (
            connector: ConnectorAvailableState | ConnectorConnectedState,
            state: { preAuthState?: ConnectPreAuthInput; selectedLinkedApps?: ConnectorName[] },
        ) => {
            const width = 600;
            const height = 600;
            const left = window.screenX + (window.outerWidth - width) / 2;
            const top = window.screenY + (window.outerHeight - height) / 2.5;
            const windowFeatures = `toolbar=0,scrollbars=1,status=1,resizable=0,location=1,menuBar=0,width=${width},height=${height},top=${top},left=${left}`;

            const data: ConnectConnectorParams = {
                connectorName: connector.connector as ConnectorName,
                preAuthState: state.preAuthState,
                selectedLinkedApps:
                    state.selectedLinkedApps && state.selectedLinkedApps.length > 0
                        ? state.selectedLinkedApps
                        : undefined,
            };
            const dataAsJson = JSON.stringify(data);
            const popupUrl = getAbsoluteUrl(joinPagesPaths([Pages.connector_oauth.root]));
            const externalWindow = window.open(
                `${popupUrl}?${QueryParams.OAuthConnectionData}=${window.btoa(dataAsJson)}`,
                `Connect`,
                windowFeatures,
            );
            if (!externalWindow) {
                onError({
                    title: 'Could not open popup',
                    shortDesc: 'Enable popups in your browser',
                });
            }
        },
        [onError],
    );

    const onConnectorDisconnect = useCallback(
        async (connector: ConnectorConnectedState): Promise<boolean> => {
            try {
                const tokens = await getAccessTokenSilently({ detailedResponse: true });
                await Api.delete([Api.routes.CONNECTORS, `connector`], tokens.id_token, {
                    params: new URLSearchParams({
                        name: connector.connector,
                        connectorUserId: connector.connectorUserId,
                    }),
                });
                return true;
            } catch (error) {
                Api.handleException(error, logger, navigate);
                setConnectorErrorMessage({
                    title: `${Capitalize(connector.connector)}`,
                    shortDesc: 'Error reaching the server.',
                });
                return false;
            }
        },
        [getAccessTokenSilently, logger, navigate],
    );

    useEffect(() => {
        pollState(true);
        return () => {
            pollState(false);
        };
    }, [pollState]);

    const getConnectedByUserCount = useCallback(() => {
        if (!userState) {
            return 0;
        }
        return userState.connectedConnectors.filter((c) => c.isConnectedByUser).length;
    }, [userState]);

    const onboardButtonCtaLabel = 'Next';

    return userState === undefined ? (
        <Loading />
    ) : (
        <>
            <GenericErrorToast error={connectorErrorMessage} onClose={() => setConnectorErrorMessage(undefined)} />
            <Box gap="small" pad={undefined}>
                {props.onboarding && (
                    <Box width="large">
                        <Card pad="small" elevation="medium" border={{ color: 'neutral-1' }}>
                            <CardBody gap="small" pad="xsmall">
                                <Text>
                                    <strong>
                                        {getConnectedByUserCount() > 0
                                            ? `🎉 Nice! Connect more apps to get the most out of That Works.`
                                            : 'Click on the icons to connect your apps.'}
                                    </strong>
                                </Text>
                                <Text>
                                    {getConnectedByUserCount() > 0
                                        ? `Or click ${onboardButtonCtaLabel} to continue.`
                                        : 'Connections are securely encrypted and use industry standard OAuth tokens and permissions.'}
                                </Text>
                                {getConnectedByUserCount() > 0 && (
                                    <Button
                                        primary
                                        label={onboardButtonCtaLabel}
                                        style={{ width: '80px', height: '30px', padding: '2px 16px 2px 16px' }}
                                        onClick={async () => navigate(Pages.app.subs.connect.subs.status)}
                                    />
                                )}
                            </CardBody>
                        </Card>
                    </Box>
                )}

                <ConnectionButtonsGrid
                    availableConnectors={userState.availableConnectors}
                    connectedConnectors={userState.connectedConnectors}
                    onConnect={onConnectorConnect}
                    onDisconnect={onConnectorDisconnect}
                    onReconnect={onConnectorConnect}
                    onError={(err) => setConnectorErrorMessage(err)}
                />
            </Box>
        </>
    );
}
