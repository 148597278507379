// Copyright 2021
// ThatWorks.xyz Limited

import { ReactComponent as Av0NoCaret } from './../icons/fun_avatar_1.svg';
import { ReactComponent as Av1NoCaret } from './../icons/fun_avatar_2.svg';
import { ReactComponent as Av2NoCaret } from './../icons/fun_avatar_3.svg';
import { ReactComponent as Av3NoCaret } from './../icons/fun_avatar_4.svg';
import { ReactComponent as Av0 } from './../icons/fun_avatar_caret_1.svg';
import { ReactComponent as Av1 } from './../icons/fun_avatar_caret_2.svg';
import { ReactComponent as Av2 } from './../icons/fun_avatar_caret_3.svg';
import { ReactComponent as Av3 } from './../icons/fun_avatar_caret_4.svg';

const listWithCaret = [Av0, Av1, Av2, Av3];
const listWithoutCaret = [Av0NoCaret, Av1NoCaret, Av2NoCaret, Av3NoCaret];
const randomIndex = Math.round(Math.random() * (Math.min(listWithCaret.length, listWithoutCaret.length) - 1));

export function RandomAvatar(props: { size: string; withoutCaret?: boolean }) {
    function Render(props: { im: React.FC<React.SVGProps<SVGSVGElement>>; size: string }): JSX.Element {
        return <props.im width={props.size} height={props.size} style={{ pointerEvents: 'none' }} />;
    }
    return Render({
        im: props.withoutCaret ? listWithoutCaret[randomIndex] : listWithCaret[randomIndex],
        size: props.size,
    });
}
