// Copyright 2021
// ThatWorks.xyz Limited

import { Blank, IconProps } from 'grommet-icons';

export const Thunder = (props: IconProps & JSX.IntrinsicElements['svg']) => (
    <Blank {...props}>
        <svg viewBox="0 0 18 24" xmlns="http://www.w3.org/2000/svg">
            <path
                stroke="none"
                d="M17.3741 11.2909C17.3522 11.1973 17.3076 11.1105 17.2442 11.0383C17.1808 10.966 17.1006 10.9105 17.0106 10.8767L11.2383 8.71222L12.7002 1.40309C12.7251 1.27834 12.7087 1.14886 12.6534 1.03429C12.5981 0.919722 12.5069 0.826312 12.3937 0.768232C12.2805 0.710151 12.1515 0.690568 12.0262 0.712453C11.9009 0.734338 11.7861 0.796498 11.6993 0.889503L0.78432 12.5841C0.718769 12.6544 0.671552 12.7398 0.646847 12.8326C0.622142 12.9255 0.62071 13.023 0.64268 13.1166C0.664649 13.2102 0.709342 13.2969 0.772802 13.369C0.836263 13.4412 0.916536 13.4966 1.00652 13.5304L6.77878 15.6949L5.31696 23.004C5.29185 23.1288 5.30815 23.2584 5.36338 23.373C5.41861 23.4877 5.50975 23.5812 5.62297 23.6394C5.7057 23.6815 5.79717 23.7035 5.88999 23.7037C5.97026 23.7037 6.04967 23.6872 6.12328 23.6552C6.19688 23.6232 6.2631 23.5763 6.31782 23.5176L17.2328 11.823C17.2982 11.7527 17.3453 11.6674 17.37 11.5746C17.3946 11.4818 17.396 11.3844 17.3741 11.2909ZM6.86454 21.2147L8.01938 15.4376C8.04678 15.3015 8.02486 15.1601 7.95755 15.0387C7.89023 14.9173 7.78192 14.8238 7.65198 14.7749L2.23348 12.743L11.1467 3.19237L9.9948 8.97048C9.9674 9.10657 9.98932 9.24798 10.0566 9.36939C10.124 9.4908 10.2323 9.58431 10.3622 9.63317L15.7807 11.6651L6.86454 21.2147Z"
            />
        </svg>
    </Blank>
);
