// Copyright 2021
// ThatWorks.xyz Limited

import { Anchor, Box, Text, TextInput } from 'grommet';
import { FormClose } from 'grommet-icons';
import { useCallback, useState } from 'react';
import { IconButton } from './IconButton';

export function StringEntry(props: {
    onValidation: (userInput: string, validated: string) => undefined | string;
    onErrorChange: (error: boolean) => void;
    validationRegex?: string[];
    validationFunc?: (value: string) => string | undefined;
    validationFailureErrorMessage?: string;
    postProcessEntry?: (s: string) => string;
    placeholder?: string;
}): JSX.Element {
    const [value, setValue] = useState('');
    const [error, setError] = useState<string>();
    const [focussed, setFocussed] = useState(false);
    const postProcessEntry = props.postProcessEntry;
    const validationFunc = props.validationFunc;

    const validate = useCallback(
        (toCheck: string): string | undefined => {
            if (!props.validationRegex && !validationFunc) {
                return toCheck;
            }

            if (props.validationRegex) {
                let stringVal: string | undefined = toCheck;
                props.validationRegex.forEach((v) => {
                    if (!stringVal) {
                        return;
                    }

                    const matched = stringVal.match(v);
                    if (!matched || matched.length === 0) {
                        stringVal = undefined;
                        return;
                    }
                    stringVal = matched[0];
                });
                if (postProcessEntry) {
                    stringVal = postProcessEntry(stringVal);
                }
                return stringVal;
            } else if (validationFunc) {
                return validationFunc(toCheck);
            }
        },
        [postProcessEntry, props.validationRegex, validationFunc],
    );

    const validateAndNotify = useCallback(
        (val: string | undefined) => {
            if (val && val.length > 0) {
                const validated = validate(val);
                if (!validated) {
                    setError(props.validationFailureErrorMessage || 'Failed to validate.');
                    props.onErrorChange(true);
                    return;
                }

                const res = props.onValidation(val, validated);
                if (res) {
                    setError(res);
                    props.onErrorChange(true);
                } else {
                    setError(undefined);
                    props.onErrorChange(false);
                    setFocussed(false);
                }
            } else if (!val || val.length === 0) {
                setError(undefined);
                props.onErrorChange(false);
            }
        },
        [props, validate],
    );

    return (
        <Box>
            <Box
                direction="row"
                border={{ color: error ? 'status-critical' : 'border' }}
                align="center"
                height="40px"
                pad={{ horizontal: '10px' }}
                round="5px"
                background={!focussed && value.length > 0 && !error ? 'background-back' : undefined}
            >
                <Box
                    width={value.length === 0 ? `${props.placeholder ? props.placeholder.length * 11 : 110}px` : '100%'}
                >
                    <TextInput
                        plain
                        focusIndicator={false}
                        placeholder={`${props.placeholder || 'Enter info'} or`}
                        onChange={(event) => {
                            setValue(event.target.value);
                        }}
                        onFocus={() => setFocussed(true)}
                        onBlur={() => {
                            validateAndNotify(value);
                        }}
                        onKeyDown={async (e) => {
                            if (e.key === 'Enter') {
                                validateAndNotify(value);
                            }
                        }}
                        value={value}
                    />
                </Box>
                {value.length === 0 && (
                    <Text size="14px">
                        <Anchor
                            onClick={async () => {
                                const d = await window.navigator.clipboard.readText();
                                setValue(d);
                                validateAndNotify(d);
                            }}
                        >
                            Paste from clipboard
                        </Anchor>
                    </Text>
                )}
            </Box>
            {error && <Text size="xsmall">{error}</Text>}
        </Box>
    );
}
export function StringEntryCard(props: { value: string; onRemove: () => void; error?: string }): JSX.Element {
    return (
        <Box>
            <Box
                direction="row"
                border={{ color: props.error ? 'status-critical' : 'neutral-1' }}
                align="center"
                height="40px"
                pad={{ horizontal: '10px' }}
                round="5px"
                background={'background-back'}
            >
                <Box width={'100%'}>
                    <Text size="14px" truncate>
                        {props.value}
                    </Text>
                </Box>
                <IconButton icon={FormClose} onClick={props.onRemove} />
            </Box>
            {props.error && <Text size="xsmall">{props.error}</Text>}
        </Box>
    );
}
