// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { ConnectorName } from '@thatworks/connector-api';
import { Box, Text } from 'grommet';
import { MetricChartData, MetricChartError } from '../__generated__/graphql';
import { LineBarChart } from './Charts';
import { ConnectorIconSmall } from './ConnectorIcon';
import { TwHeading } from './TwHeading';

export function MetricChartDataQl(props: {
    chart: MetricChartData;
    hideLegend?: boolean;
    headingSize?: 1 | 2 | 3 | 4 | 5 | 6;
}): JSX.Element {
    return (
        <Box
            gap="xxsmall"
            background={Colors.background_back}
            pad="small"
            round="15px"
            border={{ color: Colors.border_light, size: '2px' }}
        >
            <Box gap="xxsmall" direction="row" align="center">
                {props.chart.connector && (
                    <ConnectorIconSmall name={props.chart.connector as ConnectorName} sizePixels="16px" />
                )}
                <TwHeading level={props.headingSize || '5'}>{props.chart.title}</TwHeading>
            </Box>
            <LineBarChart
                chartProps={{
                    data: props.chart.chartData.data,
                    type: props.chart.chartData.type,
                    options: {
                        ...props.chart.chartData.options,
                        responsive: true,
                        aspectRatio: 3,
                        plugins: {
                            legend: {
                                display: false,
                            },
                        },
                        interaction: {
                            intersect: false,
                            mode: 'nearest',
                        },
                    },
                }}
            />
            {!props.hideLegend && (
                <Box gap="xsmall" justify="center" fill="horizontal" align="center" direction="row">
                    {props.chart.chartData.data.legend.map(
                        (d: { connector: ConnectorName | undefined; color: any; label: any }, di: number) => (
                            <Box direction="row" gap="xxsmall" align="center" key={`metric-chart-${di}`}>
                                {d.connector === undefined && (
                                    <Box background={{ color: d.color }} width="12px" height="12px" />
                                )}
                                {d.connector && (
                                    <Box
                                        border={{ color: d.color, size: '3px' }}
                                        style={{ borderRadius: '50%' }}
                                        pad="5px"
                                        width={{ min: 'max-content' }}
                                    >
                                        <ConnectorIconSmall name={d.connector} sizePixels="12px" />
                                    </Box>
                                )}
                                <Text size="14px">{d.label}</Text>
                            </Box>
                        ),
                    )}
                </Box>
            )}
        </Box>
    );
}

export function MetricChartErrorQl(props: { chart: MetricChartError }): JSX.Element {
    return (
        <Box
            gap="xxsmall"
            background={Colors.background_back}
            pad="small"
            round="15px"
            border={{ color: Colors.border_light, size: '2px' }}
        >
            <Box gap="xxsmall" direction="row" align="center">
                {props.chart.connector && (
                    <ConnectorIconSmall name={props.chart.connector as ConnectorName} sizePixels="16px" />
                )}
                <TwHeading level="5">{props.chart.title}</TwHeading>
            </Box>
            <Text size="16px">{props.chart.error}</Text>
        </Box>
    );
}
