// Copyright 2021
// ThatWorks.xyz Limited

import { useQuery } from '@apollo/client';
import { Events } from '@thatworks/shared-frontend/metrics';
import { Box } from 'grommet';
import { useStatsigLogEffect } from 'statsig-react';
import { gql } from '../../../../__generated__';
import Loading from '../../../../components/Loading';
import { useTelemetryContext } from '../../../../components/TelemetryContext';
import { useUserStateContext } from '../../../../components/UserContext';
import { withAuth } from '../../../../components/withAuth';
import { WorkplaceCreateEditView } from './WorkplaceCreateEditView';

const GET_THIS_USER = gql(/* GraphQL */ `
    query GetUser {
        user {
            name
            email
            id
        }
    }
`);

function WorkspaceCreate(props: { organizationId: string }): JSX.Element {
    const { organizationDisplayName, postErrorMessage } = useUserStateContext();
    const { logger } = useTelemetryContext();
    useStatsigLogEffect(Events.FrontendWorkspaceCreatePageLoaded);

    const { data, loading } = useQuery(GET_THIS_USER, {
        onError: (error) => {
            postErrorMessage({ title: 'Error', shortDesc: 'Failed to get topic settings' });
            logger.error(error.message);
        },
    });

    if (loading) {
        return (
            <Box fill align="center" justify="center">
                <Loading notAbsolute />
            </Box>
        );
    }

    if (!organizationDisplayName || !data) {
        return <></>;
    }

    return (
        <WorkplaceCreateEditView
            mode={{
                type: 'create',
                user: {
                    email: data.user.email,
                    userId: data.user.id,
                },
            }}
            organizationId={props.organizationId}
            organizationDisplayName={organizationDisplayName}
        />
    );
}

export default withAuth(WorkspaceCreate);
