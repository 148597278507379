// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box, Button, Text } from 'grommet';
import React from 'react';
import { CtaButtonSpinnerV2 } from './CtaButtonSpinner';
import { Modal } from './Modal';

function Confirmation(props: {
    message: string;
    cta: { text: string; color: string };
    onConfirm: () => Promise<void>;
    onCancel: () => void;
    children?: React.ReactNode;
}): JSX.Element {
    return (
        <Box gap="xsmall" width={{ min: 'medium', max: '70vw' }}>
            <Text>{props.message}</Text>
            {props.children}
            <Box direction="row" gap="xxsmall">
                <Button label="Cancel" secondary onClick={() => props.onCancel()} />
                <CtaButtonSpinnerV2
                    label={props.cta.text}
                    notPrimary
                    color={props.cta.color}
                    onClick={async () => {
                        await props.onConfirm();
                    }}
                    style={{ padding: '0px 30px 0px 30px' }}
                />
            </Box>
        </Box>
    );
}

export function ConfirmationModal(props: {
    title: string;
    message: string;
    showModal: boolean;
    cta: { text: string; color: string };
    onClose: () => void;
    onConfirm: () => Promise<void>;
    onCancel: () => void;
    children?: React.ReactNode;
}): JSX.Element {
    return (
        <Modal show={props.showModal} title={props.title} onClose={props.onClose} buttons={{ hideCancel: true }}>
            <Confirmation
                onConfirm={async () => {
                    await props.onConfirm();
                    props.onClose();
                }}
                cta={props.cta}
                onCancel={() => {
                    props.onCancel();
                    props.onClose();
                }}
                message={props.message}
            >
                {props.children}
            </Confirmation>
        </Modal>
    );
}

export function DeleteConfirmationModal(props: {
    itemName: string;
    showModal: boolean;
    onClose: () => void;
    onConfirm: () => Promise<void>;
    onCancel: () => void;
}): JSX.Element {
    return (
        <ConfirmationModal
            message={`Are you sure you want to delete ${props.itemName}?`}
            title="Are you sure?"
            onCancel={props.onCancel}
            onClose={props.onClose}
            onConfirm={props.onConfirm}
            showModal={props.showModal}
            cta={{ text: 'Delete', color: Colors.status_critical }}
        />
    );
}
