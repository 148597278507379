// Copyright 2021
// ThatWorks.xyz Limited

export function conditionally(
    test: () => boolean,
    trueElement: JSX.Element | string,
    falseElement: JSX.Element | string = '',
): JSX.Element | string {
    if (test()) {
        return trueElement;
    }
    return falseElement;
}
