// Copyright 2021
// ThatWorks.xyz Limited

import { Pages } from '@thatworks/shared-frontend/pages';
import { Navigate, Route, Routes } from 'react-router-dom';
import LoginPage from './login';

export default function Auth(): JSX.Element {
    return (
        <Routes>
            <Route path="/" element={<Navigate to={Pages.auth.subs.login} replace />} />
            <Route path={Pages.auth.subs.login} element={<LoginPage />} />
        </Routes>
    );
}
