// Copyright 2021
// ThatWorks.xyz Limited

import { Box } from 'grommet';
import { Operator, Property, PropertyFilterSelection, ValueOptions } from './helpers';
import { OperatorSelect } from './OperatorSelect';
import { PropertySelect } from './PropertySelect';
import { RemoveFilterButton } from './RemoveFilterButton';
import { ValueInput } from './ValueInput';

export function FilterRow(props: {
    onChange: (filter: PropertyFilterSelection) => void;
    selection: PropertyFilterSelection;
    onDelete: () => void;
    propertiesOptions: { label: string; value: Property }[];
    operatorOptions: { label: string; value: Operator }[];
    valueOptions: ValueOptions | undefined;
}): JSX.Element {
    return (
        <Box direction="row" align="center" gap="xxsmall" justify="between">
            <Box direction="row" align="center" gap="xxsmall">
                <PropertySelect
                    selection={props.selection}
                    onChange={props.onChange}
                    propertiesOptions={props.propertiesOptions}
                />
                <OperatorSelect
                    selection={props.selection}
                    onChange={props.onChange}
                    operatorOptions={props.operatorOptions}
                />
                <ValueInput selection={props.selection} onChange={props.onChange} valueOptions={props.valueOptions} />
            </Box>
            <RemoveFilterButton onDelete={props.onDelete} />
        </Box>
    );
}
