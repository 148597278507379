// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { ConnectorName } from '@thatworks/connector-api';
import { formatAxis, LinearBarChartProps } from '@thatworks/shared-frontend/insights';
import {
    BarElement,
    CategoryScale,
    ChartData,
    Chart as ChartJS,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    TimeScale,
    TimeSeriesScale,
    Title,
    Tooltip,
    TooltipModel,
} from 'chart.js';
import { Box, Text } from 'grommet';
import isEqual from 'lodash.isequal';
import { useCallback, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { ConnectorIconSmall } from './ConnectorIcon';
// Has to be after chart.js import
import 'chartjs-adapter-luxon';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    TimeScale,
    TimeSeriesScale,
    Title,
    Tooltip,
    Legend,
    Filler,
);

interface TooltipProps {
    top: number;
    left: number;
    data: { title?: string; points: { label?: string; value: string; datasetIndex: number }[] };
}

const chartBackground = {
    id: 'chartBackground',
    beforeDraw(chart: ChartJS) {
        chart.ctx.save();
        chart.ctx.globalCompositeOperation = 'destination-over';
        chart.ctx.fillStyle = '#F8F8F8';
        chart.ctx.fillRect(0, 0, chart.width, chart.height);
        chart.ctx.restore();
    },
};

export function LineBarChart(props: { chartProps: LinearBarChartProps }) {
    const [tooltipProps, setTooltipProps] = useState<TooltipProps | undefined>();

    const getTooltipProps = useCallback(
        (context: { chart: ChartJS; tooltip: TooltipModel<'bar' | 'line'> }): TooltipProps | undefined => {
            // opacity 0 is for hiding the tooltip
            const tooltipModel = context.tooltip;
            if (tooltipModel.opacity === 0) {
                return undefined;
            }

            const position = context.chart.canvas.getBoundingClientRect();

            // For position: fixed
            const left = position.left + tooltipModel.caretX;
            const top = position.top + tooltipModel.caretY;
            return {
                top,
                left,
                data: {
                    title: tooltipModel.title
                        .map((t) =>
                            props.chartProps.data.xAxis ? formatAxis(t, props.chartProps.data.xAxis.displayType) : t,
                        )
                        .join(' '),
                    points: tooltipModel.dataPoints.map((d) => ({
                        label: d.dataset.label,
                        value: d.formattedValue,
                        datasetIndex: d.datasetIndex,
                    })),
                },
            };
        },
        [props.chartProps.data.xAxis],
    );

    const updateTooltipProps = useCallback(
        (existingProps: TooltipProps | undefined, props: TooltipProps | undefined) => {
            if (isEqual(existingProps, props)) {
                return;
            }
            setTooltipProps(props);
        },
        [],
    );

    return (
        <Box>
            {props.chartProps.type === 'bar' && (
                <Bar
                    options={{
                        ...props.chartProps.options,
                        plugins: {
                            ...props.chartProps.options.plugins,
                            tooltip: {
                                enabled: false,
                                external: (context) => {
                                    const props = getTooltipProps(context);
                                    updateTooltipProps(tooltipProps, props);
                                },
                            },
                        },
                    }}
                    data={props.chartProps.data.chartData as ChartData<'bar', number[], string>}
                    plugins={[chartBackground]}
                />
            )}
            {props.chartProps.type === 'line' && (
                <Line
                    options={{
                        ...props.chartProps.options,
                        plugins: {
                            ...props.chartProps.options.plugins,
                            tooltip: {
                                enabled: false,
                                external: (context) => {
                                    const props = getTooltipProps(context);
                                    updateTooltipProps(tooltipProps, props);
                                },
                            },
                        },
                        scales: {
                            ...props.chartProps.options.scales,
                            x: {
                                ...props.chartProps.options.scales?.x,
                                ticks:
                                    props.chartProps.data.xAxis &&
                                    props.chartProps.data.xAxis.displayType === 'ms-epoch'
                                        ? {
                                              ...props.chartProps.options.scales?.x?.ticks,
                                          }
                                        : {
                                              ...props.chartProps.options.scales?.x?.ticks,
                                              callback: function (value) {
                                                  if (props.chartProps.data.xAxis) {
                                                      return formatAxis(
                                                          this.getLabelForValue(value as number),
                                                          props.chartProps.data.xAxis.displayType,
                                                      );
                                                  }
                                                  return this.getLabelForValue(value as number);
                                              },
                                          },
                            },
                            y: {
                                ...props.chartProps.options.scales?.y,
                                ticks: {
                                    ...props.chartProps.options.scales?.y?.ticks,
                                    callback: function (value) {
                                        if (props.chartProps.data.legend.length === 0) {
                                            return value;
                                        }
                                        return formatAxis(
                                            value.toString(),
                                            props.chartProps.data.legend[0].displayType,
                                        );
                                    },
                                },
                            },
                        },
                    }}
                    data={props.chartProps.data.chartData as ChartData<'line', number[], string>}
                />
            )}
            {tooltipProps && (
                <Box
                    pad="xsmall"
                    border={{ color: 'border', size: '1px' }}
                    background={{ color: Colors.background_back, opacity: 0.95 }}
                    style={{
                        position: 'fixed',
                        top: tooltipProps.top,
                        left: tooltipProps.left,
                        // Set to none to fix flickering
                        pointerEvents: 'none',
                        backdropFilter: 'blur(5px)',
                    }}
                    round="8px"
                    gap="xxsmall"
                    animation={{ type: 'fadeIn', duration: 200 }}
                    elevation="xsmall"
                >
                    {tooltipProps.data.title && (
                        <Text size="12px" weight={'bold'}>
                            {tooltipProps.data.title}
                        </Text>
                    )}
                    {tooltipProps.data.points.map((p) => (
                        <Box key={`tip-${p.datasetIndex}`} direction="row" align="center" gap="xxsmall">
                            {props.chartProps.data.legend[p.datasetIndex].connector === undefined && (
                                <Box
                                    background={{ color: props.chartProps.data.legend[p.datasetIndex].color }}
                                    width="12px"
                                    height="12px"
                                />
                            )}
                            {props.chartProps.data.legend[p.datasetIndex].connector !== undefined && (
                                <Box
                                    border={{ color: props.chartProps.data.legend[p.datasetIndex].color, size: '2px' }}
                                    style={{ borderRadius: '50%' }}
                                    pad="2px"
                                >
                                    <ConnectorIconSmall
                                        name={props.chartProps.data.legend[p.datasetIndex].connector as ConnectorName}
                                        sizePixels="10px"
                                    />
                                </Box>
                            )}
                            <Text size="12px" style={{ textTransform: 'capitalize' }}>
                                {p.label ? `${p.label}: ` : ''}
                                {formatAxis(p.value, props.chartProps.data.legend[p.datasetIndex].displayType)}
                            </Text>
                        </Box>
                    ))}
                </Box>
            )}
        </Box>
    );
}
