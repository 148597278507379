// Copyright 2021
// ThatWorks.xyz Limited

import { Blank, IconProps } from 'grommet-icons';

export const Inbox = (props: IconProps & JSX.IntrinsicElements['svg']) => (
    <Blank {...props}>
        <svg viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
            <path
                stroke="none"
                d="M12.6451 3.05027L11.6238 1.00772C11.592 0.944034 11.5431 0.890468 11.4826 0.853036C11.422 0.815603 11.3522 0.795786 11.281 0.795807H2.08956C2.01838 0.795786 1.94859 0.815603 1.88805 0.853036C1.8275 0.890468 1.77859 0.944034 1.74679 1.00772L0.725516 3.05027C0.699187 3.10346 0.685428 3.16199 0.685303 3.22134V11.9022C0.685303 12.1392 0.779451 12.3665 0.947037 12.5341C1.11462 12.7017 1.34192 12.7958 1.57892 12.7958H11.7917C12.0287 12.7958 12.256 12.7017 12.4236 12.5341C12.5912 12.3665 12.6853 12.1392 12.6853 11.9022V3.22134C12.6852 3.16199 12.6714 3.10346 12.6451 3.05027ZM2.32637 1.56176H11.0442L11.6825 2.83836H1.68807L2.32637 1.56176ZM11.7917 12.0298H1.57892C1.54506 12.0298 1.51259 12.0164 1.48865 11.9925C1.46471 11.9685 1.45126 11.936 1.45126 11.9022V3.60432H11.9193V11.9022C11.9193 11.936 11.9059 11.9685 11.882 11.9925C11.858 12.0164 11.8255 12.0298 11.7917 12.0298ZM8.99849 8.05708C9.07021 8.12889 9.1105 8.22623 9.1105 8.32772C9.1105 8.42921 9.07021 8.52655 8.99849 8.59836L6.95594 10.6409C6.88413 10.7126 6.78679 10.7529 6.6853 10.7529C6.58381 10.7529 6.48647 10.7126 6.41466 10.6409L4.37211 8.59836C4.30446 8.52576 4.26763 8.42974 4.26938 8.33052C4.27113 8.2313 4.31133 8.13664 4.3815 8.06647C4.45166 7.9963 4.54633 7.95611 4.64555 7.95436C4.74476 7.95261 4.84079 7.98943 4.91339 8.05708L6.30232 9.44538V5.26389C6.30232 5.16232 6.34267 5.06491 6.4145 4.99309C6.48632 4.92126 6.58373 4.88091 6.6853 4.88091C6.78687 4.88091 6.88429 4.92126 6.95611 4.99309C7.02793 5.06491 7.06828 5.16232 7.06828 5.26389V9.44538L8.45722 8.05708C8.52903 7.98536 8.62637 7.94508 8.72786 7.94508C8.82935 7.94508 8.92669 7.98536 8.99849 8.05708Z"
            />
        </svg>
    </Blank>
);
