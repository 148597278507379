// Copyright 2021
// ThatWorks.xyz Limited

export enum CustomTokenClaimKeys {
    Roles = 'roles',
    AppMetadata = 'app_metadata',
    UserMetadata = 'user_metadata',
    EmailDomain = 'email_domain',
    OrganizationDisplayName = 'organization_display_name',
    OrganizationId = 'organization_id',
}

export enum TokenClaimRoles {
    TwEmployee = 'tw_employee',
}

export interface TokenClaimUserMetadata {
    signupNux?: boolean;
    summaryDuration?: { days: number };
}

export function parseCustomTokenClaim<T>(payload: Record<string, T>, key: CustomTokenClaimKeys): T | undefined {
    const namespace = 'https://thatworks.xyz';
    const val = payload[`${namespace}/${key}`];
    return val;
}
