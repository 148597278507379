// Copyright 2021
// ThatWorks.xyz Limited

import { Box, BoxProps, Button, Spinner, Text, TextProps } from 'grommet';
import { ColorType } from 'grommet/utils';
import { ReactNode, useCallback, useState } from 'react';
import { getThemeColors } from '../theme';
import { tooltipProps } from './Tooltip';

export default function CtaButtonSpinner(props: {
    label: string;
    disabled?: boolean;
    onClick?: () => Promise<void>;
    showSpinner?: boolean;
    labelWhileSpinning?: string;
    notPrimary?: boolean;
    size?: string;
    plain?: boolean;
    color?: ColorType;
    tooltip?: string;
}) {
    const [submitting, setSubmitting] = useState(false);

    return (
        <Button
            plain={props.plain}
            label={submitting ? props.labelWhileSpinning || props.label : props.label}
            type="submit"
            color={props.color}
            primary={!props.notPrimary}
            disabled={props.disabled || submitting || props.showSpinner}
            icon={submitting || props.showSpinner ? <Spinner size={props.size} /> : undefined}
            size={props.size}
            tip={props.tooltip ? tooltipProps(props.tooltip) : undefined}
            onClick={async () => {
                if (props.onClick) {
                    setSubmitting(true);
                    await props.onClick();
                    setSubmitting(false);
                }
            }}
        />
    );
}

export function CtaButtonSpinnerV2(props: {
    label: string;
    disabled?: boolean;
    onClick?: () => Promise<void>;
    color?: ColorType;
    tooltip?: string;
    style?: React.CSSProperties;
    notPrimary?: boolean;
    truncateText?: boolean;
}) {
    const [submitting, setSubmitting] = useState(false);

    return (
        <Button
            label={
                !submitting ? (
                    <Text size="16px" truncate={props.truncateText}>
                        {props.label}
                    </Text>
                ) : undefined
            }
            type="submit"
            primary={!props.notPrimary}
            color={props.color}
            disabled={props.disabled || submitting}
            icon={submitting ? <Spinner width="16px" height="16px" /> : undefined}
            style={{
                padding: '2px',
                display: 'flex',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                ...props.style,
            }}
            tip={props.tooltip ? tooltipProps(props.tooltip) : undefined}
            onClick={async () => {
                if (props.onClick) {
                    setSubmitting(true);
                    await props.onClick();
                    setSubmitting(false);
                }
            }}
        />
    );
}

export interface CtaTextButtonSpinnerProps {
    label: string;
    icon?: ReactNode;
    onClick?: () => Promise<void>;
    onClickSyncNoSpinner?: () => void;
    textSize?: string;
    spinnerSize?: string;
    spinnerColor?: ColorType;
    disabled?: boolean;
    hideLabelWhileSpinning?: boolean;
    getBoxProps?: (hover: boolean, toggled?: boolean) => BoxProps;
    getTextProps?: (hover: boolean, toggled?: boolean) => TextProps;
    toggled?: boolean;
    showSpinner?: boolean;
}

export function CtaTextButtonSpinner(props: CtaTextButtonSpinnerProps) {
    const [submitting, setSubmitting] = useState(false);
    const _getBoxProps = props.getBoxProps;
    const boxProps = useCallback(
        (hover: boolean, toggled?: boolean) => {
            return _getBoxProps ? _getBoxProps(hover, toggled) : {};
        },
        [_getBoxProps],
    );
    const _getTextProps = props.getTextProps;
    const textProps = useCallback(
        (hover: boolean, toggled?: boolean) => {
            return _getTextProps ? _getTextProps(hover, toggled) : {};
        },
        [_getTextProps],
    );

    return (
        <Button
            disabled={props.disabled || submitting || props.showSpinner}
            onClick={() => {
                // WHAT IS HAPPENING HERE?!?
                // html2image seems to run synchronously on mac/safari (not tested ios)
                // which when called in an async onClick completely locks up the ui
                // So here we have two options with no use of async
                if (props.onClickSyncNoSpinner) {
                    props.onClickSyncNoSpinner();
                } else if (props.onClick) {
                    setSubmitting(true);
                    props
                        .onClick()
                        .then(() => setSubmitting(false))
                        .catch(() => setSubmitting(false));
                }
            }}
            plain
        >
            {({ hover, disabled }) => (
                <Box direction="row" gap="xxsmall" align="center" {...boxProps(hover && !disabled, props.toggled)}>
                    {!submitting && !props.showSpinner && props.icon}
                    {(submitting || props.showSpinner) && (
                        <Spinner
                            size={props.spinnerSize}
                            color={props.spinnerColor || (submitting || props.showSpinner ? '#ffffff' : undefined)}
                        />
                    )}
                    {!(props.hideLabelWhileSpinning && (submitting || props.showSpinner)) && (
                        <Text size={props.textSize} {...textProps(hover && !disabled, props.toggled)}>
                            {props.label}
                        </Text>
                    )}
                </Box>
            )}
        </Button>
    );
}

export function CtaColoredButtonSpinner(props: {
    label: string;
    onClick?: () => Promise<void>;
    color?: string;
    textColor?: string;
    spinnerColor?: ColorType;
    disabled?: boolean;
}) {
    const [submitting, setSubmitting] = useState(false);

    return (
        <Button
            disabled={props.disabled || submitting}
            onClick={async () => {
                if (props.onClick) {
                    setSubmitting(true);
                    await props.onClick();
                    setSubmitting(false);
                }
            }}
            plain
        >
            {({ hover, disabled }) => (
                <Box
                    direction="row"
                    gap="xxsmall"
                    align="center"
                    background={props.color}
                    round="8px"
                    pad={{ horizontal: 'xsmall', vertical: 'xxsmall' }}
                    border={{ size: '2px', color: props.color }}
                    style={{
                        boxShadow: hover
                            ? `0px 0px 0px 2px ${props.color ? getThemeColors()[props.color] : props.color}`
                            : undefined,
                    }}
                >
                    {submitting && <Spinner color={props.spinnerColor} size="xsmall" />}
                    <Text color={props.textColor} size="16px">
                        {props.label}
                    </Text>
                </Box>
            )}
        </Button>
    );
}
