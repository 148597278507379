// Copyright 2021
// ThatWorks.xyz Limited

import { Heading } from 'grommet';
import { ColorType, GridAreaType } from 'grommet/utils';
import React from 'react';
import { FontFamily } from '../theme';

export function TwHeading(props: {
    children: React.ReactNode;
    light?: boolean;
    level?: 2 | 1 | '1' | '2' | '3' | '4' | '5' | '6' | 3 | 4 | 5 | 6 | undefined;
    color?: ColorType;
    callout?: boolean;
    size?: 'small' | 'medium' | 'large' | 'xlarge' | string;
    maxWidth?: string | number;
    margin?: string | undefined;
    style?: React.CSSProperties;
    gridArea?: GridAreaType | undefined;
}) {
    return (
        <Heading
            size={props.size}
            style={{
                margin: props.margin || '0px 0px 0px 0px',
                fontWeight: props.light ? 'lighter' : 'medium',
                fontFamily: props.callout ? FontFamily.Callout : undefined,
                fontSize: props.callout ? '2em' : undefined,
                maxWidth: props.maxWidth,
                ...props.style,
            }}
            level={props.level}
            color={props.color}
            gridArea={props.gridArea}
        >
            {props.children}
        </Heading>
    );
}
