// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box, Button, TipProps } from 'grommet';

export function WorkspaceHeaderIcon(props: {
    icon: JSX.Element;
    onClick?: () => void;
    href?: string;
    tip?: string | TipProps;
    disabled?: boolean;
}): JSX.Element {
    return (
        <Button
            onClick={() => {
                if (props.onClick) {
                    props.onClick();
                }
            }}
            href={props.href}
            plain
            tip={props.tip}
            disabled={props.disabled}
        >
            {({ hover, disabled }) => (
                <Box
                    pad={{ horizontal: '6px', vertical: '6px' }}
                    round="8px"
                    direction="row"
                    align="center"
                    border={{ size: '2px', color: Colors.brand }}
                >
                    {props.icon}
                </Box>
            )}
        </Button>
    );
}
