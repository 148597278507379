// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { ConnectorName, getFormattedConnectorName } from '@thatworks/connector-api';
import { Anchor, Box, Text } from 'grommet';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { MetricBox as MetricBoxQl, StringType } from '../__generated__/graphql';
import { ConnectorIconSmall } from './ConnectorIcon';

export function MetricBoxV2(props: {
    metricBox: MetricBoxQl;
    style?: React.CSSProperties;
    mode: 'inspector' | 'summary';
    selectedIndicators?: string[];
    onToggle?: () => void;
}): JSX.Element {
    const borderColor = useMemo(() => {
        if (props.mode === 'inspector') {
            if (
                props.selectedIndicators === undefined ||
                props.selectedIndicators.includes(props.metricBox.identifier)
            ) {
                return Colors.accent_3;
            }
            return Colors.border_dark;
        }
        return Colors.light_6;
    }, [props.metricBox.identifier, props.mode, props.selectedIndicators]);

    return (
        <Box
            style={{ cursor: props.mode === 'inspector' ? 'pointer' : 'default', ...props.style }}
            border={{ color: borderColor }}
            round={'15px'}
            background={{ color: Colors.light_6 }}
            elevation="xsmall"
            onClick={props.onToggle}
            focusIndicator={false}
        >
            <Box
                direction="row"
                gap="xxsmall"
                pad={{ left: 'xsmall', bottom: 'xxsmall', top: 'xxsmall', right: 'xsmall' }}
                border={{ side: 'bottom', color: 'border' }}
                align="center"
                height={{ min: '15px' }}
            >
                <Box>
                    {props.metricBox.connector && (
                        <ConnectorIconSmall
                            name={props.metricBox.connector as ConnectorName}
                            sizePixels="10px"
                            alignSelf="center"
                        />
                    )}
                </Box>
                <Text
                    size="10px"
                    weight="bolder"
                    color="dark-6"
                    style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                >
                    {props.metricBox.parents.length === 0 && props.metricBox.connector !== undefined
                        ? `${getFormattedConnectorName(props.metricBox.connector as ConnectorName)}`
                        : ''}
                    {props.metricBox.parents.slice(0, 2).map((p, i, arr) => (
                        <span key={`card-parent-${i}`}>
                            <span style={{ textTransform: 'capitalize' }}>{p.connectorObjectType}&nbsp;•&nbsp;</span>
                            <Anchor
                                href={p.url || undefined}
                                target="_blank"
                                color="dark-6"
                                style={{ borderWidth: '0px' }}
                            >
                                {p.name}
                            </Anchor>
                            <span>{i < arr.length - 1 ? ' |' : ''}&nbsp;</span>
                        </span>
                    ))}
                </Text>
            </Box>

            <Box align="center" justify="center" fill gap="xxsmall" pad="xsmall">
                <Box align="center" justify="center">
                    <Text size="large" weight="bold">
                        {props.metricBox.valueFormatted}
                    </Text>
                    <Text
                        textAlign="center"
                        style={{ textOverflow: 'ellipsis', width: 'inherit', overflow: 'hidden', whiteSpace: 'nowrap' }}
                    >
                        {props.metricBox.title}
                    </Text>
                    {props.metricBox.xAxisValue && props.metricBox.xAxisValue.type === StringType.DateIso && (
                        <Box>
                            <Text color={Colors.dark_4} size="10px">
                                On {DateTime.fromISO(props.metricBox.xAxisValue.value).toLocaleString()}
                            </Text>
                        </Box>
                    )}
                    {!props.metricBox.xAxisValue && props.metricBox.deltas && (
                        <Box gap="2px">
                            {props.metricBox.deltas.map((d, di) => (
                                <Box
                                    background={{ color: d.color }}
                                    pad={{ horizontal: '6px', vertical: '2px' }}
                                    round="5px"
                                    key={`${props.metricBox.itemUuid}-metric-${di}`}
                                >
                                    <Text color={Colors.dark_4} size="10px">
                                        {d.value} {d.label}
                                    </Text>
                                </Box>
                            ))}
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
}
