// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box, Select, Text } from 'grommet';
import { FormDown } from 'grommet-icons';
import { useMemo } from 'react';
import { AutomationScheduleEvery } from '../../../../../__generated__/graphql';
import { getLabelForSchedule } from '../../../../../components/schedule-label';
import {
    DaysOfWeek,
    getDaysOfWeekFromIsoDay,
    getIsoWeekday,
    HalfHourTimeInterval,
    scheduleToTime,
    TimeInSchedule,
    to24Hour,
} from '../helpers/automation-types';

function ScheduleTimeSelection(props: {
    time: TimeInSchedule;
    onTimeChange: (v: TimeInSchedule) => void;
}): JSX.Element {
    return (
        <Box direction="row" gap="xsmall" align="baseline">
            <Text>at</Text>
            <Box round="10px" border={{ size: '1px', color: Colors.accent_3 }}>
                <Select
                    plain
                    options={Object.values(HalfHourTimeInterval)}
                    value={scheduleToTime(props.time)}
                    onChange={({ option }) => {
                        const { hour, minute } = to24Hour(option);
                        props.onTimeChange({
                            hour,
                            minute,
                            ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                        });
                    }}
                    style={{ borderRadius: '10px', padding: '5px 0 5px 16px' }}
                    width={'100px'}
                    icon={<FormDown size="16px" />}
                />
            </Box>
        </Box>
    );
}

function ScheduleDayOfWeekSelection(props: {
    time: TimeInSchedule;
    onTimeChange: (v: TimeInSchedule) => void;
    isoDayOfWeek: number;
    onDayChange: (v: number) => void;
}): JSX.Element {
    return (
        <Box gap="xsmall" direction="row" align="baseline">
            <Box direction="row" gap="xsmall" align="baseline">
                <Text>on</Text>
                <Box round="10px" border={{ size: '1px', color: Colors.accent_3 }}>
                    <Select
                        plain
                        options={Object.values(DaysOfWeek)}
                        value={getDaysOfWeekFromIsoDay(props.isoDayOfWeek)}
                        onChange={({ option }) => {
                            props.onDayChange(getIsoWeekday(option));
                        }}
                        width={'105px'}
                        icon={<FormDown size="16px" />}
                        style={{ borderRadius: '10px', padding: '5px 0 5px 16px' }}
                    />
                </Box>
            </Box>
            <ScheduleTimeSelection time={props.time} onTimeChange={props.onTimeChange} />
        </Box>
    );
}

export function ScheduleRow(props: {
    every: AutomationScheduleEvery;
    onEveryChange: (e: AutomationScheduleEvery) => void;
    time: TimeInSchedule;
    onTimeChange: (v: TimeInSchedule) => void;
    isoDayOfWeek: number;
    onDayChange: (v: number) => void;
}): JSX.Element {
    const textDescription = useMemo(() => {
        const res = `Delivers a summary`;
        switch (props.every) {
            case AutomationScheduleEvery.Day:
                return `${res} everyday with data from the previous day.`;
            case AutomationScheduleEvery.Week:
                return `${res} once a week with data from the previous week.`;
            case AutomationScheduleEvery.WeekDay:
                return `${res} every weekday with data from the previous weekday.`;
        }
    }, [props.every]);

    return (
        <Box gap="xsmall">
            <Box direction="row" align="baseline" gap="xsmall">
                <Text>Every</Text>
                <Box round="10px" border={{ size: '1px', color: Colors.accent_3 }}>
                    <Select
                        plain
                        options={Object.values(AutomationScheduleEvery).map((v) => ({
                            value: v,
                            label: getLabelForSchedule(v),
                        }))}
                        labelKey={'label'}
                        value={{ value: props.every, label: getLabelForSchedule(props.every) }}
                        onChange={({ option }) => {
                            props.onEveryChange(option.value);
                        }}
                        width={'85px'}
                        icon={<FormDown size="16px" />}
                        style={{ borderRadius: '10px', padding: '5px 0 5px 16px' }}
                    />
                </Box>
            </Box>
            {(props.every === AutomationScheduleEvery.Day || props.every === AutomationScheduleEvery.WeekDay) && (
                <ScheduleTimeSelection onTimeChange={props.onTimeChange} time={props.time} />
            )}
            {props.every === AutomationScheduleEvery.Week && (
                <ScheduleDayOfWeekSelection
                    onTimeChange={props.onTimeChange}
                    time={props.time}
                    isoDayOfWeek={props.isoDayOfWeek}
                    onDayChange={props.onDayChange}
                />
            )}
            <Text size="small">{textDescription}</Text>
        </Box>
    );
}
