// Copyright 2021
// ThatWorks.xyz Limited

import { useMutation, useQuery } from '@apollo/client';
import { Colors } from '@thatworks/colors';
import { getReadableDateTimeString } from '@thatworks/shared-frontend/date-helpers';
import { Box, DropButton, Text } from 'grommet';
import { Down, FormTrash } from 'grommet-icons';
import { useState } from 'react';
import { gql } from '../../../../__generated__';
import { WorkspacePostsQuery, WorkspacePostUserAbilities } from '../../../../__generated__/graphql';
import DropdownButton from '../../../../components/DropdownButton';
import { useTelemetryContext } from '../../../../components/TelemetryContext';
import { useUserStateContext } from '../../../../components/UserContext';
import { PostCommentInput } from './PostCommentInput';

function CommentPostDropButton(props: {
    userAbilities: WorkspacePostUserAbilities[];
    onDelete: () => Promise<void>;
}): JSX.Element {
    const [open, setOpen] = useState(false);
    return (
        <DropButton
            icon={
                <Down
                    size="8px"
                    onClick={() => {
                        setOpen(true);
                    }}
                />
            }
            style={{ padding: '0px' }}
            dropContent={
                <Box background="transparent" direction="row">
                    <Box elevation="medium" background="brand" round="5px" pad={{ top: '10px', bottom: '10px' }}>
                        {props.userAbilities.includes(WorkspacePostUserAbilities.CanDelete) && (
                            <DropdownButton
                                label="Delete"
                                icon={FormTrash}
                                onClick={async () => {
                                    await props.onDelete();
                                    setOpen(false);
                                }}
                            />
                        )}
                    </Box>
                </Box>
            }
            dropAlign={{ left: 'right', top: 'bottom' }}
            dropProps={{
                background: 'transparent',
                elevation: 'none',
                onEsc: () => {
                    setOpen(false);
                },
                onClickOutside: () => {
                    setOpen(false);
                },
            }}
            open={open}
        />
    );
}

const DELETE_COMMENT = gql(/* GraphQL */ `
    mutation WorkspacePostCommentDelete($commentId: ID!) {
        workspacePostCommentDelete(commentId: $commentId)
    }
`);

export function PostComment(props: {
    commentId: string;
    comment: string;
    author: string;
    date: Date;
    userAbilities: WorkspacePostUserAbilities[];
    onCommentDelete: () => Promise<void>;
}): JSX.Element {
    const { postErrorMessage } = useUserStateContext();
    const { logger } = useTelemetryContext();
    const [deleteComment] = useMutation(DELETE_COMMENT);

    return (
        <Box
            round="10px"
            background={{ color: Colors.light_2 }}
            pad={{ horizontal: 'xsmall', vertical: 'xxsmall' }}
            width={{ width: 'max-content', max: '100%' }}
            gap="xxsmall"
        >
            <Box direction="row" align="center" gap="xxsmall">
                <Text size="12px" weight={'bold'} color={Colors.dark_4}>
                    {props.author}
                    <span style={{ fontWeight: 'normal' }}>&nbsp;at {getReadableDateTimeString(props.date)}</span>
                </Text>
                {props.userAbilities.includes(WorkspacePostUserAbilities.CanDelete) && (
                    <CommentPostDropButton
                        userAbilities={props.userAbilities}
                        onDelete={async () => {
                            await deleteComment({
                                variables: {
                                    commentId: props.commentId,
                                },
                                onError: (err) => {
                                    postErrorMessage({
                                        title: `Error`,
                                        shortDesc: `Failed to delete comment`,
                                    });
                                    logger.error(err.message);
                                },
                            });
                            await props.onCommentDelete();
                        }}
                    />
                )}
            </Box>
            <Text size="14px" wordBreak={'break-word'}>
                {props.comment}
            </Text>
        </Box>
    );
}

const GET_POST_COMMENTS = gql(/* GraphQL */ `
    query WorkspacePostComments($workspaceId: ID!, $postId: ID!) {
        workspacePostComments(workspaceId: $workspaceId, postId: $postId) {
            comments {
                content
                created
                creators {
                    name
                }
                id
                userAbilities
            }
            permissionError
        }
    }
`);

export function CommentSection(props: {
    workspaceId: string;
    post: WorkspacePostsQuery['workspacePosts']['posts'][number];
}): JSX.Element {
    // Initiliase
    const { postErrorMessage } = useUserStateContext();
    const { logger } = useTelemetryContext();

    // Get post comments
    const { data, refetch } = useQuery(GET_POST_COMMENTS, {
        onError: (error) => {
            postErrorMessage({ title: 'Error', shortDesc: 'Failed to get comments' });
            logger.error(error.message);
        },
        variables: {
            postId: props.post.id,
            workspaceId: props.workspaceId,
        },
    });

    // Return.
    return (
        <Box
            pad={{ vertical: 'xsmall', horizontal: 'small' }}
            background={{ color: Colors.light_2, opacity: 0.25 }}
            gap="xxsmall"
            border={{ size: '2px', color: Colors.accent_4, side: 'top' }}
            height={{ min: 'max-content', max: '20vh' }}
            overflow={{ vertical: 'auto' }}
        >
            <Box gap="xxsmall" height={{ min: 'max-content' }}>
                {data &&
                    data.workspacePostComments.comments.map((comment) => (
                        <PostComment
                            key={comment.id}
                            author={comment.creators[0].name}
                            date={new Date(comment.created)}
                            commentId={comment.id}
                            comment={comment.content}
                            userAbilities={comment.userAbilities}
                            onCommentDelete={async () => {
                                await refetch();
                            }}
                        />
                    ))}
                <PostCommentInput
                    postId={props.post.id}
                    workspaceId={props.workspaceId}
                    onNewComment={async () => {
                        await refetch();
                    }}
                />
            </Box>
        </Box>
    );
}
