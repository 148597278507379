// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box, Image, Text } from 'grommet';
import { Icon } from 'grommet-icons';
import { MarginType, WidthType } from 'grommet/utils';

export interface InspectorCardTagProps {
    name: string;
    value: string;
    color?: string;
    iconUrl?: string;
    icon?: Icon;
    iconComponent?: React.ReactNode;
    hideTooltip?: boolean;
    hideBorder?: boolean;
    dontClipText?: boolean;
    textAndIconSize?: string;
    width?: WidthType;
    style?: React.CSSProperties;
    margin?: MarginType;
}

export function InspectorCardTag(props: InspectorCardTagProps): JSX.Element {
    const textAndIconSize = props.textAndIconSize || '10px';
    return (
        <Box
            border={{ color: props.hideBorder ? undefined : props.color || 'accent-3' }}
            background={props.color ? { color: props.color, opacity: 'weak' } : undefined}
            round="4px"
            pad={{ horizontal: 'xxsmall', vertical: '2px' }}
            direction="row"
            gap="3px"
            margin={props.margin}
            width={props.width || { min: 'unset' }}
            style={props.style}
        >
            {props.iconUrl ? (
                <Box justify="center">
                    <Image src={props.iconUrl} width={textAndIconSize} height={textAndIconSize} />
                </Box>
            ) : props.iconComponent ? (
                props.iconComponent
            ) : props.icon ? (
                <Box justify="center">
                    <props.icon width={textAndIconSize} height={textAndIconSize} size={textAndIconSize} />
                </Box>
            ) : (
                ''
            )}
            <Text
                tip={
                    props.hideTooltip
                        ? undefined
                        : {
                              plain: true,
                              content: (
                                  <Box
                                      border={{ color: 'light-4' }}
                                      background="background-front"
                                      round="4px"
                                      pad="xxsmall"
                                  >
                                      <Text
                                          size={textAndIconSize}
                                          color="dark-4"
                                          style={{ fontWeight: 'bold', textTransform: 'capitalize' }}
                                      >
                                          {props.name ? `${props.name}: ` : ''}
                                          {props.value}
                                      </Text>
                                  </Box>
                              ),
                              dropProps: {
                                  margin: 'xsmall',
                              },
                          }
                }
                size={textAndIconSize}
                color="dark-4"
                style={{
                    overflow: props.dontClipText ? undefined : 'hidden',
                    textOverflow: props.dontClipText ? undefined : 'ellipsis',
                    maxWidth: props.dontClipText ? undefined : '100px',
                    whiteSpace: 'nowrap',
                }}
            >
                {props.value.toUpperCase()}
            </Text>
        </Box>
    );
}

export function InspectorCardTagWithSpan(props: {
    name: string;
    value: string;
    color?: string;
    hideTooltip?: boolean;
    hideBorder?: boolean;
    dontClipText?: boolean;
    textSize?: string;
}): JSX.Element {
    const textSize = props.textSize || '10px';
    return (
        <span
            style={{
                borderColor: props.hideBorder ? undefined : props.color || Colors.light_6,
                borderWidth: '1px',
                borderStyle: 'solid',
                background: props.color,
                borderRadius: '4px',
                paddingLeft: '4px',
                paddingRight: '4px',
                paddingTop: '2px',
                paddingBottom: '2px',
                alignContent: 'center',
                justifyContent: 'center',
                display: 'inline-flex',
                width: 'max-content',
            }}
        >
            <Text
                tip={
                    props.hideTooltip
                        ? undefined
                        : {
                              plain: true,
                              content: (
                                  <Box
                                      border={{ color: 'light-4' }}
                                      background="background-front"
                                      round="4px"
                                      pad="xxsmall"
                                  >
                                      <Text
                                          size={textSize}
                                          color="dark-4"
                                          style={{ fontWeight: 'bold', textTransform: 'capitalize' }}
                                      >
                                          {props.name ? `${props.name}: ` : ''}
                                          {props.value}
                                      </Text>
                                  </Box>
                              ),
                              dropProps: {
                                  margin: 'xsmall',
                              },
                          }
                }
                size={textSize}
                color={Colors.dark_4}
                style={{
                    fontWeight: 'bold',
                    overflow: props.dontClipText ? undefined : 'hidden',
                    textOverflow: props.dontClipText ? undefined : 'ellipsis',
                    maxWidth: props.dontClipText ? undefined : '100px',
                    whiteSpace: 'nowrap',
                }}
            >
                {props.value.toUpperCase()}
            </Text>
        </span>
    );
}
