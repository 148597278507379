// Copyright 2021
// ThatWorks.xyz Limited

import { Logger } from '@thatworks/node-logger';
import axios, { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';
import xss from 'xss';

export { StatusCodes as HttpStatusCodes };

// From looking at the debug inspector because it doesn't match the object on google's repo
// https://github.com/googleapis/gaxios/blob/main/src/common.ts
interface GaxiosError extends Error {
    code?: string;
}

export class HttpStatusError extends Error {
    private _status: StatusCodes;

    constructor(status: StatusCodes, msg: string) {
        super(msg);
        Object.setPrototypeOf(this, HttpStatusError.prototype);
        this._status = status;
    }

    get status(): number {
        return this._status;
    }
}

export function getStatusFromException(err: unknown): number | string {
    const gaxiosError = err as GaxiosError;
    const notionError = err as { status: number };
    let status: number | string = 500;
    if (err instanceof HttpStatusError) {
        status = err.status;
    } else if (axios.isAxiosError(err)) {
        const axiosError = err as AxiosError;
        status = axiosError.response?.status || 500;
    } else if (notionError.status !== undefined && typeof notionError.status === 'number') {
        status = notionError.status;
        // Ensure gaxiosError is last because other objects above may have "code"
    } else if (gaxiosError && gaxiosError.code !== undefined && typeof gaxiosError.code === 'string') {
        status = gaxiosError.code;
    }
    return status;
}

export function ErrorToRes(err: unknown, log: Logger): { status: number | string; body: string } {
    const msg = xss(getExceptionMessage(err, true));
    const status = getStatusFromException(err);
    log.error(msg);
    return { status, body: msg };
}

export function ErrorToSetExpressRes(
    err: unknown,
    log: Logger,
    res: { status: (n: number) => void; send: (body: string) => void },
) {
    const { status, body } = ErrorToRes(err, log);
    res.status(Number(status));
    res.send(body);
}

export function getExceptionMessage(err: unknown, skipStack?: boolean) {
    if (err instanceof HttpStatusError) {
        const m = `${err.message} Status: ${err.status}`;
        if (skipStack) {
            return m;
        }
        return `${m} \n${err.stack}`;
    } else if (axios.isAxiosError(err)) {
        const axiosError = err as AxiosError;
        // get request url from error
        const url = axiosError.config?.url;

        // Get headers if it is a 429 error
        const retryAfterHeader = axiosError.response?.headers['Retry-After'];
        const headerString = retryAfterHeader ? ` Retry-After: ${retryAfterHeader}` : '';

        const m = `${axiosError.message}. Request: ${url}${headerString} Response: ${
            axiosError.response ? JSON.stringify(axiosError.response.data) : 'No response'
        }`;
        if (skipStack) {
            return m;
        }
        return `${m} \n${err.stack}`;
        // Ensure Error is last
    } else if (err instanceof Error) {
        if (skipStack) {
            return err.message;
        }
        return `${err.message} \n${err.stack}`;
    } else {
        return `Unknown error type: ${typeof err}`;
    }
}
