// Copyright 2021
// ThatWorks.xyz Limited

import { NodeViewComponentProps } from '@remirror/react';
import { BasePmNode, BasePmNodeAttributes } from '@thatworks/shared-frontend/prosemirror-nodes';
import { ComponentType, FunctionComponent, useCallback } from 'react';
import { DOMCompatibleAttributes, ExtensionTagType, NodeExtensionSpec, ValidOptions } from 'remirror';

export interface BaseReactNodeComponentProps<Attributes> {
    currentAttributes: Attributes;
    updateAttributes: (att: Partial<Attributes>) => void;
    nodeProps: {
        getPosition: NodeViewComponentProps['getPosition'];
        node: NodeViewComponentProps['node'];
    };
}

export abstract class BaseReactNode<
    Attributes extends BasePmNodeAttributes,
    InitOptions extends ValidOptions = {},
> extends BasePmNode<Attributes, InitOptions> {
    abstract ComponentToRender: FunctionComponent<BaseReactNodeComponentProps<Attributes>>;

    ReactComponent: ComponentType<NodeViewComponentProps> = (props) => {
        const propUpdateAttributes = props.updateAttributes;
        const updateAttributes = useCallback(
            (existingAtts: Attributes, newAtts: Partial<Attributes>) => {
                const updated = { ...existingAtts, ...newAtts };
                propUpdateAttributes(updated as any);
            },
            [propUpdateAttributes],
        );

        return this.ComponentToRender({
            currentAttributes: props.node.attrs as Attributes,
            updateAttributes: (newAtts) => updateAttributes(props.node.attrs as Attributes, newAtts),
            nodeProps: { getPosition: props.getPosition, node: props.node },
        });
    };
}

export abstract class BaseReactNodeInjected<
    Attributes extends BasePmNodeAttributes,
    InitOptions extends ValidOptions = {},
> extends BaseReactNode<Attributes, InitOptions> {
    abstract _injectedNode: BasePmNode<Attributes, InitOptions>;

    ReactComponent: ComponentType<NodeViewComponentProps> = (props) => {
        const propUpdateAttributes = props.updateAttributes;
        const updateAttributes = useCallback(
            (existingAtts: Attributes, newAtts: Partial<Attributes>) => {
                const updated = { ...existingAtts, ...newAtts };
                propUpdateAttributes(updated as any);
            },
            [propUpdateAttributes],
        );

        return this.ComponentToRender({
            currentAttributes: props.node.attrs as Attributes,
            updateAttributes: (newAtts) => updateAttributes(props.node.attrs as Attributes, newAtts),
            nodeProps: { getPosition: props.getPosition, node: props.node },
        });
    };

    get name() {
        return this._injectedNode.name;
    }

    getDefaultAttributes(): Attributes {
        return this._injectedNode.getDefaultAttributes();
    }

    get content(): string | undefined {
        return this._injectedNode.content;
    }

    nodeSpecOverride?(): NodeExtensionSpec | undefined {
        return this._injectedNode.nodeSpecOverride?.();
    }

    createNodeSpecBackendOverride?(): NodeExtensionSpec | undefined {
        return this._injectedNode.createNodeSpecBackendOverride?.();
    }

    createNodeSpec(): NodeExtensionSpec {
        return this._injectedNode.createNodeSpec();
    }

    attributesToDom(att: Attributes): DOMCompatibleAttributes {
        return this._injectedNode.attributesToDom(att);
    }

    domToAttributes(node: HTMLAnchorElement): Attributes | null {
        return this._injectedNode.domToAttributes(node);
    }

    createTags(): ExtensionTagType[] {
        return this._injectedNode.createTags();
    }
}
