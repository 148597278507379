// Copyright 2021
// ThatWorks.xyz Limited

import Agent, { HttpsAgent } from 'agentkeepalive';
import axiosLib, { AxiosInstance } from 'axios';
import axiosRetry, { IAxiosRetryConfig } from 'axios-retry';

export class AxiosKeepAlive {
    // eslint-disable-next-line no-use-before-define
    private static _instance: AxiosKeepAlive;
    private _httpsAgent: HttpsAgent | undefined;
    private _httpAgent: Agent | undefined;
    private _default: AxiosInstance;

    private constructor() {
        // THIS HAS BEEN DISABLED AS IT HAD NO IMPACT ON TWD-473.
        // KEEPING IT AROUND IN CASE IT IS NEEDED AGAIN

        // Per https://learn.microsoft.com/en-us/azure/app-service/app-service-web-nodejs-best-practices-and-troubleshoot-guide#my-node-application-is-making-excessive-outbound-calls
        // the recommendation is to keep it to 32 and 10
        // "The recommendation on Azure App Service is to set the agentKeepAlive maxSockets value to a total of (4 instances of node.exe * 32 maxSockets/instance) 128 sockets per VM."

        // However, docker+linux seems to run only one instance of node
        // const numNodeInstances = 1;
        // const maxSockets = 128 / numNodeInstances;
        // const maxFreeSockets = 10;
        // const timeout = 60000; // 60s
        // const freeSocketTimeout = 30000; // 30s

        // this._httpsAgent = new HttpsAgent({
        //     maxSockets,
        //     maxFreeSockets,
        //     timeout,
        //     freeSocketTimeout,
        // });
        // this._httpAgent = new Agent({
        //     maxSockets,
        //     maxFreeSockets,
        //     timeout,
        //     freeSocketTimeout,
        // });
        this._default = this.createAxios();
    }

    public static get Instance() {
        return this._instance || (this._instance = new AxiosKeepAlive());
    }

    public get httpAgent() {
        return this._httpAgent;
    }

    public get httpsAgent() {
        return this._httpsAgent;
    }

    public createAxios() {
        return axiosLib.create({ httpAgent: this._httpAgent, httpsAgent: this._httpsAgent });
    }

    public get axios() {
        return this._default;
    }
}

export const defaultAxiosRetryConfig: IAxiosRetryConfig = { retries: 3, retryDelay: axiosRetry.exponentialDelay };

export function createAxiosWithRetryInstance() {
    const axiosWithRetry = AxiosKeepAlive.Instance.createAxios();
    axiosRetry(axiosWithRetry, defaultAxiosRetryConfig);
    return axiosWithRetry;
}

const axiosWithRetry = createAxiosWithRetryInstance();
export default axiosWithRetry;
