// Copyright 2021
// ThatWorks.xyz Limited

import { useMutation } from '@apollo/client';
import { Colors } from '@thatworks/colors';
import { Box, Spinner } from 'grommet';
import { Send } from 'grommet-icons';
import { useCallback, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';
import { gql } from '../../../../__generated__';
import { IconButtonV2 } from '../../../../components/IconButton';
import { useTelemetryContext } from '../../../../components/TelemetryContext';
import { useUserStateContext } from '../../../../components/UserContext';
import { FontFamily } from '../../../../theme';

const StyledTextArea = styled(TextareaAutosize)`
    font-family: ${FontFamily.Standard};
    border: none;
    resize: none;
    font-size: 14px;
    &:focus-visible {
        outline: none;
    }
    ::placeholder {
        color: ${Colors.light_6};
    }
    width: inherit;
`;

const POST_COMMENT = gql(/* GraphQL */ `
    mutation WorkspacePostCommentCreate($workspaceId: ID!, $postId: ID!, $content: String!) {
        workspacePostCommentCreate(workspaceId: $workspaceId, postId: $postId, content: $content)
    }
`);

export function PostCommentInput(props: {
    postId: string;
    workspaceId: string;
    onNewComment: () => Promise<void>;
}): JSX.Element {
    const [comment, setComment] = useState('');
    const [postComment] = useMutation(POST_COMMENT);
    const { postErrorMessage } = useUserStateContext();
    const { logger } = useTelemetryContext();
    const onNewComment = props.onNewComment;
    const [posting, setPosting] = useState(false);

    const postCommentWrapper = useCallback(async () => {
        if (!comment) {
            return;
        }
        setPosting(true);
        await postComment({
            variables: {
                content: comment,
                postId: props.postId,
                workspaceId: props.workspaceId,
            },
            onError: (err) => {
                postErrorMessage({
                    title: `Error`,
                    shortDesc: `Failed to post comment`,
                });

                logger.error(err.message);
            },
        });
        await onNewComment();
        setPosting(false);
    }, [comment, logger, postComment, postErrorMessage, onNewComment, props.postId, props.workspaceId]);

    return (
        <Box
            round="10px"
            background={{ color: Colors.background_back }}
            fill={'horizontal'}
            pad={{ horizontal: 'xsmall', vertical: 'xxsmall' }}
            border={{ color: Colors.border_light }}
            direction="row"
            justify="between"
            align="start"
        >
            <StyledTextArea
                placeholder="Write a comment.."
                content={comment}
                onChange={(event) => setComment(event.target.value)}
                onKeyDown={(event) => {
                    if (event.key === 'Enter' && !event.shiftKey) {
                        event.preventDefault();
                        try {
                            postCommentWrapper();
                        } catch (e) {}
                    }
                }}
                disabled={posting}
            />
            <Box pad={{ top: '4px' }}>
                <IconButtonV2
                    disabled={!comment}
                    icon={(hover, disabled, submitting) =>
                        submitting || posting ? (
                            <Spinner width="14px" height="14px" />
                        ) : (
                            <Send
                                size="14px"
                                color={disabled ? Colors.light_6 : hover ? Colors.brand : Colors.dark_4}
                            />
                        )
                    }
                    onClickWithSpinner={async () => {
                        await postCommentWrapper();
                    }}
                />
            </Box>
        </Box>
    );
}
