// Copyright 2021
// ThatWorks.xyz Limited

import { Box, Text } from 'grommet';
import styled from 'styled-components';
import { FontFamily } from '../theme';

export interface PlainTextInputProps {
    fontSize: string;
    placeholderColor?: string;
}

export const PlainTextInput = styled.input<PlainTextInputProps>`
    font-family: ${FontFamily.Standard};
    font-size: ${(props) => props.fontSize};
    border: none;
    &:focus-visible {
        outline: none;
    }
    ::placeholder {
        color: ${(props) => props.placeholderColor};
    }
`;

export const PlainTextArea = styled.textarea<PlainTextInputProps>`
    font-family: ${FontFamily.Standard};
    font-size: ${(props) => props.fontSize};
    border: none;
    &:focus-visible {
        outline: none;
    }
    ::placeholder {
        color: ${(props) => props.placeholderColor};
    }
`;

const HoverDiv = styled.div<{ hoverBackgroundColor: string; hoverPadding?: string }>`
    padding: ${(props) => props.hoverPadding};
    :hover {
        background-color: ${(props) => props.hoverBackgroundColor};
    }
    :focus {
        background-color: ${(props) => props.hoverBackgroundColor};
    }
`;

export function HoverPlainTextInput(props: {
    value?: string;
    inputAttr?: React.InputHTMLAttributes<any>;
    inputStyle?: React.CSSProperties;
    inputProps: PlainTextInputProps;
    hoverBgColor: string;
    style?: React.CSSProperties;
    disableEdit?: boolean;
    alwaysShowFilledBackground?: boolean;
}): JSX.Element {
    function isEmpty() {
        return !props.value || props.value.length === 0;
    }

    if (props.disableEdit) {
        return (
            <Box margin={{ vertical: '2px' }}>
                <Text
                    size={props.inputProps.fontSize}
                    style={{
                        fontFamily: FontFamily.Standard,
                        ...props.inputStyle,
                    }}
                >
                    {props.value}
                </Text>
            </Box>
        );
    }

    return (
        <HoverDiv
            hoverBackgroundColor={props.hoverBgColor}
            hoverPadding="2px"
            style={{
                borderRadius: '4px',
                backgroundColor: isEmpty() || props.alwaysShowFilledBackground ? props.hoverBgColor : undefined,
                ...props.style,
            }}
        >
            <PlainTextInput
                value={props.value}
                {...props.inputProps}
                {...props.inputAttr}
                style={{ padding: '0px', ...props.inputStyle }}
            />
        </HoverDiv>
    );
}

export function HoverPlainTextArea(props: {
    value?: string;
    inputAttr?: React.TextareaHTMLAttributes<any>;
    inputStyle?: React.CSSProperties;
    inputProps: PlainTextInputProps;
    hoverBgColor: string;
}): JSX.Element {
    return (
        <HoverDiv
            hoverBackgroundColor={props.hoverBgColor}
            style={{
                padding: '2px',
                borderRadius: '4px',
                backgroundColor: !props.value || props.value.length === 0 ? props.hoverBgColor : undefined,
            }}
        >
            <PlainTextArea
                value={props.value}
                {...props.inputProps}
                {...props.inputAttr}
                style={{ ...props.inputStyle }}
            />
        </HoverDiv>
    );
}
