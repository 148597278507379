// Copyright 2021
// ThatWorks.xyz Limited

export enum Environment {
    Development = 'development',
    Staging = 'staging',
    Production = 'production',
}

const envMap = new Map<string, Environment>(Array.from(Object.values(Environment)).map((v) => [v.toString(), v]));

export function getEnvironment(): Environment {
    const env = process.env.REACT_APP_WEBPACK_DEPLOYMENT;
    if (!env) {
        throw new Error(`REACT_APP_WEBPACK_DEPLOYMENT is not specified`);
    }
    const environment = envMap.get(env);
    if (!environment) {
        throw new Error(`Unsupported environment (${env}) detected.`);
    }
    return environment;
}
