// Copyright 2021
// ThatWorks.xyz Limited

import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { BasicPage3, BasicPage3NavFunction } from '../../../../components/BasicPage3';
import { withAuth } from '../../../../components/withAuth';

function WorkspacesIndex(props: { onNav: BasicPage3NavFunction }): JSX.Element {
    const [title, setTitle] = useState<string>('Topics');
    return (
        <BasicPage3 browserPageTitle={title} onNav={props.onNav}>
            <Outlet context={setTitle} />
        </BasicPage3>
    );
}

export default withAuth(WorkspacesIndex);
