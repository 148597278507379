// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { FormTrash } from 'grommet-icons';
import { IconButtonV2 } from '../../../../../../components/IconButton';

export function RemoveFilterButton(props: { onDelete: () => void }): JSX.Element {
    return (
        <IconButtonV2
            icon={(hover, disabled) => <FormTrash size="16px" color={hover && !disabled ? Colors.brand : undefined} />}
            tooltip={'Remove filter'}
            onClick={props.onDelete}
        />
    );
}
