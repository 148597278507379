// Copyright 2021
// ThatWorks.xyz Limited

import { ConnectorName, ConnectorNameWithUserId } from '@thatworks/connector-api';
import { CustomFilePickerIds } from '@thatworks/shared-frontend/collections';
import { ConnectorToken } from '@thatworks/shared-frontend/connectors';
import Api from '../Api';
import { GdriveMime, gdrivePicker } from './gdrive-picker';
import { PickerResult } from './picker-result';

export async function picker(
    idToken: string,
    connector: ConnectorNameWithUserId,
    filePickerId: CustomFilePickerIds,
    onException: (error: unknown) => void,
): Promise<PickerResult[]> {
    try {
        const r = await Api.get<ConnectorToken>([Api.routes.CONNECTORS, 'access-token'], idToken, {
            params: new URLSearchParams({ connector: connector.name, connectorUserId: connector.connectorUserId }),
        });

        if (connector.name === ConnectorName.GOOGLE_DRIVE && filePickerId === CustomFilePickerIds.GoogleDriveAllFiles) {
            return gdrivePicker(r.connectorUserId, r.accessToken, {
                mimeTypes: [GdriveMime.DOCS, GdriveMime.SHEET, GdriveMime.SLIDES],
                multiSelect: true,
                showFolders: true,
            });
        } else if (
            connector.name === ConnectorName.GOOGLE_DRIVE &&
            filePickerId === CustomFilePickerIds.GoogleSheetCustomization
        ) {
            return gdrivePicker(r.connectorUserId, r.accessToken, {
                mimeTypes: [GdriveMime.SHEET],
                multiSelect: false,
                showFolders: false,
            });
        } else {
            throw new Error(`Picker not supported for ${connector} and id ${filePickerId}`);
        }
    } catch (error) {
        onException(error);
        return [];
    }
}
