// Copyright 2021
// ThatWorks.xyz Limited

import { Pages } from '@thatworks/shared-frontend/pages';
import { Outlet, Route, Routes } from 'react-router-dom';
import { GenericBrandPage } from '../../components/GenericBrandPage';
import { ParamKey } from '../../shared/param-keys';
import { PublicLinkOutlet } from './components/PublicLinkOutlet';

function Page(): JSX.Element {
    return (
        <GenericBrandPage browserPageTitle="That Works" fill innerWidth={{ width: '100%', max: '900px' }}>
            <Outlet />
        </GenericBrandPage>
    );
}

export default function Public(): JSX.Element {
    return (
        <Routes>
            <Route path={`/${Pages.public.subs.post}`} element={<Page />}>
                <Route path={`:${ParamKey.PublicPostUuid}/*`} element={<PublicLinkOutlet type="post" />} />
            </Route>
            <Route path={`/${Pages.public.subs.token}`} element={<Page />}>
                <Route path={`:${ParamKey.SlackTokenId}/*`} element={<PublicLinkOutlet type="token" />} />
            </Route>
            <Route path="/" element={<Page />} />
        </Routes>
    );
}
