// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Text } from 'grommet';
import { AddCircle } from 'grommet-icons';
import { IconButtonV2 } from '../../../../../../components/IconButton';
import { FontFamily } from '../../../../../../theme';
import { PropertyFilterGroup } from './helpers';

export function AddFilterButton(props: {
    onChange: (filters: PropertyFilterGroup) => void;
    propertyFilterGroup: PropertyFilterGroup;
}): JSX.Element {
    return (
        <IconButtonV2
            icon={(hover, disabled) => <AddCircle size="16px" color={hover ? Colors.accent_3 : Colors.dark_2} />}
            label={() => (
                <Text size="14px" style={{ fontFamily: FontFamily.Mono }}>
                    Add filter
                </Text>
            )}
            tooltip={'Add filter'}
            reverse
            onClick={() => {
                const group = structuredClone(props.propertyFilterGroup);
                group.propertyFilters.push({
                    property: undefined,
                    operator: undefined,
                    value: '',
                });
                props.onChange(group);
            }}
        />
    );
}
