// Copyright 2021
// ThatWorks.xyz Limited

import { AutomationScheduleEvery } from '../__generated__/graphql';

export function getLabelForSchedule(schedule: AutomationScheduleEvery): string {
    switch (schedule) {
        case AutomationScheduleEvery.Day:
            return 'Day';
        case AutomationScheduleEvery.Week:
            return 'Week';
        case AutomationScheduleEvery.WeekDay:
            return 'Weekday';
    }
}
