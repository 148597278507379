// Copyright 2021
// ThatWorks.xyz Limited

import {
    ActivityItemFiltersInput,
    ActorType,
    ChangeActionType,
    ChangeType,
    GroupSettingsInput,
    ItemSort,
    ItemType,
    StatusCategory,
} from '../../../../../__generated__/graphql';
import { Operator, Property, PropertyFilterGroup } from '../filters/filter-toolbar-button/helpers';
import { DatePreset } from '../filters/timeline-date-selection';

export enum PresetFilter {
    Updates = 'updates',
    UpdatesByOthers = 'updates_by_others',
    WorkCompleted = 'work_completed',
    Created = 'created',
    InProgress = 'in_progress',
    DueThisWeek = 'due_this_week',
    ToDoWithUpdates = 'to_do_with_updates',
    AllToDo = 'all_to_do',
    OverdueDueSoon = 'overdue_due_soon',
    HighPriority = 'high_priority',
    ItemsNoChanges = 'items_no_changes',
    ItemsBlocked = 'items_blocked',
    Indicators = 'indicators',
    Charts = 'charts',
    // MilestonesCompleted = 'milestones_completed',
}

export function getPresetFromString(p: string | null | undefined): PresetFilter | undefined {
    // Get preset values
    const presetValues: string[] = Object.values(PresetFilter);

    // Check if the values include the received string
    if (p && presetValues.includes(p)) {
        // Return the string as a preset filter
        return p as PresetFilter;
    }
}

export function getPresetTitle(p: PresetFilter): string {
    switch (p) {
        case PresetFilter.WorkCompleted:
            return 'Work Completed';
        case PresetFilter.InProgress:
            return 'In Progress';
        case PresetFilter.Created:
            return 'Created';
        case PresetFilter.ToDoWithUpdates:
            return 'To Do With Updates';
        case PresetFilter.Indicators:
            return 'Indicators';
        case PresetFilter.OverdueDueSoon:
            return 'Overdue and due soon';
        case PresetFilter.Charts:
            return 'Charts';
        case PresetFilter.Updates:
            return 'Updates';
        case PresetFilter.UpdatesByOthers:
            return 'Updates by others';
        case PresetFilter.HighPriority:
            return 'High Priority';
        case PresetFilter.AllToDo:
            return 'All To Do';
        case PresetFilter.ItemsNoChanges:
            return 'Items with no changes';
        case PresetFilter.ItemsBlocked:
            return 'Items blocked';
        case PresetFilter.DueThisWeek:
            return 'Due This Week';
        // case PresetFilter.MilestonesCompleted:
        //     return 'Milestones Completed';
    }
}

export function getPresetDescription(p: PresetFilter): string {
    switch (p) {
        case PresetFilter.WorkCompleted:
            return 'Tasks, docs, PRs changed to done status';
        case PresetFilter.InProgress:
            return 'Tasks or docs with in progress status';
        case PresetFilter.Indicators:
            return 'Visualize data, work, and execution';
        case PresetFilter.OverdueDueSoon:
            return 'Anything that is overdue or due soon';
        case PresetFilter.Charts:
            return 'Visualize work and execution';
        case PresetFilter.Updates:
            return 'All and any updates';
        case PresetFilter.UpdatesByOthers:
            return 'Any items updated by others and not you';
        case PresetFilter.Created:
            return 'Items created within the time period';
        case PresetFilter.HighPriority:
            return 'Items that are high in priority';
        case PresetFilter.ToDoWithUpdates:
            return 'To do items with updates within the time period';
        case PresetFilter.AllToDo:
            return 'All to do items ignoring the time period';
        case PresetFilter.ItemsNoChanges:
            return 'In progress items that had no changes';
        case PresetFilter.ItemsBlocked:
            return 'Items with a blocked status';
        case PresetFilter.DueThisWeek:
            return 'Items not done and due this week';
        // case PresetFilter.MilestonesCompleted:
        //     return 'Milestones Completed';
    }
}

export interface ActivityQueryVars {
    filters?: ActivityItemFiltersInput;
    grouping?: GroupSettingsInput;
    limit?: number;
    sort?: ItemSort;
}

export const DUE_SOON_DAYS_AHEAD = 3;

export function getFiltersForPreset(preset: PresetFilter): PropertyFilterGroup[] {
    switch (preset) {
        case PresetFilter.WorkCompleted:
            return [
                {
                    propertyFilters: [
                        {
                            property: Property.StatusCategory,
                            operator: Operator.Eq,
                            value: StatusCategory.Done,
                        },
                        {
                            property: Property.Changes,
                            operator: Operator.Eq,
                            value: ChangeType.Status,
                        },
                    ],
                },
            ];
        case PresetFilter.Created:
            return [
                {
                    propertyFilters: [
                        {
                            property: Property.Action,
                            operator: Operator.In,
                            value: ChangeActionType.Created,
                        },
                        {
                            property: Property.Changes,
                            operator: Operator.Neq,
                            value: ChangeType.Comment,
                        },
                    ],
                },
            ];
        case PresetFilter.ToDoWithUpdates:
            return [
                {
                    propertyFilters: [
                        {
                            property: Property.StatusCategory,
                            operator: Operator.Eq,
                            value: StatusCategory.ToDo,
                        },
                    ],
                },
            ];
        case PresetFilter.AllToDo:
            return [
                {
                    propertyFilters: [
                        {
                            property: Property.StatusCategory,
                            operator: Operator.Eq,
                            value: StatusCategory.ToDo,
                        },
                        {
                            property: Property.Changes,
                            operator: Operator.Empty,
                            value: 'true',
                        },
                    ],
                },
            ];
        case PresetFilter.OverdueDueSoon:
            return [
                {
                    propertyFilters: [
                        {
                            property: Property.TypeOfObject,
                            operator: Operator.In,
                            value: `${ItemType.Task},${ItemType.Document}`,
                        },
                        {
                            property: Property.EndDate,
                            operator: Operator.Lte,
                            value: DUE_SOON_DAYS_AHEAD.toString(),
                        },
                        {
                            property: Property.StatusCategory,
                            operator: Operator.Neq,
                            value: StatusCategory.Done,
                        },
                    ],
                },
            ];
        case PresetFilter.DueThisWeek:
            return [
                {
                    propertyFilters: [
                        {
                            property: Property.EndDate,
                            operator: Operator.Lte,
                            value: DatePreset.EndOfWeek,
                        },
                        {
                            property: Property.EndDate,
                            operator: Operator.Gte,
                            value: DatePreset.StartOfWeek,
                        },
                        {
                            property: Property.StatusCategory,
                            operator: Operator.Neq,
                            value: StatusCategory.Done,
                        },
                    ],
                },
            ];
        case PresetFilter.InProgress:
            return [
                {
                    propertyFilters: [
                        {
                            property: Property.TypeOfObject,
                            operator: Operator.In,
                            value: `${ItemType.Task},${ItemType.Document},${ItemType.PullRequest}`,
                        },
                        {
                            property: Property.StatusCategory,
                            operator: Operator.Eq,
                            value: StatusCategory.InProgress,
                        },
                    ],
                },
            ];
        case PresetFilter.Updates:
            return [];
        case PresetFilter.UpdatesByOthers:
            return [
                {
                    propertyFilters: [
                        {
                            property: Property.UpdatesBy,
                            operator: Operator.In,
                            value: ActorType.Others,
                        },
                    ],
                },
            ];
        case PresetFilter.HighPriority:
            return [
                {
                    propertyFilters: [
                        {
                            property: Property.Priority,
                            operator: Operator.Regex,
                            value: 'High|Highest',
                        },
                    ],
                },
            ];
        case PresetFilter.ItemsNoChanges:
            return [
                {
                    propertyFilters: [
                        {
                            property: Property.TypeOfObject,
                            operator: Operator.In,
                            value: `${ItemType.Task},${ItemType.Document},${ItemType.MilestoneWithRollupProgress},${ItemType.Milestone},${ItemType.PullRequest}`,
                        },
                        {
                            property: Property.StatusCategory,
                            operator: Operator.Eq,
                            value: StatusCategory.InProgress,
                        },
                        {
                            property: Property.Changes,
                            operator: Operator.Empty,
                            value: 'true',
                        },
                    ],
                },
            ];
        case PresetFilter.ItemsBlocked:
            return [
                {
                    propertyFilters: [
                        {
                            property: Property.TypeOfObject,
                            operator: Operator.In,
                            value: `${ItemType.Task},${ItemType.Document},${ItemType.MilestoneWithRollupProgress},${ItemType.Milestone}`,
                        },
                        {
                            property: Property.Status,
                            operator: Operator.Regex,
                            value: 'block|blocking|blocked|waiting|waiting',
                        },
                    ],
                },
            ];
        case PresetFilter.Indicators:
            return [];
        case PresetFilter.Charts:
            return [];
    }
}

export function getActivityQueryVars(
    grouping: GroupSettingsInput,
    filters: ActivityItemFiltersInput,
): ActivityQueryVars {
    return {
        grouping,
        filters: filters,
    };
}
