// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box, Text, TextType } from 'grommet';
import { FormClose, IconProps } from 'grommet-icons';
import { BackgroundType, BorderType, PadType } from 'grommet/utils';
import { IconButtonV2 } from './IconButton';

export default function CustomizableTag(props: {
    value: string;
    background?: BackgroundType;
    border?: BorderType;
    text?: TextType;
    icon?: IconProps;
    pad?: PadType;
    onRemove?: () => void;
}): JSX.Element {
    return (
        <Box
            round="20px"
            background={props.background}
            border={props.border || { color: Colors.border_light }}
            pad={props.pad || { vertical: '2px', horizontal: 'xsmall' }}
            direction="row"
            gap="xxsmall"
            align="center"
        >
            <Text {...props.text}>{props.value}</Text>
            {props.onRemove && (
                <IconButtonV2
                    icon={() => <FormClose size="14px" {...props.icon} />}
                    onClick={() => {
                        if (props.onRemove) {
                            props.onRemove();
                        }
                    }}
                />
            )}
        </Box>
    );
}
