// Copyright 2021
// ThatWorks.xyz Limited

import { Card, CardBody, CardHeader } from 'grommet';
import React from 'react';

export function InfoNotficationCard(props: { children?: React.ReactNode; header?: React.ReactNode }): JSX.Element {
    return (
        <Card pad="small" elevation="medium" border={{ color: 'neutral-1' }} height={{ height: 'fit-content' }}>
            {props.header && <CardHeader pad={{ horizontal: 'xsmall' }}>{props.header}</CardHeader>}
            <CardBody gap="small" pad="xsmall">
                {props.children}
            </CardBody>
        </Card>
    );
}
