// Copyright 2021
// ThatWorks.xyz Limited

import { useQuery } from '@apollo/client';
import { Colors } from '@thatworks/colors';
import { joinPagesPaths, Pages, QueryParams } from '@thatworks/shared-frontend/pages';
import { Box } from 'grommet';
import { SettingsOption } from 'grommet-icons';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { gql } from '../../../../__generated__';
import { WorkspaceUserAbilities } from '../../../../__generated__/graphql';
import { CtaButtonSpinnerV2 } from '../../../../components/CtaButtonSpinner';
import Loading from '../../../../components/Loading';
import { TwHeading } from '../../../../components/TwHeading';
import { withAuth } from '../../../../components/withAuth';
import { useNavNoRerender } from '../../../../shared/UseNavNoRerender';
import { FrontendStorageKeys } from '../../../../storage-keys';
import { FontFamily } from '../../../../theme';
import { CreationMode } from '../magic-composer/components/MagicComposer';
import { WorkspaceHeaderIcon } from './components/WorkspaceHeaderIcon';
import { WorkspacePosts } from './WorkspacePosts';

const GET_WORKSPACE = gql(/* GraphQL */ `
    query GetWorkspace($organizationId: ID!, $workspaceId: ID!) {
        workspace(organizationId: $organizationId, workspaceId: $workspaceId) {
            id
            name
            userAbilities
        }
    }
`);

function WorkspaceView(props: {
    organizationUuid: string;
    workspaceUuid: string;
    showComposer: boolean;
    setTitle: (title: string) => void;
}): JSX.Element {
    const navigate = useNavigate();
    const navigateNoRerender = useNavNoRerender();
    const { loading, data } = useQuery(GET_WORKSPACE, {
        variables: {
            organizationId: props.organizationUuid,
            workspaceId: props.workspaceUuid,
        },
        onCompleted(data) {
            if (data.workspace) {
                localStorage.setItem(FrontendStorageKeys.LastVisitedTopic, data.workspace.id);
                props.setTitle(data.workspace.name);
            }
        },
    });

    const goToMagicComposer = useCallback(
        async () =>
            navigate(
                `${joinPagesPaths([Pages.app.root, Pages.app.subs.create])}?${QueryParams.Mode}=${CreationMode.Post}`,
            ),
        [navigate],
    );

    if (loading) {
        return (
            <Box fill align="center" justify="center">
                <Loading notAbsolute />
            </Box>
        );
    }

    return (
        <Box id="workspace-view-container">
            {data && data.workspace && (
                <Box>
                    <Box
                        pad={{ horizontal: 'medium', vertical: 'small' }}
                        border={{ color: Colors.border_light, side: 'bottom' }}
                        direction="row"
                        align="center"
                        justify="between"
                        height={{ min: 'max-content' }}
                    >
                        <Box>
                            <TwHeading
                                size="32px"
                                style={{ fontFamily: FontFamily.Callout, fontWeight: 'bold' }}
                                level="2"
                            >
                                {data.workspace.name}
                            </TwHeading>
                        </Box>
                        <Box direction="row" gap="xsmall">
                            <CtaButtonSpinnerV2
                                label="New Post"
                                style={{ padding: '1px 14px' }}
                                onClick={goToMagicComposer}
                                disabled={!data.workspace.userAbilities.includes(WorkspaceUserAbilities.CanCreatePost)}
                            />
                            <WorkspaceHeaderIcon
                                icon={<SettingsOption size="18px" color={Colors.brand} />}
                                onClick={() => navigateNoRerender(Pages.app.subs.topics.subs.settings)}
                                tip={'Settings'}
                            />
                        </Box>
                    </Box>
                    <Box height="100%" justify="between">
                        <Box fill overflow={{ vertical: 'auto' }}>
                            <Box
                                id="digest-body"
                                gap="small"
                                alignSelf="center"
                                width={{ width: '900px', min: '840px', max: '900px' }}
                                height={{ min: 'fit-content' }}
                                pad={{ horizontal: 'xsmall', bottom: 'medium', top: 'medium' }}
                            >
                                <WorkspacePosts
                                    organizationId={props.organizationUuid}
                                    workspaceId={props.workspaceUuid}
                                    workspaceUserAbilities={data.workspace.userAbilities}
                                    createPost={goToMagicComposer}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
}

export default withAuth(WorkspaceView);
