// Copyright 2021
// ThatWorks.xyz Limited

import { useLazyQuery, useMutation } from '@apollo/client';
import { Colors } from '@thatworks/colors';
import { joinPagesPaths, Pages } from '@thatworks/shared-frontend/pages';
import { Box, Button, Grid, RadioButtonGroup, Spinner, Tag, Text, TextInput } from 'grommet';
import { Trash, User, UserAdd, UserAdmin } from 'grommet-icons';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { gql } from '../../../../__generated__';
import { TwError, UsersSearchQuery, WorkspaceOrgPermission, WorkspaceRole } from '../../../../__generated__/graphql';
import { CtaButtonSpinnerV2 } from '../../../../components/CtaButtonSpinner';
import CustomizableTag from '../../../../components/CustomizableTag';
import { DropdownMenuComponent } from '../../../../components/DropdownMenu';
import { IconButtonV2 } from '../../../../components/IconButton';
import { Modal } from '../../../../components/Modal';
import { HoverPlainTextInput } from '../../../../components/PlainTextInput';
import { useTelemetryContext } from '../../../../components/TelemetryContext';
import { TwHeading } from '../../../../components/TwHeading';
import { useUserStateContext } from '../../../../components/UserContext';
import { GET_WORKSPACES_FOR_NAV } from '../../../../components/WorkspacesNav';
import { useNavNoRerender } from '../../../../shared/UseNavNoRerender';
import { FontFamily } from '../../../../theme';

const UPDATE_WORKSPACE = gql(/* GraphQL */ `
    mutation WorkspaceUpdate($organizationId: ID!, $workspaceId: ID!, $workspace: WorkspaceUpdateInput!) {
        workspaceUpdate(organizationId: $organizationId, workspaceId: $workspaceId, workspace: $workspace) {
            id
            members {
                id
                email
                userId
                role
            }
            name
            organizationId
            orgPermission
            group
        }
    }
`);

const CREATE_WORKSPACE = gql(/* GraphQL */ `
    mutation WorkspaceCreate($organizationId: ID!, $workspace: WorkspaceCreateInput!) {
        workspaceCreate(organizationId: $organizationId, workspace: $workspace) {
            id
        }
    }
`);

function getRoleAsString(role: WorkspaceRole): string {
    switch (role) {
        case WorkspaceRole.Admin:
            return 'Admin';
        case WorkspaceRole.Contributor:
            return 'Contributor';
        case WorkspaceRole.Viewer:
            return 'Viewer';
    }
}

const DELETE_WORKSPACE = gql(/* GraphQL */ `
    mutation DeleteWorkspace($organizationId: ID!, $workspaceId: ID!) {
        workspaceDelete(organizationId: $organizationId, workspaceId: $workspaceId)
    }
`);

enum WorkspaceVisibility {
    Public = 'Public',
    Private = 'Private',
}

function DeleteConfirmation(props: {
    workspaceId: string;
    organizationId: string;
    workspaceName: string;
    onConfirm: () => void;
    onCancel: () => void;
}): JSX.Element {
    const [deleteWorkspace] = useMutation(DELETE_WORKSPACE);

    const [deletetionText, setDeletionText] = useState<string>('');
    return (
        <Box gap="xsmall" width={{ min: 'medium', max: '70vw' }}>
            <Text>
                Type <span style={{ fontWeight: 'bold' }}>delete</span> to confirm.
            </Text>
            <TextInput value={deletetionText} onChange={(event) => setDeletionText(event.target.value)} />
            <Box direction="row" gap="xxsmall">
                <Button label="Cancel" secondary onClick={() => props.onCancel()} />
                <CtaButtonSpinnerV2
                    label="Delete"
                    notPrimary
                    color={Colors.status_critical}
                    disabled={deletetionText.toLowerCase() !== 'delete'}
                    onClick={async () => {
                        await deleteWorkspace({
                            variables: {
                                organizationId: props.organizationId,
                                workspaceId: props.workspaceId,
                            },
                            update: (cache) => {
                                const normalizedId = cache.identify({ id: props.workspaceId, __typename: 'Workspace' });
                                cache.evict({
                                    id: normalizedId,
                                });
                                cache.gc();
                            },
                        });
                        props.onConfirm();
                    }}
                    style={{ padding: '0px 30px 0px 30px' }}
                />
            </Box>
        </Box>
    );
}

function WorkspaceMember(props: {
    userId: string;
    role: WorkspaceRole;
    email: string;
    deleted: boolean;
    disableDelete: boolean;
    onChange: (user: { userId: string; role: WorkspaceRole; email: string; deleted: boolean }) => void;
    hideRole?: boolean;
    userIsAdmin: boolean;
}): JSX.Element {
    const [selected, setSelected] = useState<{ label: string; id: string }[]>([
        { id: props.role, label: getRoleAsString(props.role) },
    ]);

    return (
        <Grid columns={['300px', 'small', 'small']} gap="small">
            <Box gap="xxsmall" direction="row" align="center">
                {props.role === WorkspaceRole.Admin && <UserAdmin size="16px" />}
                {props.role !== WorkspaceRole.Admin && <User size="16px" />}
                <Text truncate="tip" size="16px">
                    {props.email}
                </Text>
            </Box>
            {!props.hideRole && (
                <DropdownMenuComponent
                    label={selected[0].label}
                    options={Object.values(WorkspaceRole).map((r) => ({ id: r, label: getRoleAsString(r) }))}
                    selected={selected}
                    disabled={!props.userIsAdmin}
                    onSelectionChange={(sel) => {
                        if (sel.length === 0) {
                            return;
                        }
                        setSelected(sel);
                        props.onChange({
                            userId: props.userId,
                            email: props.email,
                            deleted: props.deleted,
                            role: sel[0].id as WorkspaceRole,
                        });
                    }}
                    hideSearch
                    singleSelectionOnly
                    dropMenuLabel={<Box pad={{ horizontal: 'small', top: 'xsmall' }}>Role</Box>}
                    boxProps={{
                        pad: { horizontal: 'xxsmall', vertical: '2px' },
                    }}
                    iconSize="14px"
                />
            )}
            <Box direction="row" align="center">
                {!props.deleted && (
                    <IconButtonV2
                        icon={(hover, disabled) => (
                            <Trash
                                size="16px"
                                color={disabled ? Colors.status_disabled : hover ? Colors.brand : undefined}
                            />
                        )}
                        disabled={props.disableDelete || !props.userIsAdmin}
                        onClick={() =>
                            props.onChange({
                                userId: props.userId,
                                email: props.email,
                                deleted: true,
                                role: props.role,
                            })
                        }
                    />
                )}
                {props.deleted && (
                    <CustomizableTag
                        value={'Removed'}
                        text={{ size: '16px' }}
                        icon={{ size: '14px' }}
                        onRemove={() =>
                            props.onChange({
                                userId: props.userId,
                                email: props.email,
                                deleted: false,
                                role: props.role,
                            })
                        }
                        background={{ color: Colors.status_error, opacity: 'strong' }}
                    />
                )}
            </Box>
        </Grid>
    );
}

type UserUpdateMap = Map<string, { role: WorkspaceRole; deleted: boolean; email: string }>;

function WorkspaceMembers(props: {
    members: { userId: string; role: WorkspaceRole; email: string; deleted: boolean }[];
    membersToUpdate: UserUpdateMap;
    onChange: (user: { userId: string; role: WorkspaceRole; email: string; deleted: boolean }) => void;
    userIsAdmin: boolean;
    hideRole?: boolean;
    visibility: WorkspaceVisibility;
    showAddMembersModal: () => void;
}): JSX.Element {
    const [allMembers, setAllMembers] = useState<
        { userId: string; role: WorkspaceRole; email: string; deleted: boolean }[]
    >([]);

    useEffect(() => {
        const map: UserUpdateMap = new Map();
        props.members.forEach((m) => {
            map.set(m.userId, { email: m.email, role: m.role, deleted: m.deleted });
        });
        props.membersToUpdate.forEach((v, k) => map.set(k, v));
        const combined = Array.from(map.entries()).map(([k, v]) => ({
            email: v.email,
            role: v.role,
            userId: k,
            deleted: v.deleted,
        }));
        combined.sort((a, b) => a.email.localeCompare(b.email));
        setAllMembers(combined);
    }, [props.members, props.membersToUpdate]);

    const members = useMemo(() => {
        if (props.visibility === WorkspaceVisibility.Public) {
            return allMembers.filter((m) => m.role === WorkspaceRole.Admin);
        }
        return allMembers;
    }, [allMembers, props.visibility]);

    return (
        <Box>
            <Box direction="row" align="center" gap="xxsmall">
                <TwHeading level={3}>
                    {props.visibility === WorkspaceVisibility.Public ? 'Admins' : 'Members'}&nbsp;
                </TwHeading>
                <IconButtonV2
                    icon={(hover, disabled) => (
                        <UserAdd size="18px" color={hover && !disabled ? Colors.brand : undefined} />
                    )}
                    tooltip={'Add admin'}
                    onClick={() => props.showAddMembersModal()}
                    disabled={!props.userIsAdmin}
                />
            </Box>
            <Box height={{ max: '40vh' }} overflow={{ vertical: 'auto' }}>
                <Box height={{ min: 'max-content', height: 'max-content' }} gap="xxsmall">
                    {members.map((m) => (
                        <WorkspaceMember
                            key={`member-${m.userId}`}
                            {...m}
                            disableDelete={allMembers.length === 1}
                            onChange={props.onChange}
                            userIsAdmin={props.userIsAdmin}
                            hideRole={props.visibility === WorkspaceVisibility.Public}
                        />
                    ))}
                </Box>
            </Box>
        </Box>
    );
}

const USERS_SEARCH = gql(/* GraphQL */ `
    query UsersSearch($organizationId: ID!, $query: String!) {
        usersSearch(organizationId: $organizationId, query: $query) {
            email
            id
            name
        }
    }
`);

function AddMembers(props: {
    organizationId: string;
    existingMembers: string[];
    restrictRole?: WorkspaceRole;
    dontFilterExistingUsers?: boolean;
    onCta: (newMembers: UsersSearchQuery['usersSearch'], role: WorkspaceRole) => void;
    onCancel: () => void;
}): JSX.Element {
    const [usersSearch, { loading }] = useLazyQuery(USERS_SEARCH);
    const [searchResults, setSearchResults] = useState<UsersSearchQuery['usersSearch']>([]);
    const [selectedUsers, setSelectedUsers] = useState<UsersSearchQuery['usersSearch']>([]);
    const [selectedRole, setSelectedRole] = useState<WorkspaceRole>(props.restrictRole || WorkspaceRole.Contributor);
    const [searchValue, setSearchValue] = useState<string>('');

    const debouncedResults = useMemo(() => {
        return debounce((query: string) => {
            usersSearch({ variables: { query, organizationId: props.organizationId } }).then((r) => {
                const searchRes = r.data ? r.data.usersSearch : [];
                if (props.dontFilterExistingUsers) {
                    setSearchResults(searchRes);
                    return;
                }
                const allExistingUsers = selectedUsers.map((s) => s.id).concat(props.existingMembers);
                const filtered = searchRes.filter((s) => !allExistingUsers.includes(s.id));
                setSearchResults(filtered);
            });
        }, 300);
    }, [props.dontFilterExistingUsers, props.existingMembers, props.organizationId, selectedUsers, usersSearch]);

    useEffect(() => {
        return () => debouncedResults.cancel();
    }, [debouncedResults]);

    return (
        <Box width={{ min: '600px', max: '600px' }} gap="small">
            <Box gap="xxsmall">
                <Box direction="row" gap="xxsmall">
                    <TextInput
                        placeholder={
                            <Box direction="row" gap="xxsmall">
                                <Text size="16px" color={Colors.light_6}>
                                    Search for email
                                </Text>
                            </Box>
                        }
                        size="16px"
                        onChange={(e) => {
                            const searchText = e.target.value;
                            setSearchValue(searchText);
                            debouncedResults(searchText);
                        }}
                        value={searchValue}
                        suggestions={searchResults.map((s) => ({ label: `${s.email}`, value: s }))}
                        onSuggestionSelect={(x) => {
                            setSearchResults([]);
                            setSearchValue('');
                            const suggested = x.suggestion.value as UsersSearchQuery['usersSearch'][number];
                            setSelectedUsers((v) => v.concat(suggested));
                        }}
                        reverse
                        icon={loading ? <Spinner /> : undefined}
                    />
                </Box>
                <Box direction="row" gap="xxsmall" wrap align="center">
                    {selectedUsers.map((u) => (
                        <Box key={u.id} margin={{ bottom: '4px' }}>
                            <Tag
                                size="xsmall"
                                value={u.email}
                                onRemove={() => setSelectedUsers((s) => s.filter((v) => v.id !== u.id))}
                            />
                        </Box>
                    ))}
                    {!props.restrictRole && selectedUsers.length > 0 && (
                        <Box direction="row" gap="xxsmall" align="center">
                            <Text size="14px">as</Text>
                            <DropdownMenuComponent
                                label={getRoleAsString(selectedRole)}
                                options={Object.values(WorkspaceRole).map((r) => ({
                                    id: r,
                                    label: getRoleAsString(r),
                                }))}
                                selected={[{ id: selectedRole, label: getRoleAsString(selectedRole) }]}
                                onSelectionChange={(sel) => {
                                    if (sel.length === 0) {
                                        return;
                                    }
                                    setSelectedRole(sel[0].id as WorkspaceRole);
                                }}
                                hideSearch
                                singleSelectionOnly
                                dropMenuLabel={<Box pad={{ horizontal: 'small', top: 'xsmall' }}>Role</Box>}
                                boxProps={{
                                    margin: {},
                                    border: { size: '1px', color: Colors.border_light },
                                    background: { color: Colors.background_back },
                                    pad: { horizontal: '8px', vertical: '8px' },
                                    elevation: 'none',
                                    round: '20px',
                                }}
                                getTextProps={() => ({
                                    size: '12px',
                                    style: { fontFamily: FontFamily.Standard },
                                    weight: 'bold',
                                })}
                                iconSize="12px"
                            />
                        </Box>
                    )}
                    {props.restrictRole && selectedUsers.length > 0 && (
                        <Box direction="row" gap="xxsmall" align="center">
                            <Text size="14px">as {getRoleAsString(props.restrictRole)}</Text>
                        </Box>
                    )}
                </Box>
            </Box>
            <Box direction="row" gap="xxsmall">
                <CtaButtonSpinnerV2
                    notPrimary
                    label="Cancel"
                    style={{ width: '90px' }}
                    onClick={async () => props.onCancel()}
                />
                <CtaButtonSpinnerV2
                    label="Add"
                    style={{ width: '90px' }}
                    disabled={selectedUsers.length === 0}
                    onClick={async () => {
                        props.onCta(selectedUsers, selectedRole);
                        props.onCancel();
                    }}
                />
            </Box>
        </Box>
    );
}

function getWorkspaceVisibilityLabel(p: WorkspaceVisibility, orgName: string | undefined, workspaceName: string) {
    const org = orgName || 'this organization';
    const workspace = workspaceName.length > 0 ? `"${workspaceName}"` : 'this Topic';
    switch (p) {
        case WorkspaceVisibility.Public:
            return `Public — everyone in ${org} can view and post content`;
        case WorkspaceVisibility.Private:
            return `Private — limited to members of ${workspace}`;
    }
}

function getWorkspacePermissionFromVisibility(p: WorkspaceVisibility): WorkspaceOrgPermission {
    switch (p) {
        case WorkspaceVisibility.Public:
            return WorkspaceOrgPermission.AnyoneCanViewAndCreateContent;
        case WorkspaceVisibility.Private:
            return WorkspaceOrgPermission.MembersOnly;
    }
}

function getWorkspaceVisibilityFromPermission(p: WorkspaceOrgPermission): WorkspaceVisibility {
    switch (p) {
        case WorkspaceOrgPermission.AnyoneCanViewAndCreateContent:
            return WorkspaceVisibility.Public;
        case WorkspaceOrgPermission.MembersOnly:
            return WorkspaceVisibility.Private;
        default:
            throw new Error(`Invalid permission value ${p}`);
    }
}

const WORKSPACE_NAME_MAX_LENGTH = 50;

export function WorkplaceCreateEditView(props: {
    mode:
        | {
              type: 'update';
              userIsAdmin: boolean;
              workspaceId: string;
              workspaceName: string;
              orgPermission: WorkspaceOrgPermission;
              members: { userId: string; email: string; role: WorkspaceRole }[];
          }
        | { type: 'create'; user: { userId: string; email: string } };
    organizationId: string;
    organizationDisplayName: string;
}) {
    const { postErrorMessage } = useUserStateContext();
    const { logger } = useTelemetryContext();
    const [updateWorkspace] = useMutation(UPDATE_WORKSPACE);
    const [createWorkspace] = useMutation(CREATE_WORKSPACE);

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showAddMembersModal, setShowAddMembersModal] = useState(false);
    const navigate = useNavNoRerender();

    const [newName, setNewName] = useState<string>(props.mode.type === 'update' ? props.mode.workspaceName : '');
    const [membersToUpdate, setMembersToUpdate] = useState<UserUpdateMap>(
        new Map(
            props.mode.type === 'create'
                ? [
                      [
                          props.mode.user.userId,
                          { deleted: false, email: props.mode.user.email, role: WorkspaceRole.Admin },
                      ],
                  ]
                : undefined,
        ),
    );
    const [workspaceVisibility, setWorkspaceVisibility] = useState<WorkspaceVisibility>(
        props.mode.type === 'update'
            ? getWorkspaceVisibilityFromPermission(props.mode.orgPermission)
            : WorkspaceVisibility.Public,
    );

    const ctaDisabled = useCallback(() => {
        if (props.mode.type === 'update') {
            return (
                !props.mode.userIsAdmin ||
                newName.length === 0 ||
                (membersToUpdate.size === 0 &&
                    newName === props.mode.workspaceName &&
                    getWorkspacePermissionFromVisibility(workspaceVisibility) === props.mode.orgPermission)
            );
        }
        return newName.length === 0;
    }, [membersToUpdate.size, newName, workspaceVisibility, props.mode]);

    const isAdmin = useCallback(() => {
        if (props.mode.type === 'update') {
            return props.mode.userIsAdmin;
        }
        return true;
    }, [props.mode]);

    return (
        <Box>
            <Box
                pad={{ horizontal: 'medium', vertical: 'small' }}
                border={{ color: Colors.border_light, side: 'bottom' }}
                direction="row"
                align="center"
                justify="between"
                gap="small"
            >
                {/* Topic name */}
                <Box fill="horizontal">
                    <HoverPlainTextInput
                        hoverBgColor={Colors.white}
                        inputStyle={{
                            fontWeight: 'normal',
                            color: Colors.brand,
                            backgroundColor: 'unset',
                            overflow: 'hidden',
                            resize: 'none',
                            width: '100%',
                        }}
                        inputProps={{ fontSize: '32px', placeholderColor: Colors.light_5 }}
                        value={newName}
                        inputAttr={{
                            placeholder: 'Topic Name (required)',
                            onChange: (e) => {
                                setNewName(e.target.value);
                            },
                            onBlur: () => {},
                            maxLength: WORKSPACE_NAME_MAX_LENGTH,
                        }}
                        style={{ border: `1px solid ${Colors.border_dark}` }}
                        alwaysShowFilledBackground
                        disableEdit={!isAdmin()}
                    />
                </Box>

                {/* Cancel and Save buttons */}
                <Box direction="row" gap="xxsmall">
                    <CtaButtonSpinnerV2
                        label="Cancel"
                        style={{ width: '95px' }}
                        onClick={async () => {
                            navigate('..');
                        }}
                        notPrimary
                    />
                    <CtaButtonSpinnerV2
                        label="Save"
                        style={{ width: '80px' }}
                        disabled={ctaDisabled()}
                        onClick={async () => {
                            if (props.mode.type === 'update') {
                                await updateWorkspace({
                                    variables: {
                                        organizationId: props.organizationId,
                                        workspaceId: props.mode.workspaceId,
                                        workspace: {
                                            name: newName !== props.mode.workspaceName ? newName : undefined,
                                            members:
                                                membersToUpdate.size > 0
                                                    ? Array.from(membersToUpdate.entries()).map(([k, v]) => ({
                                                          id: k,
                                                          role: v.deleted ? undefined : v.role,
                                                      }))
                                                    : undefined,
                                            orgPermission:
                                                getWorkspacePermissionFromVisibility(workspaceVisibility) !==
                                                props.mode.orgPermission
                                                    ? getWorkspacePermissionFromVisibility(workspaceVisibility)
                                                    : undefined,
                                        },
                                    },
                                    onError: (err) => {
                                        const showMessage = err.graphQLErrors.find(
                                            (e) => e.extensions?.code === TwError.DisplayErrorMessage,
                                        );
                                        postErrorMessage({
                                            title: `Error`,
                                            shortDesc: showMessage ? err.message : `Failed to update settings`,
                                        });
                                        if (!showMessage) {
                                            logger.error(err.message);
                                        }
                                    },
                                    onCompleted: () => navigate('..'),
                                });
                            } else {
                                await createWorkspace({
                                    variables: {
                                        organizationId: props.organizationId,
                                        workspace: {
                                            name: newName,
                                            orgPermission: getWorkspacePermissionFromVisibility(workspaceVisibility),
                                            members: Array.from(membersToUpdate.entries())
                                                .filter(([_, v]) => !v.deleted)
                                                .map(([k, v]) => ({
                                                    id: k,
                                                    role: v.role,
                                                })),
                                        },
                                    },
                                    onError: (err) => {
                                        const showMessage = err.graphQLErrors.find(
                                            (e) => e.extensions?.code === TwError.DisplayErrorMessage,
                                        );
                                        postErrorMessage({
                                            title: `Error`,
                                            shortDesc: showMessage ? err.message : `Failed to update settings`,
                                        });
                                        if (!showMessage) {
                                            logger.error(err.message);
                                        }
                                    },
                                    onCompleted: (d) => navigate(`../${d.workspaceCreate.id}`),
                                    awaitRefetchQueries: true,
                                    refetchQueries: () => [
                                        {
                                            query: GET_WORKSPACES_FOR_NAV,
                                            variables: {
                                                organizationId: props.organizationId,
                                            },
                                            errorPolicy: 'ignore',
                                        },
                                    ],
                                });
                            }
                        }}
                    />
                </Box>
            </Box>
            <Box pad={{ horizontal: 'medium', vertical: 'small' }}>
                <Box gap="small">
                    {/* Visibility */}
                    <Box gap="xxsmall">
                        <TwHeading level={3}>Visibility</TwHeading>
                        <RadioButtonGroup
                            name=""
                            options={Object.values(WorkspaceVisibility).map((v) => ({
                                id: v,
                                label: getWorkspaceVisibilityLabel(v, props.organizationDisplayName, newName),
                                value: v,
                            }))}
                            value={workspaceVisibility}
                            onChange={(event) => {
                                setWorkspaceVisibility(event.target.value as WorkspaceVisibility);
                            }}
                            disabled={!isAdmin()}
                            gap={'xxsmall'}
                        />
                    </Box>

                    {/* Admin/Members */}
                    <Box gap="xxsmall">
                        <WorkspaceMembers
                            members={
                                props.mode.type === 'update'
                                    ? props.mode.members.map((m) => ({
                                          deleted: false,
                                          email: m.email,
                                          role: m.role,
                                          userId: m.userId,
                                      }))
                                    : []
                            }
                            visibility={workspaceVisibility}
                            showAddMembersModal={() => setShowAddMembersModal(true)}
                            userIsAdmin={isAdmin()}
                            onChange={(userToDelete) => {
                                setMembersToUpdate((s) => {
                                    s.set(userToDelete.userId, {
                                        role: userToDelete.role,
                                        email: userToDelete.email,
                                        deleted: userToDelete.deleted,
                                    });
                                    return new Map(s);
                                });
                            }}
                            membersToUpdate={new Map(membersToUpdate)}
                        />
                        <Modal
                            show={showAddMembersModal}
                            title={'Add Members'}
                            onClose={() => setShowAddMembersModal(false)}
                            buttons={{ hideCancel: true }}
                        >
                            <AddMembers
                                organizationId={props.organizationId}
                                onCta={(newMembers, newRole) => {
                                    setMembersToUpdate((s) => {
                                        newMembers.forEach((m) =>
                                            s.set(m.id, { role: newRole, email: m.email, deleted: false }),
                                        );
                                        return new Map(s);
                                    });
                                }}
                                existingMembers={
                                    props.mode.type === 'update'
                                        ? props.mode.members.map((m) => m.userId)
                                        : [props.mode.user.userId]
                                }
                                onCancel={() => setShowAddMembersModal(false)}
                                dontFilterExistingUsers={workspaceVisibility === WorkspaceVisibility.Public}
                                restrictRole={
                                    workspaceVisibility === WorkspaceVisibility.Public ? WorkspaceRole.Admin : undefined
                                }
                            />
                        </Modal>
                    </Box>

                    {/* Delete topic */}
                    {props.mode.type === 'update' && props.mode.userIsAdmin && (
                        <Box
                            pad="small"
                            background={{ color: Colors.status_critical, opacity: 'weak' }}
                            round="8px"
                            gap="xsmall"
                            width="large"
                            margin={{ top: 'small' }}
                            border={{ color: Colors.status_critical }}
                        >
                            <Box direction="row" justify="between" align="start">
                                <Box>
                                    <Text weight={'bold'}>Delete this topic</Text>
                                    <Text>All posts and data will be deleted.</Text>
                                </Box>
                                <Button label="Delete" onClick={() => setShowDeleteModal(true)} />
                            </Box>
                            <Modal
                                show={showDeleteModal}
                                title={'Are you sure?'}
                                onClose={() => setShowDeleteModal(false)}
                                buttons={{ hideCancel: true }}
                            >
                                <DeleteConfirmation
                                    onConfirm={() => {
                                        setShowDeleteModal(false);
                                        navigate(joinPagesPaths([Pages.app.root, Pages.app.subs.topics.root]));
                                    }}
                                    workspaceName={props.mode.workspaceName}
                                    onCancel={() => setShowDeleteModal(false)}
                                    organizationId={props.organizationId}
                                    workspaceId={props.mode.workspaceId}
                                />
                            </Modal>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
}
