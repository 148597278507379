// Copyright 2021
// ThatWorks.xyz Limited

import { useQuery } from '@apollo/client';
import { Box } from 'grommet';
import { gql } from '../../../../__generated__';
import { WorkspaceRole } from '../../../../__generated__/graphql';
import Loading from '../../../../components/Loading';
import { useTelemetryContext } from '../../../../components/TelemetryContext';
import { useUserStateContext } from '../../../../components/UserContext';
import { withAuth } from '../../../../components/withAuth';
import { WorkplaceCreateEditView } from './WorkplaceCreateEditView';

const GET_WORKSPACE_MEMBERS = gql(/* GraphQL */ `
    query GetWorkspaceProps($organizationId: ID!, $workspaceId: ID!) {
        workspace(organizationId: $organizationId, workspaceId: $workspaceId) {
            members {
                id
                email
                role
                userId
            }
            name
            orgPermission
            group
        }
    }
`);

function WorkspaceSettings(props: {
    organizationId: string;
    workspaceId: string;
    setTitle: (title: string) => void;
}): JSX.Element {
    const { postErrorMessage, organizationDisplayName, userState } = useUserStateContext();
    const { logger } = useTelemetryContext();

    const { data, loading } = useQuery(GET_WORKSPACE_MEMBERS, {
        variables: {
            organizationId: props.organizationId,
            workspaceId: props.workspaceId,
        },
        onCompleted(data) {
            if (data.workspace) {
                props.setTitle(data.workspace.name);
            }
        },
        onError: (error) => {
            postErrorMessage({ title: 'Error', shortDesc: 'Failed to get topic settings' });
            logger.error(error.message);
        },
    });

    if (loading) {
        return (
            <Box fill align="center" justify="center">
                <Loading notAbsolute />
            </Box>
        );
    }

    return (
        <Box>
            {userState && data && data.workspace && organizationDisplayName && (
                <WorkplaceCreateEditView
                    mode={{
                        type: 'update',
                        userIsAdmin:
                            data.workspace.members.find((m) => m.userId === userState.id)?.role ===
                                WorkspaceRole.Admin || false,
                        workspaceId: props.workspaceId,
                        workspaceName: data.workspace.name,
                        orgPermission: data.workspace.orgPermission,
                        members: data.workspace.members
                            .filter((m) => m.email)
                            .map((m) => ({ email: m.email || '', role: m.role, userId: m.userId })),
                    }}
                    organizationId={props.organizationId}
                    organizationDisplayName={organizationDisplayName}
                />
            )}
        </Box>
    );
}

export default withAuth(WorkspaceSettings);
