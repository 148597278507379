// Copyright 2021
// ThatWorks.xyz Limited

import { useQuery } from '@apollo/client';
import { Colors } from '@thatworks/colors';
import { joinPagesPaths, NEW_COLLECTION_URL_ID, Pages, QueryParams } from '@thatworks/shared-frontend/pages';
import { Box, Button, Spinner, Text } from 'grommet';
import { Add, Icon, Lock } from 'grommet-icons';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { gql } from '../__generated__';
import { WorkspacePresetGroup } from '../__generated__/graphql';
import { Hashtag } from '../icons/Hashtag';
import { ParamKey } from '../shared/param-keys';
import { useNavNoRerender } from '../shared/UseNavNoRerender';
import { IconButtonV2 } from './IconButton';
import { useTelemetryContext } from './TelemetryContext';
import { tooltipProps } from './Tooltip';
import { useUserStateContext } from './UserContext';
import { withAuth } from './withAuth';

export const GET_WORKSPACES_FOR_NAV = gql(/* GraphQL */ `
    query WorkspacesForNav($organizationId: ID!) {
        workspaces(organizationId: $organizationId) {
            id
            name
            group
        }
    }
`);

function WorkspaceTextButton(props: {
    label: string;
    onClick?: () => void;
    active?: boolean;
    icon?: Icon;
}): JSX.Element {
    const textRef = useRef<HTMLSpanElement>(null);
    const [hasOverflowed, setHasOverflowed] = useState(false);

    useEffect(() => {
        if (textRef.current) {
            const has = textRef.current.offsetWidth < textRef.current.scrollWidth;
            setHasOverflowed(has);
        }
    }, [textRef]);

    return (
        <Button
            onClick={() => {
                if (props.onClick) {
                    props.onClick();
                }
            }}
            plain
        >
            {({ hover, disabled }) => (
                <Box
                    round="6px"
                    direction="row"
                    align="center"
                    gap="xxsmall"
                    background={hover || props.active ? Colors.accent_2 : undefined}
                    pad="xxsmall"
                >
                    {props.icon && (
                        <props.icon
                            size="small"
                            color={props.active ? Colors.dark_2 : hover ? Colors.dark_2 : Colors.neutral_4}
                        />
                    )}
                    <Text
                        ref={textRef}
                        size="13px"
                        weight={props.active ? 'bold' : undefined}
                        color={props.active ? Colors.dark_2 : hover ? Colors.dark_2 : Colors.neutral_4}
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                        tip={hasOverflowed ? tooltipProps(props.label) : undefined}
                    >
                        {`${props.label}`}
                    </Text>
                </Box>
            )}
        </Button>
    );
}

function WorkspacesNav(props: { organizationId: string; closed: boolean }): JSX.Element {
    const { postErrorMessage } = useUserStateContext();
    const { logger } = useTelemetryContext();
    const params = useParams();
    const navigate = useNavNoRerender();
    const [searchParams] = useSearchParams();

    const { data, loading } = useQuery(GET_WORKSPACES_FOR_NAV, {
        variables: {
            organizationId: props.organizationId,
        },
        onError: (error) => {
            postErrorMessage({ title: 'Error', shortDesc: 'Failed to get topics' });
            logger.error(error.message);
        },
    });
    const [selectedUuid, setSelectedUuid] = useState<string | undefined>();

    useEffect(() => {
        // For the case where the user is not a workspace page (e.g. /connect)
        const rootParam = params['*'];
        if (rootParam && !rootParam.includes(Pages.app.subs.topics.root)) {
            return;
        }

        const workspaceUuid = params[ParamKey.WorkspaceUuid];
        if (workspaceUuid === NEW_COLLECTION_URL_ID) {
            setSelectedUuid(NEW_COLLECTION_URL_ID);
            return;
        }

        if (!data) {
            return;
        }

        const workspaceExists = data.workspaces.find((w) => w.id === workspaceUuid);
        if (!workspaceExists) {
            if (data.workspaces.length === 0) {
                navigate(joinPagesPaths([Pages.app.root, Pages.app.subs.topics.root, NEW_COLLECTION_URL_ID]));
                return;
            }

            const showComposer = searchParams.get(QueryParams.ShowComposer) != null ? true : false;
            const qp = showComposer ? `?${QueryParams.ShowComposer}` : '';
            const uuidToRedirect = data.workspaces[0].id;
            navigate(`${joinPagesPaths([Pages.app.root, Pages.app.subs.topics.root, uuidToRedirect])}${qp}`);
            return;
        }
        setSelectedUuid(workspaceUuid);
    }, [data, navigate, params, searchParams]);

    // Topics
    const topics = useMemo(
        () => (
            <Box gap="xsmall">
                {/* Title and add button*/}
                <Box direction="row" pad={{ horizontal: 'xxsmall' }} justify="between" height={{ min: 'min-content' }}>
                    <Box direction="row" gap="xxsmall">
                        <Text
                            size="14px"
                            color={Colors.neutral_2}
                            weight="bold"
                            style={{ textTransform: 'uppercase', letterSpacing: '2px' }}
                        >
                            Topics
                        </Text>
                        {loading && <Spinner size="xsmall" color={Colors.accent_2} />}
                    </Box>
                    <IconButtonV2
                        icon={() => <Add size="small" color={Colors.neutral_2} />}
                        onClick={async () =>
                            navigate(
                                joinPagesPaths([Pages.app.root, Pages.app.subs.topics.root, NEW_COLLECTION_URL_ID]),
                            )
                        }
                    />
                </Box>
                {/* Topics list */}
                {!loading && data && (
                    <Box overflow={{ vertical: 'auto' }} gap={'xxsmall'}>
                        {data.workspaces.length === 0 && <Text color={Colors.light_6}>No topics</Text>}
                        {data.workspaces.map((w) => (
                            <WorkspaceTextButton
                                key={`nav-${w.id}`}
                                label={w.name}
                                icon={w.group === WorkspacePresetGroup.Private ? Lock : Hashtag}
                                active={w.id === selectedUuid}
                                onClick={() =>
                                    navigate(joinPagesPaths([Pages.app.root, Pages.app.subs.topics.root, w.id]))
                                }
                            />
                        ))}
                    </Box>
                )}
            </Box>
        ),
        [data, loading, navigate, selectedUuid],
    );

    return (
        <Box
            height={{ min: '150px' }}
            border={{ color: Colors.neutral_2, size: '1px', side: 'top' }}
            pad={{ top: '10px' }}
            align={props.closed ? 'center' : 'stretch'}
        >
            {props.closed && <Hashtag size="18px" color={Colors.neutral_2} />}
            {!props.closed && topics}
        </Box>
    );
}

export default withAuth(WorkspacesNav);
