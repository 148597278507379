// Copyright 2021
// ThatWorks.xyz Limited

import { Colors } from '@thatworks/colors';
import { Box, Select, SelectMultiple, TextInput } from 'grommet';
import debounce from 'lodash.debounce';
import { useEffect, useMemo, useState } from 'react';
import { DateInput } from './DateInput';
import { PropertyFilterSelection, ValueOptions } from './helpers';

function calculateSuggestedInput(suggestions: string[], userInput: string): string[] {
    // Return all the suggestions if user input is not valid
    if (userInput == null || userInput === '') {
        return suggestions;
    }

    // Normalise the user input
    const userInputNormalised = userInput.toLowerCase();

    // Initialize suggested input
    let suggestedInputs: string[] = [];

    // Iterate over the suggestions to get all the suggestions that match the user input
    suggestions.forEach((value) => {
        const valueNormalised = value.toLowerCase();
        const valueDescontructed = valueNormalised.split(/[ ]|[-]/);
        for (const v of valueDescontructed) {
            if (v.startsWith(userInputNormalised) && valueNormalised !== userInputNormalised) {
                suggestedInputs.push(value);
                break;
            }
        }
    });

    // Return suggested inputs
    return suggestedInputs;
}

export function ValueInput(props: {
    selection: PropertyFilterSelection;
    onChange: (filter: PropertyFilterSelection) => void;
    valueOptions: ValueOptions | undefined;
}): JSX.Element {
    const onChange = props.onChange;
    const [value, setValue] = useState<string>(props.selection.value);
    const debounceValue = useMemo(() => {
        return debounce((value: string) => {
            const newSelection = { ...props.selection };
            newSelection.value = value;
            onChange(newSelection);
        }, 200);
    }, [onChange, props.selection]);

    useEffect(() => {
        return () => debounceValue.cancel();
    }, [debounceValue]);

    useEffect(() => {
        setValue(props.selection.value);
    }, [props.selection.value]);

    // Get suggested inputs
    const suggestedInputs = useMemo(() => {
        // Get the available options
        const options = props.valueOptions?.options?.map((v) => v.value) ?? [];

        // Calculate the suggested input
        const suggestedInput = calculateSuggestedInput(options, value);

        // Return the suggested input
        return suggestedInput;
    }, [props.valueOptions, value]);

    // Validate what input we should show
    let input: JSX.Element | undefined;

    // Text input
    if (props.valueOptions?.allowCustomSearch && !props.valueOptions.allowMultiple) {
        input = (
            <TextInput
                plain
                placeholder="Value"
                value={value}
                onChange={(e) => {
                    debounceValue(e.target.value);
                    setValue(e.target.value);
                }}
                suggestions={suggestedInputs}
                onSuggestionSelect={({ suggestion }) => {
                    debounceValue(suggestion);
                    setValue(suggestion);
                }}
            />
        );
    } else if (props.valueOptions?.options && !props.valueOptions.allowCustomSearch) {
        // Multi select input
        if (props.valueOptions.allowMultiple) {
            input = (
                <SelectMultiple
                    plain
                    focusIndicator
                    icon={false}
                    placeholder="Value"
                    options={props.valueOptions?.options ?? []}
                    value={props.selection.value ? props.selection.value.split(',') : []}
                    valueKey="value"
                    labelKey="label"
                    size="small"
                    dropProps={{
                        width: 'medium',
                    }}
                    onChange={({ value }) => {
                        const result = value.reduce(
                            (previous: string, current: { label: string; value: string }) =>
                                previous ? `${current.value},${previous}` : current.value,
                            '',
                        );
                        debounceValue(result);
                        setValue(result);
                    }}
                />
            );
        }
        // Select input
        else {
            input = (
                <Select
                    plain
                    focusIndicator
                    icon={false}
                    placeholder="Value"
                    options={props.valueOptions?.options ?? []}
                    value={props.selection.value}
                    valueKey="value"
                    labelKey="label"
                    size="small"
                    onChange={({ option }) => {
                        debounceValue(option.value);
                        setValue(option.value);
                    }}
                />
            );
        }
    } else if (props.valueOptions?.isDate) {
        input = (
            <DateInput
                key={`date-input-${props.selection.value}`} // Add key so react knows when to re-renders the component
                selection={props.selection}
                onChange={(dateValue) => {
                    debounceValue(dateValue);
                    setValue(dateValue);
                }}
            />
        );
    }

    return input ? (
        <Box
            border={{ color: Colors.accent_3, size: '1px' }}
            round="8px"
            height="32px"
            direction="row"
            align="center"
            justify="center"
            width="200px"
        >
            {input}
        </Box>
    ) : (
        <></>
    );
}
