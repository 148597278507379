// Copyright 2021
// ThatWorks.xyz Limited

export enum FilterHierarchyType {
    Root = 'Root',
    SpecificItem = 'SpecificItem',
    ConnectorTopLevel = 'ConnectorTopLevel',
    JiraBoard = 'JiraBoard',
    JiraSprint = 'JiraSprint',
    JiraEpic = 'JiraEpic',
    JiraBacklog = 'JiraBacklog',
    JiraProject = 'JiraProject',
    ClickUpSpace = 'ClickUpSpace',
    ClickUpFolder = 'ClickUpFolder',
    ClickUpList = 'ClickUpList',
    GoogleDrive = 'GoogleDrive',
    GoogleDriveFolder = 'GoogleDriveFolder',
    EverythingInConnector = 'ConnectorAll',
    FigmaProject = 'FigmaProject',
    MiroTeam = 'MiroTeam',
    ConfluenceSpace = 'ConfluenceSpace',
    AsanaProject = 'AsanaProject',
    GoogleAnalyticsProperty = 'GoogleAnalyticsProperty',
    TogglProject = 'TogglProject',
    LinearProject = 'LinearProject',
    LinearCycle = 'LinearCycle',
    GoogleSheetNamedRange = 'GoogleSheetNamedRange',
    GoogleSheet = 'GoogleSheet',
    NotionDatabase = 'NotionDatabase',
    GithubRepo = 'GithubRepo',
    GithubBranch = 'GithubBranch',
    GithubPullRequest = 'GithubPullRequest',
    BitbucketRepo = 'BitbucketRepo',
    BitbucketBranch = 'BitbucketBranch',
    BitbucketPullRequest = 'BitbucketPullRequest',
    BitbucketCommit = 'BitbucketCommit',
    SlackChannel = 'SlackChannel',
}

export const EverythingInConnectorId = 'everything_in_connector_id';

export enum CustomFilePickerIds {
    GoogleDriveAllFiles = 'google_drive_all_files',
    GoogleSheetCustomization = 'google_sheet_customization',
}
