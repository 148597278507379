// Copyright 2021
// ThatWorks.xyz Limited

import { Notification } from 'grommet';
import { Component, ReactNode } from 'react';
import { Logger } from '../shared/Telemetry';

interface ErrorBoundaryState {
    hasError: boolean;
}

if (!process.env.REACT_APP_SUPPORT_HREF) {
    throw new Error(`process.env.REACT_APP_SUPPORT_HREF must be defined`);
}

export class BasicErrorBoundary extends Component<{ children: ReactNode; logger: Logger }, ErrorBoundaryState> {
    state: ErrorBoundaryState = {
        hasError: false,
    };

    static getDerivedStateFromError(_error: Error): ErrorBoundaryState {
        return { hasError: true };
    }

    componentDidCatch(error: Error) {
        this.props.logger.exception(error);
    }

    render() {
        if (this.state.hasError) {
            return (
                <Notification
                    title="Sorry, we've run into an error :("
                    status="critical"
                    message="Please"
                    actions={[{ label: 'get in touch.', href: process.env.REACT_APP_SUPPORT_HREF }]}
                />
            );
        }
        return this.props.children;
    }
}
