// Copyright 2021
// ThatWorks.xyz Limited

import {
    ActivityItemPropertyType,
    ActorType,
    ChangeActionType,
    ChangeType,
    ItemType,
    StatusCategory,
} from '../../../../../../__generated__/graphql';

export enum Operator {
    Empty = 'empty',
    Eq = 'eq',
    Gt = 'gt',
    Gte = 'gte',
    In = 'in',
    Lt = 'lt',
    Lte = 'lte',
    Neq = 'neq',
    Regex = 'regex',
}

export enum Property {
    TypeOfObject = ActivityItemPropertyType.TypeOfObject,
    Description = ActivityItemPropertyType.Description,
    Assignee = ActivityItemPropertyType.Assignee,
    Priority = ActivityItemPropertyType.Priority,
    TypeOfItem = 'TPYE_OF_ITEM',
    Status = ActivityItemPropertyType.Status,
    StatusCategory = 'STATUS_CATEGORY',
    StartDate = ActivityItemPropertyType.StartDate,
    EndDate = ActivityItemPropertyType.EndDate,
    Changes = 'CHANGES',
    Action = 'ACTION',
    UpdatesBy = 'UPDATES_BY',
}

export interface PropertyOption {
    value: Property;
    operatorOptions: Partial<Record<Operator, OperatorOption>>;
}

export interface OperatorOption {
    label: string;
    value: Operator;
    valueOptions: ValueOptions;
}

export interface ValueOptions {
    options?: { label: string; value: string }[];
    allowMultiple: boolean;
    allowCustomSearch: boolean;
    isDate: boolean;
}

export interface PropertyFilterSelection {
    property: string | undefined;
    operator: Operator | undefined;
    value: string;
}

export interface PropertyFilterGroup {
    propertyFilters: PropertyFilterSelection[];
}

export const OPERATOR_OPTIONS: Record<Operator, { label: string; value: Operator }> = {
    [Operator.Empty]: { label: 'empty', value: Operator.Empty },
    [Operator.Eq]: { label: 'equal', value: Operator.Eq },
    [Operator.Gt]: { label: 'greater than', value: Operator.Gt },
    [Operator.Gte]: { label: 'greater than or equal', value: Operator.Gte },
    [Operator.In]: { label: 'in', value: Operator.In },
    [Operator.Lt]: { label: 'less than', value: Operator.Lt },
    [Operator.Lte]: { label: 'less than or equal', value: Operator.Lte },
    [Operator.Neq]: { label: 'not equal', value: Operator.Neq },
    [Operator.Regex]: { label: 'includes', value: Operator.Regex },
};

export const CUSTOM_PROPERTY_OPERATORS = [
    OPERATOR_OPTIONS[Operator.Eq],
    OPERATOR_OPTIONS[Operator.Neq],
    OPERATOR_OPTIONS[Operator.Regex],
];

export const BOOLEAN_OPTIONS: { label: string; value: 'true' | 'false' }[] = [
    { label: 'True', value: 'true' },
    { label: 'False', value: 'false' },
];

export const STATUS_CATEGORY_OPTIONS: { label: string; value: StatusCategory }[] = [
    { label: 'Done', value: StatusCategory.Done },
    { label: 'In progress', value: StatusCategory.InProgress },
    { label: 'To do', value: StatusCategory.ToDo },
];

export const TYPE_OF_OBJECT_OPTIONS: { label: string; value: ItemType }[] = [
    { label: 'Milestone', value: ItemType.Milestone },
    { label: 'Task', value: ItemType.Task },
    { label: 'Document', value: ItemType.Document },
    { label: 'Metric data', value: ItemType.MetricData },
    { label: 'Pull request', value: ItemType.PullRequest },
    { label: 'Code commit', value: ItemType.Commit },
    { label: 'Chat channel', value: ItemType.ChatChannel },
];

export const CHANGE_TYPE_OPTIONS: { label: string; value: ChangeType }[] = [
    { label: 'Assignee', value: ChangeType.Assignee },
    { label: 'Comment', value: ChangeType.Comment },
    { label: 'Description', value: ChangeType.Description },
    { label: 'End Date', value: ChangeType.EndDate },
    { label: 'Parent', value: ChangeType.Parent },
    { label: 'Priority', value: ChangeType.Priority },
    { label: 'Sprint', value: ChangeType.Sprint },
    { label: 'Start Date', value: ChangeType.StartDate },
    { label: 'Status', value: ChangeType.Status },
    { label: 'Story Points', value: ChangeType.StoryPoints },
    { label: 'Title', value: ChangeType.Title },
];

export const CHANGE_ACTION_OPTIONS: { label: string; value: ChangeActionType }[] = [
    { label: 'Created', value: ChangeActionType.Created },
    { label: 'Updated', value: ChangeActionType.Updated },
    { label: 'Deleted', value: ChangeActionType.Deleted },
    { label: 'Renamed', value: ChangeActionType.Renamed },
    { label: 'Resolved', value: ChangeActionType.Resolved },
];

export const UPDATED_BY_OPTIONS: { label: string; value: ActorType }[] = [
    { label: 'Me', value: ActorType.You },
    { label: 'Others', value: ActorType.Others },
];

export const DEFAULT_PROPERTY_FILTERS: Partial<Record<Property, PropertyOption>> = {
    [Property.TypeOfItem]: {
        value: Property.TypeOfItem,
        operatorOptions: {
            [Operator.Eq]: {
                valueOptions: {
                    allowMultiple: false,
                    allowCustomSearch: true,
                    isDate: false,
                },
                ...OPERATOR_OPTIONS[Operator.Eq],
            },
            [Operator.In]: {
                valueOptions: {
                    allowMultiple: true,
                    allowCustomSearch: false,
                    isDate: false,
                },
                ...OPERATOR_OPTIONS[Operator.In],
            },
            [Operator.Neq]: {
                valueOptions: {
                    allowMultiple: false,
                    allowCustomSearch: true,
                    isDate: false,
                },
                ...OPERATOR_OPTIONS[Operator.Neq],
            },
            [Operator.Regex]: {
                valueOptions: {
                    allowMultiple: false,
                    allowCustomSearch: true,
                    isDate: false,
                },
                ...OPERATOR_OPTIONS[Operator.Regex],
            },
        },
    },
    [Property.TypeOfObject]: {
        value: Property.TypeOfObject,
        operatorOptions: {
            [Operator.In]: {
                valueOptions: {
                    options: TYPE_OF_OBJECT_OPTIONS,
                    allowMultiple: true,
                    allowCustomSearch: false,
                    isDate: false,
                },
                ...OPERATOR_OPTIONS[Operator.In],
            },
        },
    },
    [Property.Description]: {
        value: Property.Description,
        operatorOptions: {
            [Operator.Empty]: {
                valueOptions: {
                    options: BOOLEAN_OPTIONS,
                    allowMultiple: false,
                    allowCustomSearch: false,
                    isDate: false,
                },
                ...OPERATOR_OPTIONS[Operator.Empty],
            },
            [Operator.Regex]: {
                valueOptions: {
                    allowMultiple: false,
                    allowCustomSearch: true,
                    isDate: false,
                },
                ...OPERATOR_OPTIONS[Operator.Regex],
            },
        },
    },
    [Property.Assignee]: {
        value: Property.Assignee,
        operatorOptions: {
            [Operator.Empty]: {
                valueOptions: {
                    options: BOOLEAN_OPTIONS,
                    allowMultiple: false,
                    allowCustomSearch: false,
                    isDate: false,
                },
                ...OPERATOR_OPTIONS[Operator.Empty],
            },

            [Operator.Eq]: {
                valueOptions: {
                    allowMultiple: false,
                    allowCustomSearch: false,
                    isDate: false,
                },
                ...OPERATOR_OPTIONS[Operator.Eq],
            },
            [Operator.Neq]: {
                valueOptions: {
                    allowMultiple: false,
                    allowCustomSearch: false,
                    isDate: false,
                },
                ...OPERATOR_OPTIONS[Operator.Neq],
            },
            [Operator.Regex]: {
                valueOptions: {
                    allowMultiple: false,
                    allowCustomSearch: true,
                    isDate: false,
                },
                ...OPERATOR_OPTIONS[Operator.Regex],
            },
        },
    },
    [Property.Priority]: {
        value: Property.Priority,
        operatorOptions: {
            [Operator.Empty]: {
                valueOptions: {
                    options: BOOLEAN_OPTIONS,
                    allowMultiple: false,
                    allowCustomSearch: false,
                    isDate: false,
                },
                ...OPERATOR_OPTIONS[Operator.Empty],
            },

            [Operator.Eq]: {
                valueOptions: {
                    allowMultiple: false,
                    allowCustomSearch: false,
                    isDate: false,
                },
                ...OPERATOR_OPTIONS[Operator.Eq],
            },
            [Operator.Neq]: {
                valueOptions: {
                    allowMultiple: false,
                    allowCustomSearch: false,
                    isDate: false,
                },
                ...OPERATOR_OPTIONS[Operator.Neq],
            },
            [Operator.Regex]: {
                valueOptions: {
                    allowMultiple: false,
                    allowCustomSearch: true,
                    isDate: false,
                },
                ...OPERATOR_OPTIONS[Operator.Regex],
            },
        },
    },
    [Property.Status]: {
        value: Property.Status,
        operatorOptions: {
            [Operator.Empty]: {
                valueOptions: {
                    options: BOOLEAN_OPTIONS,
                    allowMultiple: false,
                    allowCustomSearch: false,
                    isDate: false,
                },
                ...OPERATOR_OPTIONS[Operator.Empty],
            },

            [Operator.Eq]: {
                valueOptions: {
                    allowMultiple: false,
                    allowCustomSearch: false,
                    isDate: false,
                },
                ...OPERATOR_OPTIONS[Operator.Eq],
            },
            [Operator.Neq]: {
                valueOptions: {
                    allowMultiple: false,
                    allowCustomSearch: false,
                    isDate: false,
                },
                ...OPERATOR_OPTIONS[Operator.Neq],
            },
            [Operator.Regex]: {
                valueOptions: {
                    allowMultiple: false,
                    allowCustomSearch: true,
                    isDate: false,
                },
                ...OPERATOR_OPTIONS[Operator.Regex],
            },
        },
    },
    [Property.StatusCategory]: {
        value: Property.StatusCategory,
        operatorOptions: {
            [Operator.Empty]: {
                valueOptions: {
                    options: BOOLEAN_OPTIONS,
                    allowMultiple: false,
                    allowCustomSearch: false,
                    isDate: false,
                },
                ...OPERATOR_OPTIONS[Operator.Empty],
            },

            [Operator.Eq]: {
                valueOptions: {
                    options: STATUS_CATEGORY_OPTIONS,
                    allowMultiple: false,
                    allowCustomSearch: false,
                    isDate: false,
                },
                ...OPERATOR_OPTIONS[Operator.Eq],
            },
            [Operator.Neq]: {
                valueOptions: {
                    options: STATUS_CATEGORY_OPTIONS,
                    allowMultiple: false,
                    allowCustomSearch: false,
                    isDate: false,
                },
                ...OPERATOR_OPTIONS[Operator.Neq],
            },
            [Operator.Regex]: {
                valueOptions: {
                    allowMultiple: false,
                    allowCustomSearch: true,
                    isDate: false,
                },
                ...OPERATOR_OPTIONS[Operator.Regex],
            },
        },
    },
    [Property.Changes]: {
        value: Property.Changes,
        operatorOptions: {
            [Operator.Empty]: {
                valueOptions: {
                    options: BOOLEAN_OPTIONS,
                    allowMultiple: false,
                    allowCustomSearch: false,
                    isDate: false,
                },
                ...OPERATOR_OPTIONS[Operator.Empty],
            },
            [Operator.Eq]: {
                valueOptions: {
                    options: CHANGE_TYPE_OPTIONS,
                    allowMultiple: false,
                    allowCustomSearch: false,
                    isDate: false,
                },
                ...OPERATOR_OPTIONS[Operator.Eq],
            },
            [Operator.In]: {
                valueOptions: {
                    options: CHANGE_TYPE_OPTIONS,
                    allowMultiple: true,
                    allowCustomSearch: false,
                    isDate: false,
                },
                ...OPERATOR_OPTIONS[Operator.In],
            },
            [Operator.Neq]: {
                valueOptions: {
                    options: CHANGE_TYPE_OPTIONS,
                    allowMultiple: false,
                    allowCustomSearch: false,
                    isDate: false,
                },
                ...OPERATOR_OPTIONS[Operator.Neq],
            },
        },
    },
    [Property.Action]: {
        value: Property.Action,
        operatorOptions: {
            [Operator.In]: {
                valueOptions: {
                    options: CHANGE_ACTION_OPTIONS,
                    allowMultiple: true,
                    allowCustomSearch: false,
                    isDate: false,
                },
                ...OPERATOR_OPTIONS[Operator.In],
            },
        },
    },
    [Property.UpdatesBy]: {
        value: Property.UpdatesBy,
        operatorOptions: {
            [Operator.In]: {
                valueOptions: {
                    options: UPDATED_BY_OPTIONS,
                    allowMultiple: true,
                    allowCustomSearch: false,
                    isDate: false,
                },
                ...OPERATOR_OPTIONS[Operator.In],
            },
        },
    },
    [Property.StartDate]: {
        value: Property.StartDate,
        operatorOptions: {
            [Operator.Gt]: {
                valueOptions: {
                    allowMultiple: false,
                    allowCustomSearch: false,
                    isDate: true,
                },
                ...OPERATOR_OPTIONS[Operator.Gt],
            },
            [Operator.Lt]: {
                valueOptions: {
                    allowMultiple: false,
                    allowCustomSearch: false,
                    isDate: true,
                },
                ...OPERATOR_OPTIONS[Operator.Lt],
            },
            [Operator.Gte]: {
                valueOptions: {
                    allowMultiple: false,
                    allowCustomSearch: false,
                    isDate: true,
                },
                ...OPERATOR_OPTIONS[Operator.Gte],
            },
            [Operator.Lte]: {
                valueOptions: {
                    allowMultiple: false,
                    allowCustomSearch: false,
                    isDate: true,
                },
                ...OPERATOR_OPTIONS[Operator.Lte],
            },
            [Operator.Empty]: {
                valueOptions: {
                    options: BOOLEAN_OPTIONS,
                    allowMultiple: false,
                    allowCustomSearch: false,
                    isDate: false,
                },
                ...OPERATOR_OPTIONS[Operator.Empty],
            },
        },
    },
    [Property.EndDate]: {
        value: Property.EndDate,
        operatorOptions: {
            [Operator.Gt]: {
                valueOptions: {
                    allowMultiple: false,
                    allowCustomSearch: false,
                    isDate: true,
                },
                ...OPERATOR_OPTIONS[Operator.Gt],
            },
            [Operator.Lt]: {
                valueOptions: {
                    allowMultiple: false,
                    allowCustomSearch: false,
                    isDate: true,
                },
                ...OPERATOR_OPTIONS[Operator.Lt],
            },
            [Operator.Gte]: {
                valueOptions: {
                    allowMultiple: false,
                    allowCustomSearch: false,
                    isDate: true,
                },
                ...OPERATOR_OPTIONS[Operator.Gte],
            },
            [Operator.Lte]: {
                valueOptions: {
                    allowMultiple: false,
                    allowCustomSearch: false,
                    isDate: true,
                },
                ...OPERATOR_OPTIONS[Operator.Lte],
            },
            [Operator.Empty]: {
                valueOptions: {
                    options: BOOLEAN_OPTIONS,
                    allowMultiple: false,
                    allowCustomSearch: false,
                    isDate: false,
                },
                ...OPERATOR_OPTIONS[Operator.Empty],
            },
        },
    },
};

export function getPropertyLabel(property: Property): string {
    switch (property) {
        case Property.TypeOfObject:
            return 'Category of item';
        case Property.Description:
            return 'Description';
        case Property.Assignee:
            return 'Assignee';
        case Property.Priority:
            return 'Priority';
        case Property.Status:
            return 'Status';
        case Property.StatusCategory:
            return 'Status Category';
        case Property.StartDate:
            return 'Start date';
        case Property.EndDate:
            return 'End date';
        case Property.Changes:
            return 'Changes';
        case Property.Action:
            return 'Action';
        case Property.UpdatesBy:
            return 'Updates by';
        case Property.TypeOfItem:
            return 'Type of item';
    }
}

export function getPropertyFromLabel(label: string | undefined): Property | undefined {
    if (!label) {
        return undefined;
    }
    return Object.values(Property).find((p) => getPropertyLabel(p as Property) === label);
}
